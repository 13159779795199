import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";

import Home from "../pages/Home";
import Roteiro from "../pages/Roteiro";
import Login from "../pages/Login";
import Publicacoes from "../pages/Publicacoes";
import Blog from "../pages/Blog";

import PaginaNaoEncontrada from "../PaginaNaoEncontrada";

import HomeAdm from "../AdmPages/Home";
import CriarHomeAdm from "../AdmPages/CriarHome";
import CriarRoteiros from "../AdmPages/CriarRoteiros";
import CriarLugares from "../AdmPages/CriarLugares";
import FinalizarRoteiros from "../AdmPages/FinalizarRoteiros";
import CriarPostagemBlog from "../AdmPages/CriarPostagemBlog";

import RouteMiddleware from '../middleware/route-middleware';

export default function Routes() {
  const routes = [
    {
      path: "/",
      component: Home,
      title: 'Home',
      exact: true,
    },
    {
      path: "/roteiro",
      component: Roteiro,
      title: 'Roteiro',
      exact: true,
    },
    {
      path: "/login",
      component: Login,
      title: 'Login',
      exact: true,
    },
    {
      path: "/adm",
      component: HomeAdm,
      title: 'HomeAdm',
      exact: true,
    },
    {
      path: "/blog",
      component: Blog,
      title: 'Blog',
      exact: true,
    },
    {
      path: "/atualizarhome",
      component: CriarHomeAdm,
      title: 'CriarHomeAdm',
      exact: true,
    },
    {
      path: "/criarroteiros",
      component: CriarRoteiros,
      title: 'CriarRoteiros',
      exact: true,
    },
    {
      path: "/criarlugares",
      component: CriarLugares,
      title: 'CriarLugares',
      exact: true,
    },
    {
      path: "/publicacoes",
      component: Publicacoes,
      title: 'Publicacoes',
      exact: true,
    },
    {
      path: "/finalizarroteiros",
      component: FinalizarRoteiros,
      title: 'FinalizarRoteiros',
      exact: true,
    },
    {
      path: "/criarpostagemblog",
      component: CriarPostagemBlog,
      title: 'CriarPostagemBlog',
      exact: true,
    },
    {
      path: "*",
      component: PaginaNaoEncontrada,
      title: 'PaginaNaoEncontrada',
      exact: true,
    },
  ];

  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route, index) => (
          <RouteMiddleware
            key={index}
            path={route.path}
            title={route.title}
            component={route.component}
            exact={route.exact}
          />
        ))}
      </Switch>
    </BrowserRouter>
  );
}
