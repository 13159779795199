import React, { Component } from 'react';
import { Row, Col, Container, Form, Button, Alert, Tabs, Tab } from 'react-bootstrap'

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Redirect } from 'react-router-dom'

import Cadastro from '../Cadastro'


import Api from '../../services/api';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = { emailvalue: '', passwordvalue: '', namevalue: '', idUser: '', token: '', showSenhaAlert: false, redir: false, adm: false };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.postUseLogin = this.postUseLogin.bind(this);
    }

    handleChange(event) {
        switch (event.target.id) {
            case "email":
                this.setState({ emailvalue: event.target.value });
                break;

            case "password":
                this.setState({ passwordvalue: event.target.value });
                break;
            default:
                console.log("ERRO NO SWITCH");
                break;
        }
    }

    async postUseLogin() {
        this.setState({ showSenhaAlert: false });
        try {

            const JsonSend = {
                "email": this.state.emailvalue,
                "password": this.state.passwordvalue
            };

            const headers = {
                "Content-Type": "application/json; charset=utf-8"
            };

            if (this.state.passwordvalue !== "" && this.state.emailvalue !== "") {
                const response = await Api.post('/auth/authenticate', JsonSend, { headers })

                if (this.state.idUser !== undefined && this.state.token !== undefined) {

                    sessionStorage.removeItem('token');
                    sessionStorage.removeItem('idUser');
                    sessionStorage.removeItem('name');
                    sessionStorage.removeItem('email');
                    sessionStorage.removeItem('adm');
                    this.setState({ adm: response.data.user.adm, namevalue: response.data.user.name, idUser: response.data.user._id, token: response.data.token, passwordvalue: undefined, passwordvalue2: undefined });
                    sessionStorage.setItem('token', this.state.token);
                    sessionStorage.setItem('idUser', this.state.idUser);
                    sessionStorage.setItem('name', this.state.namevalue);
                    sessionStorage.setItem('email', this.state.emailvalue);
                    sessionStorage.setItem('adm', this.state.adm);
                    this.setState({ redir: true });
                }


            } else {
                this.setState({ showSenhaAlert: true });
            }



        }
        catch (error) {
            if (error.response !== undefined) {
                console.log(error.response.data.error);
                if (error.response.data.error === "Senha errada") {
                    this.setState({ showSenhaAlert: true });
                }

                if (error.response.data.error === "Usuário não encontrado") {
                    this.setState({ showSenhaAlert: true });
                }
            }
        }

    }

    handleSubmit(event) {
        this.postUseLogin();
        event.preventDefault();
    }

    render() {
        if (this.state.redir) {
            if (!this.state.adm) {
                return (<Redirect
                    to={{
                        pathname: "/",
                    }}
                />);
            }
            else {
                return (<Redirect
                    to={{
                        pathname: "/adm",
                    }}
                />);
            }

        }
        else {
            window.scrollTo(0,0); 
            return (
                <div className="login">
                    <Header></Header>
                    <Container fluid>
                        <Row className="">
                            <Col md={4} className="mt-5 p-5">
                                <Tabs defaultActiveKey="Login" id="" className="mb-3" style={{}}>
                                    <Tab eventKey="Login" title="Login" className="m-3">
                                        <Form onSubmit={this.handleSubmit}>
                                            <Form.Group controlId="email" className="my-3" onChange={this.handleChange} value={this.state.emailvalue}>
                                                <Form.Label>Email:</Form.Label>
                                                <Form.Control size="lg" type="email" placeholder="Coloque o Email" />
                                            </Form.Group>

                                            <Form.Group controlId="password" onChange={this.handleChange} value={this.state.passwordvalue} >
                                                <Form.Label>Senha:</Form.Label>
                                                <Form.Control size="lg" type="password" placeholder="Coloque sua senha" />
                                            </Form.Group>

                                            <Row className="m-5">
                                                <Col className="d-flex justify-content-center">
                                                    <Button size="sm" type="submit" className="text-center button2" style={{ backgroundColor: 'transparent', borderRadius: '50px', width: '100%', height: '2.5rem', color: 'white' }}>ENTRAR</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Tab>
                                    <Tab eventKey="Cadastro" title="Cadastro" className="mb-3">
                                        <Cadastro>  </Cadastro>
                                    </Tab>
                                </Tabs>
                                <Alert key={1} variant="danger" show={this.state.showSenhaAlert}>
                                    Email ou senha errados.
                            </Alert>

                            </Col>
                            <Col md style={{ width: '100%', height: '100vh', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'right', backgroundImage: "url('/imagens/registro.png')" }}>
                            </Col>
                        </Row>
                    </Container>
                    <Footer> </Footer>
                </div>
            );
        }
    }
}

export default Login;