import React, { Component } from 'react';
import { Col, Form, Button, Modal, Row, Image } from 'react-bootstrap'

class Loading extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ItineraryInformationsMap: [], isLoading: true
        };
    }


    render() {
        return (
            <div className='d-flex justify-content-center  align-items-center' style={{ width: "100%", height: "100%" }}>
                <Image className='Loading' style={{ width: this.props.size }} src='/imagens/Loading.png' />
            </div>

        );
    }
}

export default Loading;