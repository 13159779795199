import React from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap'

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Publicacoes() {


    return (
        <div className="telaInicial">
            <Header></Header>
            <Container>
                <Row>
                    <Col className="my-5">
                        <Card className="text-center my-5">
                            <Card.Header className="tituloStyle" >Dicas para viagens</Card.Header>
                            <Card.Body>
                                <Card.Text className="textoStyle">
                                Encontre as 5 principais dicas para todo viajantes! kasflkasçl asçlkfçlaskfa kafsçkaçf asçfkças asçfkças asçfkças asçfkças asçfkças asçfkças
                                asçfkças
                                asçfkças
                                asçfkças
                             </Card.Text>
                            </Card.Body>
                            <Card.Footer className="text-muted">Publicado há 2 dias</Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Footer> </Footer>
        </div>
    );
}

