import React from 'react';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'

import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link } from 'react-router-dom'

import Header from '../../components/Header'

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function HomeAdm() {

    return (
        <div className="cadastro">
            <Header></Header>
            <Container>
                <Row style={{ height: '20vh' }} className="align-items-center text-center">
                    <p className="TextosAuxiliares2">Ajustes Gerais</p>
                    <Col>
                        <Link className="h5TextosAuxiliares" to='/atualizarhome'>
                            <Card style={{ backgroundColor: '#3e3d90' }}>
                                <Card.Body>
                                    Modificar Tela inicial
                            </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                    <Col>
                        <Link className="h5TextosAuxiliares" to='/criarpostagemblog'>
                            <Card style={{ backgroundColor: '#3e3d90' }}>
                                <Card.Body>
                                    Criar postagem no Blog
                            </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                </Row>
                <Row style={{ height: '20vh' }} className="align-items-center text-center mt-5">
                    <p className="TextosAuxiliares2">Criação de roteiros</p>
                    <Col>
                        <Link className="h5TextosAuxiliares" to='/criarroteiros'>
                            <Card style={{ backgroundColor: '#3e3d90' }}>
                                <Card.Body>
                                    Iniciar roteiros
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                    <Col md={1}>
                        <FontAwesomeIcon id="teste" style={{ width: '100%', height: '6vh',color:'#e7424d' }} icon={faArrowRight} />
                    </Col>
                    <Col>
                        <Link className="h5TextosAuxiliares" to='/criarlugares'>
                            <Card style={{ backgroundColor: '#3e3d90' }} >
                                <Card.Body>
                                   Adicionar lugares e atrações
                            </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                    <Col md={1}>
                        <FontAwesomeIcon id="teste" style={{ width: '100%', height: '6vh',color:'#e7424d' }} icon={faArrowRight} />
                    </Col>
                    <Col>
                        <Link className="h5TextosAuxiliares" to='/finalizarroteiros'>
                            <Card style={{ backgroundColor: '#3e3d90' }} >
                                <Card.Body>
                                   Finalizar roteiros
                            </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}