import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";

import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4geodata_data_countries2 from "@amcharts/amcharts4-geodata/data/countries2";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4geodata_lang_PT from "@amcharts/amcharts4-geodata/lang/PT";

import { Col, Form, Button, Modal, Row, Image } from 'react-bootstrap'
import './style.css';

import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Loading from '../../components/Loading'


import { Link } from 'react-router-dom'
import Api from '../../services/api';
import { interpolate } from '@amcharts/amcharts4/.internal/core/utils/Colors';

let RoteiroId = null;

var imageSeriesTemplate;


let handleShow = () => { };
let handleClose = () => { };
let setNumeroDeDiasShow = () => { };
let setPrimeiraVezShow = () => { };
let setVelocidadeShow = () => { };
let setPreferenciasShow = () => { };


function Example() {

    const [numeroDeDias, setNumeroDeDias] = React.useState(1);
    const [primeiraVez, setPrimeiraVez] = React.useState(0);
    const [velocidade, setVelocidade] = React.useState("0");
    const [preferencias, setPreferencias] = React.useState("0");
    const [idRoteiro, setIdRoteiro] = React.useState("0");

    handleClose = () => {
        document.getElementById("buttonCommit").style.display = "none";
        document.getElementById("formBasicRangeGeral").style.display = "none";
        document.getElementById("FirstTimeCheckboxGeral").style.display = "none";
        document.getElementById("velocidadeCheckboxGeral").style.display = "none";
        document.getElementById("preferenciasCheckboxGeral").style.display = "none";
    };

    handleShow = (t) => {
        setIdRoteiro(t);
        document.getElementById("buttonCommit").style.display = "block";
        document.getElementById("formBasicRangeGeral").style.display = "block";
        document.getElementById("FirstTimeCheckboxGeral").style.display = "block";
        document.getElementById("velocidadeCheckboxGeral").style.display = "block";
        document.getElementById("preferenciasCheckboxGeral").style.display = "block";
    };
    setNumeroDeDiasShow = () => setNumeroDeDias(document.getElementById("formBasicRange").value);
    setPrimeiraVezShow = () => setPrimeiraVez(document.getElementById("FirstTimeCheckbox").value);
    setVelocidadeShow = () => setVelocidade(document.getElementById("velocidadeCheckbox").value);
    setPreferenciasShow = () => setPreferencias(document.getElementById("preferenciasCheckbox").value);

    return (
        <>
            <Form>
                <Row className="justify-content-center mt-5">
                    <Col xl={1} sm={3}>
                        <Form.Group controlId="FirstTimeCheckbox" id="FirstTimeCheckboxGeral" className='mt-2' style={{ display: 'none' }}>
                            <Form.Label className='form-label2'>Primeira vez?</Form.Label>
                            <Form.Control className='form-control2' onChange={setPrimeiraVezShow} as="select" defaultValue="0" >
                                <option value="0">Sim</option>
                                <option value="1">Não</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xl={2} sm={4}>
                        <Form.Group controlId="velocidadeCheckbox" id="velocidadeCheckboxGeral" className='mt-2' style={{ display: 'none' }}>
                            <Form.Label className='form-label2'>Ritmo da Viagem</Form.Label>
                            <Form.Control className='form-control2' onChange={setVelocidadeShow} as="select" defaultValue="0" >
                                <option value="0">Relax</option>
                                <option value="1">Pé no acelerador</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xl={2} sm={4}>
                        <Form.Group controlId="preferenciasCheckbox" id="preferenciasCheckboxGeral" className='mt-2' style={{ display: 'none' }}>
                            <Form.Label className='form-label2'>Perfil do viajante</Form.Label>
                            <Form.Control className='form-control2' onChange={setPreferenciasShow} as="select" defaultValue="0">
                                <option value="0">Passaporte cultural</option>
                                <option value="1">Ao ar livre</option>
                                <option value="2">Turistão</option>
                                <option value="3">Caramba, comi bem!</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xl={1} sm={1}>
                        <Form.Group controlId="formBasicRange" id="formBasicRangeGeral" className='mt-2' style={{ display: 'none' }}>
                            <Form.Label className='form-label2'>Dias</Form.Label>
                            <Form.Control className='form-control2' onChange={setNumeroDeDiasShow} as="select" defaultValue="1">
                                <option value="1">1</option>
                                <option value="3">3</option>
                                <option value="5">5</option>
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center align-self-end mt-3">
                        <Link id="buttonCommit" style={{ display: 'none' }} to={"/roteiro?itineraryId=" + idRoteiro + "&numeroDeDias=" + numeroDeDias + "&primeiraVez=" + primeiraVez + "&velocidade=" + velocidade + "&preferencias=" + preferencias}> <Button id="buttonSubmitRoteiros" variant="flat" size="lg" type="submit"> <FontAwesomeIcon id="teste" className="mx-2" icon={faSearch} />Gerar</Button></Link>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

class MapContainer2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ItineraryInformationsMap: [], isLoading: true
        };
    }

    requisicao = async () => {
        await Api.get('/itineraryWithoutToken/mapInformations')
            .then((response) => {
                return response;
            }).then(datas => {
                this.setState({
                    ItineraryInformationsMap: datas.data.projects,
                    isLoading: false
                });

                am4core.useTheme(am4themes_animated);

                let targetSVG = "M 9 0 C 4.029 0 0 4.029 0 9 s 4.029 9 9 9 s 9 -4.029 9 -9 S 13.971 0 9 0 z M 9 15.93 z";

                let colors = {
                    "BR": "#5f5fb9",
                    "FR": "#5f5fb9",
                    "BE": "#5f5fb9",
                    "NL": "#5f5fb9",
                    "IT": "#5f5fb9",
                    "CO": "#5f5fb9",
                    "PE": "#5f5fb9",
                    "UY": "#5f5fb9",
                    "AR": "#5f5fb9",
                }

                let chart = am4core.create("chartdiv", am4maps.MapChart);
                chart.projection = new am4maps.projections.Miller();
                chart.geodataNames = am4geodata_lang_PT;

                // Create map polygon series for world map
                let worldSeries = chart.series.push(new am4maps.MapPolygonSeries());
                worldSeries.useGeodata = true;
                worldSeries.geodata = am4geodata_worldLow;
                worldSeries.exclude = ["AQ"];

                let worldPolygon = worldSeries.mapPolygons.template;
                worldPolygon.tooltipText = "{name}";
                worldPolygon.nonScalingStroke = true;
                worldPolygon.strokeOpacity = 0.5;
                worldPolygon.fill = am4core.color("#eee");
                worldPolygon.propertyFields.fill = "color";

                let hs = worldPolygon.states.create("hover");
                hs.properties.fill = am4core.color("#e9434e");


                // Create country specific series (but hide it for now)
                let countrySeries = chart.series.push(new am4maps.MapPolygonSeries());
                countrySeries.useGeodata = true;
                countrySeries.hide();
                countrySeries.geodataSource.events.on("done", function (ev) {
                    worldSeries.hide();
                    countrySeries.show();
                });

                let countryPolygon = countrySeries.mapPolygons.template;
                countryPolygon.tooltipText = "{name}";
                countryPolygon.nonScalingStroke = true;
                countryPolygon.strokeOpacity = 0.5;
                countryPolygon.fill = am4core.color("#5f5fb9");

                let hs2 = countryPolygon.states.create("hover");
                hs2.properties.fill = am4core.color("#5f5fb9");

                // create capital markers
                let imageSeries = chart.series.push(new am4maps.MapImageSeries());
                imageSeries.id = "markers";

                // define template
                imageSeriesTemplate = imageSeries.mapImages.template;

                let circle = imageSeriesTemplate.createChild(am4core.Sprite);
                circle.scale = 0.8;
                circle.fill = am4core.color("#e9434e");
                circle.path = targetSVG;

                imageSeriesTemplate.propertyFields.latitude = "latitude";
                imageSeriesTemplate.propertyFields.longitude = "longitude";
                imageSeriesTemplate.nonScaling = true;
                imageSeriesTemplate.horizontalCenter = "middle";
                imageSeriesTemplate.verticalCenter = "middle";
                imageSeriesTemplate.width = 5;
                imageSeriesTemplate.height = 5;
                imageSeriesTemplate.tooltipText = "{title}";
                imageSeriesTemplate.fill = am4core.color("#e9434e");

                imageSeriesTemplate.events.on("hit", function (ev) {
                    RoteiroId = ev.target.dataItem.dataContext.id;
                    handleShow(RoteiroId);
                });


                imageSeries.data = this.state.ItineraryInformationsMap.map(data => {
                    return { "svgPath": targetSVG, "title": data._id.ItineraryName, "latitude": data._id.ItineraryLatitude, "longitude": data._id.ItineraryLongitude, "id": data._id._id }
                });


                setTimeout(() => {
                    imageSeries.hide();
                }, 400);

                // Set up click events
                worldPolygon.events.on("hit", function (ev) {
                    ev.target.series.chart.zoomToMapObject(ev.target);
                    let map = ev.target.dataItem.dataContext.map;
                    if (map) {
                        ev.target.isHover = false;
                        countrySeries.geodataSource.url = "https://www.amcharts.com/lib/4/geodata/json/" + map + ".json";
                        countrySeries.geodataSource.load();
                        imageSeries.show();
                        handleClose();
                    }
                });

                // Set up data for countries
                let data = [];
                for (var id in am4geodata_data_countries2) {
                    if (am4geodata_data_countries2.hasOwnProperty(id)) {
                        let country = am4geodata_data_countries2[id];
                        if (country.maps.length) {
                            data.push({
                                id: id,
                                color: colors[id],
                                map: country.maps[0]
                            });
                        }
                    }
                }
                worldSeries.data = data;

                // Zoom control
                chart.zoomControl = new am4maps.ZoomControl();

                let homeButton = new am4core.Button();
                homeButton.events.on("hit", function () {
                    worldSeries.show();
                    countrySeries.hide();
                    chart.goHome();
                    imageSeries.hide();
                    handleClose();
                });

                homeButton.icon = new am4core.Sprite();
                homeButton.padding(7, 5, 7, 5);
                homeButton.width = 30;
                homeButton.icon.path = "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
                homeButton.marginBottom = 10;
                homeButton.parent = chart.zoomControl;
                homeButton.insertBefore(chart.zoomControl.plusButton);

            }).catch(error => {
                console.log(error);
            });
    }

    componentDidMount() {
        this.requisicao();
    }

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        return (
            <div>
                { this.state.isLoading == false ?
                    <div>
                        <div id="chartdiv" style={{ width: "100%", height: "50vh" }}></div>
                        <Example></Example>
                    </div>
                    :
                    <div style={{ width: "100%", height: "50vh" }}>
                        <Loading size='5%' />
                    </div>
                }
            </div>
        );
    }
}

export default MapContainer2;