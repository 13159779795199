import React, { Component } from 'react';
import Api from '../../services/api';

import {
    Row,
    Col,
    Container,
    Form, Button, Dropdown
} from "react-bootstrap";


import { ListBox } from 'primereact/listbox';
import { Tree } from 'primereact/tree';


import Header from '../../components/Header'
import Loading from '../../components/Loading'
import { Link } from 'react-router-dom'
import { faSearch, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';

class GetProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roteiros: [],
            informacoesRoteiroAtual: [{ id: "", ItineraryName: "", ItineraryResume: "", ItineraryWhereGo: "", ItineraryTimeStay: "", ItineraryHowGo: "", ItinerarySafety: "", ItineraryHealth: "", ItineraryImage: "", ItineraryLatitude: 0, ItineraryLongitude: 0, ItineraryDay: 1, ItineraryMap: "", ItineraryPassport: "", ItineraryExchange: "", ItineraryInternet: "", ItineraryAccommodation: "", ItineraryExpensiveOrCheap: "", ItineraryMovieMusicAndOthers: "", ItineraryLocomotion: "", ItineraryOthers: "" }],
            selectedRoteiros: "",
            selectedRoteirosDisplay: "",
            validationError: "",
            places: []
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        sessionStorage.removeItem('id_Itinerary');

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
        };

        Api.get('/itinerary', { headers }).then((response) => {
            return response;
        }).then(data => {
            let roteirosFromApi = data.data.projects.map(data => {

                return { value: data._id, display: data.ItineraryName }
            });
            this.setState({
                roteiros: roteirosFromApi
            });

        }).catch(error => {
            console.log(error);
        });
    }

    handleChange(event) {
        /*document.getElementById("submitbutton").style.display = "none";
        document.getElementById("updatebutton").style.display = "block";*/
        this.setState({ selectedRoteiros: event.target.getAttribute("value"), selectedRoteirosDisplay: event.target.getAttribute("display") });
        sessionStorage.setItem('id_Itinerary', event.target.getAttribute("value"));
        sessionStorage.setItem('itineraryName', event.target.getAttribute("display"));

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
        };

        Api.get('/places/byItinerary/' + sessionStorage.getItem('id_Itinerary'), { headers }).then((response) => {
            return response;
        }).then(data => {
            let placesFromApi = data.data.places.map(data => {

                return { value: data._id, display: data.placeName }
            });
            this.setState({
                places: placesFromApi
            });

        }).catch(error => {
            console.log(error);
        });


    }
    render() {
        return (
            <Row >
                <h6 className="TextosAdm">Você está finalizando o roteiro:<h4>{this.state.selectedRoteirosDisplay} </h4> </h6>
                <Row >
                    <Col md>
                        <Dropdown>
                            <Dropdown.Toggle className='dropdownAdm' id="dropdown-basic" size="lg">
                                Escolha um roteiro para finalizar
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ overflowY: 'scroll', height: '20vh' }}>
                                {this.state.roteiros.map((roteiro) => {

                                    return (
                                        <Dropdown.Item style={{ border: '0px solid #3e3d90', borderBottomWidth: '1px', color: color2 }} key={roteiro.value} onClick={this.handleChange} value={roteiro.value} display={roteiro.display}>{roteiro.display}</Dropdown.Item>
                                    );
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                    <Col md className="d-flex justify-content-end">
                        <GetItinerary></GetItinerary>
                    </Col>
                </Row>
                <div className="">
                    <div className="p-text-center p-my-3">
                        <TreeDragDropDemo places={this.state.places}></TreeDragDropDemo>
                    </div>
                </div>
            </Row>
        )
    }
}
let color2 = '#3e3d90';
class GetItinerary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ItineraryId: null, ItineraryName: [],
            ItineraryProfiles: null,//Turistão , Historia, Parques e Comi bem
            ItineraryRhythm: null,//Pé Acelerador , Relax
            ItineraryFirstTime: null,
            ItineraryInfoData: null,
            ItinerarysEnd: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.GetItineraryEnd = this.GetItineraryEnd.bind(this);
        this.Atualizar = this.Atualizar.bind(this);
    }

    Atualizar() {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
        };
        Api.get('/itinerary_datainfo/ItineraryId/' + sessionStorage.getItem('id_Itinerary'), { headers }).then((response) => {
            console.log(response);
            return response;
        }).then(data => {
            let itinerary_datainfoApi = data.data.Itinerary_data.map(data => {
                console.log(data);
                let varAux1 = "";
                let varAux2 = "";
                let varAux3 = "";
                switch (parseInt(data.ItineraryProfiles)) {
                    case 0:
                        varAux1 = 'História/Museus';
                        break;
                    case 1:
                        varAux1 = 'Parques/Ar livre';
                        break;
                    case 2:
                        varAux1 = 'Turistão';
                        break;
                    case 3:
                        varAux1 = 'Caramba, comi bem';
                        break;
                    default:
                        break;
                }

                switch (parseInt(data.ItineraryRhythm)) {
                    case 0:
                        varAux2 = 'Relax';
                        break;
                    case 1:
                        varAux2 = 'Pé no acelerador';
                        break;
                    default:
                        break;
                }

                switch (parseInt(data.ItineraryFirstTime)) {
                    case 0:
                        varAux3 = 'Sim';
                        break;
                    case 1:
                        varAux3 = 'Não';
                        break;
                    default:
                        break;
                }

                return { value: data._id, ItineraryId: data.ItineraryId, numeroDeDias: data.ItineraryDays, primeiraVez: data.ItineraryFirstTime, velocidade: data.ItineraryRhythm, preferencias: data.ItineraryProfiles, display: data.ItineraryName + "," + varAux1 + ", " + varAux2 + ", " + varAux3 + ", " + data.ItineraryDays + " Dias" }
            });
            this.setState({
                ItinerarysEnd: itinerary_datainfoApi
            });


        }).catch(error => {
            console.log(error);
        });
    }

    componentDidMount() {
        this.Atualizar();
    }

    async GetItineraryEnd(selected) {


        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
        };

        sessionStorage.setItem('id_Place', selected);

        await Api.get('/itinerary_datainfo/ItineraryId/' + selected, { headers }).then((response) => {
            return response;
        }).then(data => {
            let dataMap = data.data.places;

            this.setState({
                ItineraryId: dataMap.ItineraryId, ItineraryName: dataMap.ItineraryName,
                ItineraryProfiles: dataMap.ItineraryProfiles,
                ItineraryRhythm: dataMap.ItineraryRhythm,//Pé Acelerador , Relax
                ItineraryFirstTime: dataMap.ItineraryFirstTime,
                ItineraryInfoData: dataMap.ItineraryInfoData,
            });

        }).catch(error => {
            console.log(error);
        });

    }

    handleChange(event) {
        /*document.getElementById("submitbutton").style.display = "none";
        document.getElementById("updatebutton").style.display = "block";*/
        //this.setState({ selectedRoteiros: event.target.getAttribute("value"), selectedRoteirosDisplay: event.target.getAttribute("display") });
        //this.GetItineraryEnd(event.target.getAttribute("value"));
        this.GetItineraryEnd(sessionStorage.getItem('id_Itinerary'));

    }


    render() {
        return (
            <div>
                <Dropdown>
                    <Dropdown.Toggle className="dropdownAdm" id="dropdown-basic" size="lg" onFocus={this.Atualizar}>
                        Roteiros finalizados
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ overflowY: 'scroll', overflowX: 'hidden', height: '20vh', width: '30vw' }}>
                        {this.state.ItinerarysEnd.map((a) => {
                            return (
                                <>
                                    <Row style={{ border: '0px solid #3e3d90', borderBottomWidth: '1px', color: color2 }}>
                                        <Col md={9} >
                                            <Dropdown.Item style={{ color: color2 }} disabled className="textAdmSelect" key={a.value} /*onClick={this.handleChange}*/ value={a.value}>{a.display}
                                            </Dropdown.Item>
                                        </Col>
                                        <Col md={1}>
                                            <Link id="buttonCommit" to={"/roteiro?itineraryId=" + a.ItineraryId + "&numeroDeDias=" + a.numeroDeDias + "&primeiraVez=" + a.primeiraVez + "&velocidade=" + a.velocidade + "&preferencias=" + a.preferencias}>
                                                <Button id="buttonSubmitRoteiros" variant="flat" size="sm" type="submit"> <FontAwesomeIcon id="teste" className="" icon={faSearch} />
                                                </Button>
                                            </Link>
                                        </Col>
                                        <Col md={1}>
                                            <div id="buttonX">
                                                <Button id="buttonXRoteiros" variant="flat" size="sm" type="submit" onClick={async () => {
                                                    if (window.confirm("Deseja exluir a postagem?")) {

                                                        const headers = {
                                                            "Content-Type": "application/json; charset=utf-8",
                                                            "Authorization": "Bearer " + sessionStorage.getItem('token')
                                                        };

                                                        await Api.delete('/itinerary_datainfo/remove/' + a.value, { headers }).then((response) => {
                                                            return response;
                                                        }).finally(data => {
                                                            this.Atualizar();
                                                        }).catch(error => {
                                                            console.log(error);
                                                        });
                                                    }
                                                }}> <FontAwesomeIcon style={{ color: '#e7424d' }} id="teste" className="" icon={faPowerOff} />
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>

        )
    }
}

export class TreeDragDropDemo extends Component {

    datas = [];
    constructor(props) {
        super(props);
        this.state = {
            ItineraryId: null, ItineraryName: null,
            ItineraryProfiles: null,//Turistão , Historia, Parques e Comi bem
            ItineraryRhythm: null,//Pé Acelerador , Relax
            ItineraryFirstTime: null,
            ItineraryDays: null,
            ItineraryInfoData: null,
            isLoading: false
        };
        //this.nodeService = new NodeService();
        this.handleChange = this.handleChange.bind(this);
        this.ClickSubmit = this.ClickSubmit.bind(this);
        this.postPlace = this.postPlace.bind(this);
        this.handleSubmit = this.postPlace.bind(this);
        this.UpdatePlace = this.postPlace.bind(this);

        this.perfil = [
            { value: 'História/Museus' },
            { value: 'Parques/Ar livre' },
            { value: 'Turistão' },
            { value: 'Caramba, comi bem' }
        ];

        this.ritmo = [
            { value: 'Pé no acelerador' },
            { value: 'Relax' }
        ];

        this.primeiravez = [
            { value: 'Sim' },
            { value: 'Não' }
        ];
        this.dias = [
            { value: '1' },
            { value: '3' },
            { value: '5' }
        ];

    }


    handleChange() {
        let i = 0;
        let lista = [];
        this.props.places.forEach(element => {
            console.log(element);
            let a = { "key": "5-" + i, "label": element.display, "icon": "pi pi-fw pi-file", "data": element.value };
            lista.push(a);
            i++;
        });

        this.datas =
        {
            "root": [{
                "key": "0",
                "label": "Dia 1",
                "data": "Documents Folder",
                "icon": "pi pi-fw pi-inbox",
                "children": [{
                    "key": "0-0",
                    "label": "Manhã",
                    "data": "Work Folder",
                    "icon": "pi pi-fw pi-sun",
                    "children": []
                },
                {
                    "key": "0-1",
                    "label": "Tarde",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-cloud",
                    "children": []
                }, {
                    "key": "0-2",
                    "label": "Noite",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-home",
                    "children": []
                }]
            },
            {
                "key": "1",
                "label": "Dia 2",
                "data": "Events Folder",
                "icon": "pi pi-fw pi-calendar",
                "children":
                    [{
                        "key": "1-0",
                        "label": "Manhã",
                        "data": "Work Folder",
                        "icon": "pi pi-fw pi-sun",
                        "children": []
                    },
                    {
                        "key": "1-1",
                        "label": "Tarde",
                        "data": "Home Folder",
                        "icon": "pi pi-fw pi-cloud",
                        "children": []
                    }, {
                        "key": "1-2",
                        "label": "Noite",
                        "data": "Home Folder",
                        "icon": "pi pi-fw pi-home",
                        "children": []
                    }]
            },
            {
                "key": "2",
                "label": "Dia 3",
                "data": "Movies Folder",
                "icon": "pi pi-fw pi-star",
                "children": [{
                    "key": "2-0",
                    "label": "Manhã",
                    "data": "Work Folder",
                    "icon": "pi pi-fw pi-sun",
                    "children": []
                },
                {
                    "key": "2-1",
                    "label": "Tarde",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-cloud",
                    "children": []
                }, {
                    "key": "2-2",
                    "label": "Noite",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-home",
                    "children": []
                }]
            },
            {
                "key": "3",
                "label": "Dia 4",
                "data": "Movies Folder",
                "icon": "pi pi-fw pi-star",
                "children": [{
                    "key": "2-0",
                    "label": "Manhã",
                    "data": "Work Folder",
                    "icon": "pi pi-fw pi-sun",
                    "children": []
                },
                {
                    "key": "2-1",
                    "label": "Tarde",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-cloud",
                    "children": []
                }, {
                    "key": "2-2",
                    "label": "Noite",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-home",
                    "children": []
                }]
            },
            {
                "key": "4",
                "label": "Dia 5",
                "data": "Movies Folder",
                "icon": "pi pi-fw pi-star",
                "children": [{
                    "key": "2-0",
                    "label": "Manhã",
                    "data": "Work Folder",
                    "icon": "pi pi-fw pi-sun",
                    "children": []
                },
                {
                    "key": "2-1",
                    "label": "Tarde",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-cloud",
                    "children": []
                }, {
                    "key": "2-2",
                    "label": "Noite",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-home",
                    "children": []
                }]
            },
            {
                "key": "5",
                "label": "Locais",
                "data": "Movies Folder",
                "icon": "pi pi-fw pi-chart-bar",
                "children": lista
            }
            ]
        };
        this.setState({ ItineraryInfoData: this.datas.root });

    }

    componentDidMount() {
        let i = 0;
        let lista = [];
        this.props.places.forEach(element => {
            let a = { "key": "5-" + i, "label": element.display, "icon": "pi pi-fw pi-file", "data": element.value };
            lista.push(a);
            i++;
        });


        if (lista.length <= 0) {
            let a = { "key": "5-" + 0, "label": "Sem locais registrados", "icon": "pi pi-fw pi-file", "data": "Expenses Document" };
            lista.push(a);
        }


        this.datas =
        {
            "root": [{
                "key": "0",
                "label": "Dia 1",
                "data": "Documents Folder",
                "icon": "pi pi-fw pi-inbox",
                "children": [{
                    "key": "0-0",
                    "label": "Manhã",
                    "data": "Work Folder",
                    "icon": "pi pi-fw pi-sun",
                    "children": []
                },
                {
                    "key": "0-1",
                    "label": "Tarde",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-cloud",
                    "children": []
                }, {
                    "key": "0-2",
                    "label": "Noite",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-home",
                    "children": []
                }]
            },
            {
                "key": "1",
                "label": "Dia 2",
                "data": "Events Folder",
                "icon": "pi pi-fw pi-calendar",
                "children":
                    [{
                        "key": "1-0",
                        "label": "Manhã",
                        "data": "Work Folder",
                        "icon": "pi pi-fw pi-sun",
                        "children": []
                    },
                    {
                        "key": "1-1",
                        "label": "Tarde",
                        "data": "Home Folder",
                        "icon": "pi pi-fw pi-cloud",
                        "children": []
                    }, {
                        "key": "1-2",
                        "label": "Noite",
                        "data": "Home Folder",
                        "icon": "pi pi-fw pi-home",
                        "children": []
                    }]
            },
            {
                "key": "2",
                "label": "Dia 3",
                "data": "Movies Folder",
                "icon": "pi pi-fw pi-star",
                "children": [{
                    "key": "2-0",
                    "label": "Manhã",
                    "data": "Work Folder",
                    "icon": "pi pi-fw pi-sun",
                    "children": []
                },
                {
                    "key": "2-1",
                    "label": "Tarde",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-cloud",
                    "children": []
                }, {
                    "key": "2-2",
                    "label": "Noite",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-home",
                    "children": []
                }]
            },
            {
                "key": "3",
                "label": "Dia 4",
                "data": "Movies Folder",
                "icon": "pi pi-fw pi-star",
                "children": [{
                    "key": "2-0",
                    "label": "Manhã",
                    "data": "Work Folder",
                    "icon": "pi pi-fw pi-sun",
                    "children": []
                },
                {
                    "key": "2-1",
                    "label": "Tarde",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-cloud",
                    "children": []
                }, {
                    "key": "2-2",
                    "label": "Noite",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-home",
                    "children": []
                }]
            },
            {
                "key": "4",
                "label": "Dia 5",
                "data": "Movies Folder",
                "icon": "pi pi-fw pi-star",
                "children": [{
                    "key": "2-0",
                    "label": "Manhã",
                    "data": "Work Folder",
                    "icon": "pi pi-fw pi-sun",
                    "children": []
                },
                {
                    "key": "2-1",
                    "label": "Tarde",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-cloud",
                    "children": []
                }, {
                    "key": "2-2",
                    "label": "Noite",
                    "data": "Home Folder",
                    "icon": "pi pi-fw pi-home",
                    "children": []
                }]
            },
            {
                "key": "5",
                "label": "Locais",
                "data": "Movies Folder",
                "icon": "pi pi-fw pi-chart-bar",
                "children": lista
            }
            ]
        };

        this.setState({ ItineraryInfoData: this.datas.root });

    }

    ClickSubmit(e) {
        if (e.target.id === "updatebutton") {
            UpdateProject = true;
        }
        else {
            UpdateProject = false;
        }

        this.setState({
            ItineraryId: sessionStorage.getItem('id_Itinerary'),
            ItineraryName: sessionStorage.getItem('itineraryName')
        });
    }

    async postPlace(e) {
        try {
            this.setState({
                isLoading: true
            });
            switch (this.state.ItineraryProfiles) {
                case 'História/Museus':
                    this.state.ItineraryProfiles = 0;
                    break;
                case 'Parques/Ar livre':
                    this.state.ItineraryProfiles = 1;
                    break;
                case 'Turistão':
                    this.state.ItineraryProfiles = 2;
                    break;
                case 'Caramba, comi bem':
                    this.state.ItineraryProfiles = 3;
                    break;
                default:
                    break;
            }

            switch (this.state.ItineraryRhythm) {
                case 'Pé no acelerador':
                    this.state.ItineraryRhythm = 1;
                    break;
                case 'Relax':
                    this.state.ItineraryRhythm = 0;
                    break;
                default:
                    break;
            }

            switch (this.state.ItineraryFirstTime) {
                case 'Sim':
                    this.state.ItineraryFirstTime = 0;
                    break;
                case 'Não':
                    this.state.ItineraryFirstTime = 1;
                    break;
                default:
                    break;
            }

            const JsonSend = this.state;
            const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + sessionStorage.getItem('token')
            };

            await Api.post('/itinerary_datainfo/infodata/' + sessionStorage.getItem('id_Itinerary'), JsonSend, { headers }).then((response) => {
                this.setState({
                    isLoading: false
                });
                return response;
            }).then(() => {
                window.alert("Enviado com Sucesso!");
            });

        }
        catch (error) {
            if (error.response !== undefined) {
                window.alert(error.response.data.error);
            }
        }

    }

    async UpdatePlace(e) {


    }

    async handleSubmit(event) {
        try {
            if (UpdateProject === true) {
                this.UpdatePlace(event);
            } else {
                if (sessionStorage.getItem('id_Itinerary') != null) {
                    this.postPlace(event);
                    window.alert("Enviado com Sucesso!");
                } else {
                    window.alert("Você não selecionou o roteiro que quer linkar");
                }
            }
            sessionStorage.removeItem('id_Itinerary');
            sessionStorage.removeItem('itineraryName');
        }
        catch (e) {
            window.alert("ERRO! " + e);
        }
    }


    render() {
        return (
            <div>
                {
                    this.state.isLoading == false ?
                        <div>
                            <Form onSubmit={this.handleSubmit}>
                                <Row>
                                    <Col>
                                        <Tree filter filterMode="strict" value={this.state.ItineraryInfoData} style={{ fontSize: '1rem', width: '100%' }} dragdropScope="demo" onDragDrop={event => { this.setState({ ItineraryInfoData: event.value }); }} />
                                    </Col>
                                </Row>
                                <Button className='dropdownAdm p-mt-3' onClick={this.handleChange}>Atualizar</Button>
                                <Row className="p-mt-5">
                                    <Col >
                                        <h4 className="TextosAuxiliares3">Perfis</h4>
                                        <ListBox value={this.state.ItineraryProfiles} options={this.perfil} onChange={(e) => this.setState({ ItineraryProfiles: e.value })} optionLabel="value" style={{ width: '100%' }} />
                                    </Col>
                                    <Col className="">
                                        <h4 className="TextosAuxiliares3" >Ritmo</h4>
                                        <ListBox value={this.state.ItineraryRhythm} options={this.ritmo} onChange={(e) => this.setState({ ItineraryRhythm: e.value })} optionLabel="value" style={{ width: '100%' }} />
                                    </Col>
                                    <Col className="">
                                        <h4 className="TextosAuxiliares3" >Primeira vez na cidade?</h4>
                                        <ListBox value={this.state.ItineraryFirstTime} options={this.primeiravez} onChange={(e) => this.setState({ ItineraryFirstTime: e.value })} optionLabel="value" style={{ width: '100%' }} />
                                    </Col>
                                    <Col className="">
                                        <h4 className="TextosAuxiliares3" >Dias</h4>
                                        <ListBox className="TextosAuxiliares4" value={this.state.ItineraryDays} options={this.dias} onChange={(e) => this.setState({ ItineraryDays: e.value })} optionLabel="value" style={{ width: '100%' }} />
                                    </Col>
                                </Row>
                                <Row className="my-3">

                                </Row>
                                <Row className="mx-5">
                                    <Button id="submitbutton" onClick={this.ClickSubmit} type="submit" className='dropdownAdm2' size="lg" >Salvar</Button>
                                </Row>
                                <Row className="mx-5">
                                    <Button id="updatebutton" onClick={this.ClickSubmit} type="submit" style={{ display: "none" }} className='dropdownAdm2' size="lg" >Update</Button>
                                </Row>
                            </Form>
                        </div>
                        :
                        <div style={{ width: "100%", height: "50vh" }}>
                            <Loading size='25%' />
                        </div>
                }
            </div>
        )
    }
}

let UpdateProject = false;
export default class FinalizarRoteiros extends Component {

    constructor(props) {
        super(props);
        this.state = {
            itineraryId: null, itineraryName: null,
        };
    }

    render() {
        return (
            <div className="cadastro">
                <Header></Header>
                <Container>
                    <Row className="m-3 d-flex justify-content-center">
                        <GetProjects></GetProjects>
                    </Row>
                </Container>
            </div >

        );
    }
}





