//COMPONENTES

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Separador from '../../components/Separador'
import Loading from '../../components/Loading'
//CSS
import "../../stylesGeral.css"
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import './CarouselDemo.css';
import { Link } from 'react-router-dom';

import { Checkbox } from 'primereact/checkbox';

//ELEMENTOS
import React, { Component } from "react";
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';
import {
  Row,
  Col,
  Container,
  Accordion,
  Nav, Tab, Tabs
} from "react-bootstrap";

//ICONES
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLink,
  faMapPin,
  faThermometerEmpty, faClock, faMoneyBill, faSuitcaseRolling, faMobileAlt, faCloud, faSun, faMoon, faCloudSun
} from "@fortawesome/free-solid-svg-icons";

import {
  faIdBadge
} from "@fortawesome/free-regular-svg-icons";

import { convertToHTML, convertFromHTML } from 'draft-convert';

import {
  convertFromRaw
} from "draft-js";

import Api from '../../services/api';

class RoteiroParte1 extends Component {

  componentDidMount() {

    Api.get('/itineraryWithoutToken/mapInformations').then((response) => {

      return response;
    }).then(data => {
      this.setState({
        ItineraryInformationsMap: data.data.projects
      });

    }).catch(error => {
      console.log(error);
    });

  }

  render() {
    console.log(this.props);
    return (
      <Row>
        <Col style={{ width: '100%', height: '50vh', backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'white', backgroundImage: 'url("' + this.props.Img + '")' }}>
          <Row style={{ height: '75%', backgroundImage: 'linear-gradient(to bottom, rgba(63, 62, 145,0), rgba(63, 62, 145,0.5)' }}></Row>
          <Row className="text-center" style={{ color: "white", backgroundImage: 'linear-gradient(to bottom, rgba(63, 62, 145,0.5), rgba(63, 62, 145,1)', height: '25%', backgroundSize: 'cover' }}>
            <Row className="">
              <h1 className="h5TextoInformacoes ">{this.props.nome} </h1>
            </Row>


            <Row >
              <Col xs={6} sm className="d-flex justify-content-end">
                <h6 className="TextosSec"> Imagem de: {this.props.author}</h6>
              </Col>
              { /*
                <Col xs={3} sm={2} >
                  <h6 className="TextosSec">{this.props.Lati_Logi}</h6>
                </Col>
               
              <Col xs={3} sm={2} >
                <h6 className="TextosSec"> <FontAwesomeIcon className="mx-2" icon={faThermometerEmpty} />Não encontrado</h6>
              </Col>
              <Col xs={3} sm={2} >
                <h6 className="TextosSec">  <FontAwesomeIcon className="mx-2" icon={faClock} />Não encontrado</h6>
              </Col>
              */}
            </Row>
          </Row>
        </Col>
      </Row>
    );
  }
}

const tryText = (id, text) => {
  try {
    //console.log(convertToHTML(convertFromRaw((JSON.parse(text)))).replaceAll("&lt;a", "<a target='_blank' ").replaceAll("&lt;/a&gt;", "</a>").replaceAll("&quot;", "\"").replaceAll("&gt;", ">").replaceAll("<strong>", "<b class='negrito'>").replaceAll("</strong>", "</b>"));
    document.getElementById(id).innerHTML = convertToHTML(convertFromRaw((JSON.parse(text)))).replaceAll("&lt;a", "<a target='_blank' ").replaceAll("&lt;/a&gt;", "</a>").replaceAll("&quot;", "\"").replaceAll("&gt;", ">").replaceAll("<strong>", "<b class='negrito'>").replaceAll("</strong>", "</b>");
  }
  catch {
    if (document.getElementById(id) !== null) {
      document.getElementById(id).innerHTML = text;
    }
  }
};

class RoteiroParte2 extends Component {

  componentDidMount() {
    tryText("resumeText", this.props.ItineraryInformationsMap.ItineraryResume);
    tryText("ItineraryWhereGo", this.props.ItineraryInformationsMap.ItineraryWhereGo);
    tryText("ItineraryTimeStay", this.props.ItineraryInformationsMap.ItineraryTimeStay);
    tryText("ItineraryHealth", this.props.ItineraryInformationsMap.ItineraryHealth);
    tryText("ItineraryPassport", this.props.ItineraryInformationsMap.ItineraryPassport);
    tryText("ItineraryHowGo", this.props.ItineraryInformationsMap.ItineraryHowGo);
    tryText("ItineraryExchange", this.props.ItineraryInformationsMap.ItineraryExchange);
    tryText("ItinerarySafety", this.props.ItineraryInformationsMap.ItinerarySafety);
    tryText("ItineraryAccommodation", this.props.ItineraryInformationsMap.ItineraryAccommodation);
    tryText("ItineraryOthers", this.props.ItineraryInformationsMap.ItineraryOthers);
    tryText("ItineraryLocomotion", this.props.ItineraryInformationsMap.ItineraryLocomotion);
    tryText("ItineraryInternet", this.props.ItineraryInformationsMap.ItineraryInternet);
  }

  render() {

    return (
      <Container>
        <Row>
          <Col md>
            <Row>
              <Col md={6}>
                <div id="resumeText" style={{ textAlign: 'justify', textJustify: 'inter-word' }} className="h6Textos"></div>
              </Col>
              <Col md>
                <iframe title="mapa" src={this.props.ItineraryInformationsMap.ItineraryMap} width="100%" height="100%" style={{ minHeight: '50vh' }}></iframe>
              </Col>
            </Row>
            <Row className="mt-5">
              <h4 className="h4 text-center">Antes de <span className="d-inline" style={{ color: "#e9434e" }}>Viajar</span></h4>
              <Col>
                <Accordion>
                  {
                    this.props.ItineraryInformationsMap.ItineraryWhereGo.length > 0 ?
                      <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} id="MelhorépocaCampo" eventKey="0">
                        <Accordion.Header>Quando ir</Accordion.Header>
                        <Accordion.Body className="p-4" id="ItineraryWhereGo">
                        </Accordion.Body>
                      </Accordion.Item>
                      :
                      null
                  }

                  {
                    this.props.ItineraryInformationsMap.ItineraryTimeStay.length > 0 ?
                      <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} id="QuantosDiasCampo" eventKey="1">
                        <Accordion.Header>Quantos dias ficar</Accordion.Header>
                        <Accordion.Body className="p-4" id="ItineraryTimeStay">
                        </Accordion.Body>
                      </Accordion.Item>
                      :
                      null
                  }

                  {
                    this.props.ItineraryInformationsMap.ItineraryHealth.length > 0 ?
                      <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} id="VacinaCampo" eventKey="2">
                        <Accordion.Header>Saúde</Accordion.Header>
                        <Accordion.Body className="p-4" id="ItineraryHealth">
                        </Accordion.Body>
                      </Accordion.Item>
                      :
                      null
                  }
                  {
                    this.props.ItineraryInformationsMap.ItineraryPassport.length > 0 ?
                      <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} id="DocumentaçãoCampo" eventKey="3">
                        <Accordion.Header>Passaporte e visto</Accordion.Header>
                        <Accordion.Body className="p-4" id="ItineraryPassport">
                        </Accordion.Body>
                      </Accordion.Item>
                      :
                      null
                  }
                </Accordion>
              </Col>
              <Col>
                <Accordion>
                  {
                    this.props.ItineraryInformationsMap.ItineraryHowGo.length > 0 ?
                      <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} id="ComoChegarCampo" eventKey="2">
                        <Accordion.Header>Como chegar</Accordion.Header>
                        <Accordion.Body className="p-4" id="ItineraryHowGo">
                        </Accordion.Body>
                      </Accordion.Item>
                      :
                      null
                  }
                  {/*
                    this.props.ItineraryInformationsMap.ItineraryHealth.length > 0 ?
                      <Accordion.Item className='h6Textos'style={{ textAlign: 'justify', textJustify: 'inter-word' }} id="SeguroCampo" eventKey="0">
                        <Accordion.Header>Seguro de vida</Accordion.Header>
                        <Accordion.Body className="p-4">
                          {this.props.ItineraryInformationsMap.ItineraryHealth}
                        </Accordion.Body>
                      </Accordion.Item>
                      :
                      null
                      */
                  }

                  {
                    /*
                    this.props.ItineraryInformationsMap.ItinerarySafety.length > 0 ?
                      <Accordion.Item className='h6Textos'style={{ textAlign: 'justify', textJustify: 'inter-word' }} id="OrganizaçãoCampo" eventKey="1">
                        <Accordion.Header>Organização do roteiro</Accordion.Header>
                        <Accordion.Body className="p-4">
                          {this.props.ItineraryInformationsMap.ItinerarySafety}
                        </Accordion.Body>
                      </Accordion.Item>
                      :
                      null
                      */
                  }
                  {
                    this.props.ItineraryInformationsMap.ItineraryExchange.length > 0 ?
                      <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} id="MoedaCampo" eventKey="3">
                        <Accordion.Header>Moeda</Accordion.Header>
                        <Accordion.Body className="p-4" id="ItineraryExchange">
                        </Accordion.Body>
                      </Accordion.Item>
                      :
                      null
                  }
                </Accordion>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md>
            <Row className="mt-5">
              <h4 className="h4 text-center"><span className="d-inline" style={{ color: "#3f3e91" }}>Quando </span>chegar lá</h4>
              <Col>
                <Accordion >
                  {
                    this.props.ItineraryInformationsMap.ItinerarySafety.length > 0 ?
                      <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} id="SegurançaCampo" eventKey="0">
                        <Accordion.Header>Segurança</Accordion.Header>
                        <Accordion.Body className="p-4" id="ItinerarySafety">
                        </Accordion.Body>
                      </Accordion.Item>
                      :
                      null
                  }
                  {
                    this.props.ItineraryInformationsMap.ItineraryAccommodation.length > 0 ?
                      <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} id="HospedagemCampo" eventKey="1">
                        <Accordion.Header>Hospedagem</Accordion.Header>
                        <Accordion.Body className="p-4" id="ItineraryAccommodation">
                        </Accordion.Body>
                      </Accordion.Item>
                      :
                      null
                  }
                  {
                    this.props.ItineraryInformationsMap.ItineraryOthers.length > 0 ?
                      <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} id="CostumesCampo" eventKey="2">
                        <Accordion.Header>Dicas</Accordion.Header>
                        <Accordion.Body className="p-4" id="ItineraryOthers">
                        </Accordion.Body>
                      </Accordion.Item>
                      :
                      null
                  }


                </Accordion>
              </Col>
              <Col>
                <Accordion>
                  {
                    this.props.ItineraryInformationsMap.ItineraryLocomotion.length > 0 ?
                      <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} id="LocomoçãoCampo" eventKey="0">
                        <Accordion.Header>Locomoção</Accordion.Header>
                        <Accordion.Body className="p-4" id="ItineraryLocomotion">
                        </Accordion.Body>
                      </Accordion.Item>
                      :
                      null
                  }
                  {
                    this.props.ItineraryInformationsMap.ItineraryInternet.length > 0 ?
                      <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} id="InternetCampo" eventKey="1">
                        <Accordion.Header>Internet</Accordion.Header>
                        <Accordion.Body className="p-4" id="ItineraryInternet">
                        </Accordion.Body>
                      </Accordion.Item>
                      :
                      null
                  }


                </Accordion>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export class CarouselDemo extends Component {

  constructor(props) {
    super(props);

    this.state = {
      products: [], valorAtualCarrosel: 0
    };

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '600px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '480px',
        numVisible: 1,
        numScroll: 1
      }
    ];


    this.productTemplate = this.productTemplate.bind(this);
    this.productTemplate2 = this.productTemplate2.bind(this);
    this.productTemplate3 = this.productTemplate3.bind(this);
    this.productTemplate4 = this.productTemplate4.bind(this);
    this.productTemplate5 = this.productTemplate5.bind(this);
    this.ClickBotao = this.ClickBotao.bind(this);
  }

  componentDidMount() {
    for (let index = 0; index < this.props.dados.LocaisFullData.length; index++) {
      const element = this.props.dados.LocaisFullData[index];
      element.idAux = index;
    }

    this.setState({
      products: this.props.dados.LocaisFullData
    });
  }

  componentDidUpdate() {
    tryText("placeDescriptText", this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeDescript);
    tryText("placeTips", this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeTips);
    tryText("placeWhereToEat", this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeWhereToEat);
    tryText("placeHowToGet", this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeHowToGet);
    tryText("placeShop", this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeShop);

  }


  ClickBotao(evt) {
    this.setState({
      valorAtualCarrosel: evt.target.id
    });

  }

  productTemplate(product) {
    return (

      <div className="product-item">
        {
          product.placeName !== "Bate e volta" && product.placeName.includes("(DT)") !== true ?
            this.props.dados.LocaisVisual[0][0].length > 0 && parseInt(product.idAux) < this.props.dados.LocaisVisual[0][0].length ?
              <div className="d-flex justify-content-center mt-3">
                <h6 id={product.idAux} className="h5Aux"><FontAwesomeIcon style={{ color: 'white' }} icon={faCloud} /> Manhã </h6>
              </div>
              :
              this.props.dados.LocaisVisual[0][1].length > 0 && parseInt(product.idAux) - this.props.dados.LocaisVisual[0][0].length < this.props.dados.LocaisVisual[0][1].length ?
                <div className="d-flex justify-content-center mt-3">
                  <h6 id={product.idAux} className="h5Aux"><FontAwesomeIcon style={{ color: 'white' }} icon={faSun} /> Tarde </h6>
                </div>
                :
                this.props.dados.LocaisVisual[0][2].length > 0 && parseInt(product.idAux) - this.props.dados.LocaisVisual[0][0].length - this.props.dados.LocaisVisual[0][1].length < this.props.dados.LocaisVisual[0][2].length ?
                  <div className="d-flex justify-content-center mt-3">
                    <h6 id={product.idAux} className="h5Aux"><FontAwesomeIcon style={{ color: 'white' }} icon={faMoon} /> Noite </h6>
                  </div> :
                  null :
            null
        }
        <div className="product-item-content">

          {
            product.placeName !== "Bate e volta" && product.placeName.includes("(DT)") !== true ?
              <button id={product.idAux} className="h5Aux" onClick={this.ClickBotao} style={{ borderRadius: '6px', height: '10rem', width: '100%', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'rgba(63, 62, 145,1)', backgroundImage: 'url("' + product.placeImage + '")' }}>
                {/*<iframe onClick={this.ClickBotao} src="https://drive.google.com/file/d/1_JY1A8m33S-9E8E1Nif7JZM_y2sdInJ8/preview" width="100%" height="100%" allow="autoplay"></iframe>*/}
              </button>
              :
              <button id={product.idAux} className="h5Aux" onClick={this.ClickBotao} style={{ borderRadius: '6px', height: '10rem', width: '100%', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'rgba(63, 62, 145,1)', backgroundImage: 'url("' + product.placeImage + '")' }}>
                {/*<iframe onClick={this.ClickBotao} src="https://drive.google.com/file/d/1_JY1A8m33S-9E8E1Nif7JZM_y2sdInJ8/preview" width="100%" height="100%" allow="autoplay"></iframe>*/}
              </button>
          }


        </div>
        {
          product.placeName !== "Bate e volta" && product.placeName.includes("(DT)") !== true ?
            <div id={product.idAux} className="d-flex justify-content-center align-items-end">
              <h6 id={product.idAux} className="h6TextosAuxBranco">{product.placeName} </h6>
            </div>
            : null
        }
      </div>
    );
  }
  productTemplate2(product) {
    return (

      <div className="product-item">
        {
          product.placeName !== "Bate e volta" && product.placeName.includes("(DT)") !== true ?
            this.props.dados.LocaisVisual[1][0].length > 0 && parseInt(product.idAux) - this.props.dados.ElementosPorDias[0] < this.props.dados.LocaisVisual[1][0].length ?
              <div className="d-flex justify-content-center mt-3">
                <h6 id={product.idAux} className="h5Aux"><FontAwesomeIcon style={{ color: 'white' }} icon={faCloud} /> Manhã </h6>
              </div>
              :
              this.props.dados.LocaisVisual[1][1].length > 0 && parseInt(product.idAux) - this.props.dados.LocaisVisual[1][0].length - this.props.dados.ElementosPorDias[0] < this.props.dados.LocaisVisual[1][1].length ?
                <div className="d-flex justify-content-center mt-3">
                  <h6 id={product.idAux} className="h5Aux"><FontAwesomeIcon style={{ color: 'white' }} icon={faSun} /> Tarde </h6>
                </div>
                :
                this.props.dados.LocaisVisual[1][2].length > 0 && parseInt(product.idAux) - this.props.dados.LocaisVisual[1][0].length - this.props.dados.LocaisVisual[1][1].length - this.props.dados.ElementosPorDias[0] < this.props.dados.LocaisVisual[1][2].length ?
                  <div className="d-flex justify-content-center mt-3">
                    <h6 id={product.idAux} className="h5Aux"><FontAwesomeIcon style={{ color: 'white' }} icon={faMoon} /> Noite </h6>
                  </div> :
                  null
            : null
        }
        <div className="product-item-content">

          {
            product.placeName !== "Bate e volta" && product.placeName.includes("(DT)") !== true ?
              <button id={product.idAux} className="h5Aux" onClick={this.ClickBotao} style={{ borderRadius: '6px', height: '10rem', width: '100%', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'rgba(63, 62, 145,1)', backgroundImage: 'url("' + product.placeImage + '")' }}>
                {/*<iframe onClick={this.ClickBotao} src="https://drive.google.com/file/d/1_JY1A8m33S-9E8E1Nif7JZM_y2sdInJ8/preview" width="100%" height="100%" allow="autoplay"></iframe>*/}
              </button>
              :
              <button id={product.idAux} className="h5Aux" onClick={this.ClickBotao} style={{ borderRadius: '6px', height: '10rem', width: '100%', backgroundPosition: 'center', backgroundColor: 'rgba(63, 62, 145,1)', backgroundImage: 'url("' + product.placeImage + '")' }}>
                {/*<iframe onClick={this.ClickBotao} src="https://drive.google.com/file/d/1_JY1A8m33S-9E8E1Nif7JZM_y2sdInJ8/preview" width="100%" height="100%" allow="autoplay"></iframe>*/}
              </button>
          }


        </div>
        {
          product.placeName !== "Bate e volta" && product.placeName.includes("(DT)") !== true ?
            <div id={product.idAux} className="d-flex justify-content-center align-items-end">
              <h6 id={product.idAux} className="h6TextosAuxBranco">{product.placeName} </h6>
            </div>
            : null
        }
      </div>
    );
  }
  productTemplate3(product) {
    return (

      <div className="product-item">
        {
          product.placeName !== "Bate e volta" && product.placeName.includes("(DT)") !== true ?
            this.props.dados.LocaisVisual[2][0].length > 0 && parseInt(product.idAux) - this.props.dados.ElementosPorDias[0] - this.props.dados.ElementosPorDias[1] < this.props.dados.LocaisVisual[2][0].length ?
              <div className="d-flex justify-content-center mt-3">
                <h6 id={product.idAux} className="h5Aux"><FontAwesomeIcon style={{ color: 'white' }} icon={faCloud} /> Manhã </h6>
              </div>
              :
              this.props.dados.LocaisVisual[2][1].length > 0 && parseInt(product.idAux) - this.props.dados.LocaisVisual[2][0].length - this.props.dados.ElementosPorDias[0] - this.props.dados.ElementosPorDias[1] < this.props.dados.LocaisVisual[2][1].length ?
                <div className="d-flex justify-content-center mt-3">
                  <h6 id={product.idAux} className="h5Aux"><FontAwesomeIcon style={{ color: 'white' }} icon={faSun} /> Tarde </h6>
                </div>
                :
                this.props.dados.LocaisVisual[2][2].length > 0 && parseInt(product.idAux) - this.props.dados.LocaisVisual[2][0].length - this.props.dados.LocaisVisual[2][1].length - this.props.dados.ElementosPorDias[0] - this.props.dados.ElementosPorDias[1] < this.props.dados.LocaisVisual[2][2].length ?
                  <div className="d-flex justify-content-center mt-3">
                    <h6 id={product.idAux} className="h5Aux"><FontAwesomeIcon style={{ color: 'white' }} icon={faMoon} /> Noite </h6>
                  </div> :
                  null
            : null
        }
        <div className="product-item-content">

          {
            product.placeName !== "Bate e volta" && product.placeName.includes("(DT)") !== true ?
              <button id={product.idAux} className="h5Aux" onClick={this.ClickBotao} style={{ borderRadius: '6px', height: '10rem', width: '100%', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'rgba(63, 62, 145,1)', backgroundImage: 'url("' + product.placeImage + '")' }}>
                {/*<iframe onClick={this.ClickBotao} src="https://drive.google.com/file/d/1_JY1A8m33S-9E8E1Nif7JZM_y2sdInJ8/preview" width="100%" height="100%" allow="autoplay"></iframe>*/}
              </button>
              :
              <button id={product.idAux} className="h5Aux" onClick={this.ClickBotao} style={{ borderRadius: '6px', height: '10rem', width: '100%', backgroundPosition: 'center', backgroundColor: 'rgba(63, 62, 145,1)', backgroundImage: 'url("' + product.placeImage + '")' }}>
                {/*<iframe onClick={this.ClickBotao} src="https://drive.google.com/file/d/1_JY1A8m33S-9E8E1Nif7JZM_y2sdInJ8/preview" width="100%" height="100%" allow="autoplay"></iframe>*/}
              </button>
          }

        </div>
        {
          product.placeName !== "Bate e volta" && product.placeName.includes("(DT)") !== true ?
            <div id={product.idAux} className="d-flex justify-content-center align-items-end">
              <h6 id={product.idAux} className="h6TextosAuxBranco">{product.placeName} </h6>
            </div>
            : null
        }
      </div>
    );
  }
  productTemplate4(product) {
    return (

      <div className="product-item">
        {
          product.placeName !== "Bate e volta" && product.placeName.includes("(DT)") !== true ?
            this.props.dados.LocaisVisual[3][0].length > 0 && parseInt(product.idAux) - this.props.dados.ElementosPorDias[0] - this.props.dados.ElementosPorDias[1] - this.props.dados.ElementosPorDias[2] < this.props.dados.LocaisVisual[3][0].length ?
              <div className="d-flex justify-content-center mt-3">
                <h6 id={product.idAux} className="h5Aux"><FontAwesomeIcon style={{ color: 'white' }} icon={faCloud} /> Manhã </h6>
              </div>
              :
              this.props.dados.LocaisVisual[3][1].length > 0 && parseInt(product.idAux) - this.props.dados.LocaisVisual[3][0].length - this.props.dados.ElementosPorDias[0] - this.props.dados.ElementosPorDias[1] - this.props.dados.ElementosPorDias[1] < this.props.dados.LocaisVisual[3][1].length ?
                <div className="d-flex justify-content-center mt-3">
                  <h6 id={product.idAux} className="h5Aux"><FontAwesomeIcon style={{ color: 'white' }} icon={faSun} /> Tarde </h6>
                </div>
                :
                this.props.dados.LocaisVisual[3][2].length > 0 && parseInt(product.idAux) - this.props.dados.LocaisVisual[3][0].length - this.props.dados.LocaisVisual[3][1].length - this.props.dados.ElementosPorDias[0] - this.props.dados.ElementosPorDias[1] - this.props.dados.ElementosPorDias[2] < this.props.dados.LocaisVisual[3][2].length ?
                  <div className="d-flex justify-content-center mt-3">
                    <h6 id={product.idAux} className="h5Aux"><FontAwesomeIcon style={{ color: 'white' }} icon={faMoon} /> Noite </h6>
                  </div> :
                  null
            : null
        }
        <div className="product-item-content">

          {
            product.placeName !== "Bate e volta" && product.placeName.includes("(DT)") !== true ?
              <button id={product.idAux} className="h5Aux" onClick={this.ClickBotao} style={{ borderRadius: '6px', height: '10rem', width: '100%', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'rgba(63, 62, 145,1)', backgroundImage: 'url("' + product.placeImage + '")' }}>
                {/*<iframe onClick={this.ClickBotao} src="https://drive.google.com/file/d/1_JY1A8m33S-9E8E1Nif7JZM_y2sdInJ8/preview" width="100%" height="100%" allow="autoplay"></iframe>*/}
              </button>
              :
              <button id={product.idAux} className="h5Aux" onClick={this.ClickBotao} style={{ borderRadius: '6px', height: '10rem', width: '100%', backgroundPosition: 'center', backgroundColor: 'rgba(63, 62, 145,1)', backgroundImage: 'url("' + product.placeImage + '")' }}>
                {/*<iframe onClick={this.ClickBotao} src="https://drive.google.com/file/d/1_JY1A8m33S-9E8E1Nif7JZM_y2sdInJ8/preview" width="100%" height="100%" allow="autoplay"></iframe>*/}
              </button>
          }


        </div>
        {
          product.placeName !== "Bate e volta" && product.placeName.includes("(DT)") !== true ?
            <div id={product.idAux} className="d-flex justify-content-center align-items-end">
              <h6 id={product.idAux} className="h6TextosAuxBranco">{product.placeName} </h6>
            </div>
            : null
        }
      </div>
    );
  }
  productTemplate5(product) {
    return (

      <div className="product-item">
        {
          product.placeName !== "Bate e volta" && product.placeName.includes("(DT)") !== true ?
            this.props.dados.LocaisVisual[4][0].length > 0 && parseInt(product.idAux) - this.props.dados.ElementosPorDias[0] - this.props.dados.ElementosPorDias[1] - this.props.dados.ElementosPorDias[2] - this.props.dados.ElementosPorDias[3] < this.props.dados.LocaisVisual[4][0].length ?
              <div className="d-flex justify-content-center mt-3">
                <h6 id={product.idAux} className="h5Aux"><FontAwesomeIcon style={{ color: 'white' }} icon={faCloud} /> Manhã </h6>
              </div>
              :
              this.props.dados.LocaisVisual[4][1].length > 0 && parseInt(product.idAux) - this.props.dados.LocaisVisual[4][0].length - this.props.dados.ElementosPorDias[0] - this.props.dados.ElementosPorDias[1] - this.props.dados.ElementosPorDias[2] - this.props.dados.ElementosPorDias[3] < this.props.dados.LocaisVisual[4][1].length ?
                <div className="d-flex justify-content-center mt-3">
                  <h6 id={product.idAux} className="h5Aux"><FontAwesomeIcon style={{ color: 'white' }} icon={faSun} /> Tarde </h6>
                </div>
                :
                this.props.dados.LocaisVisual[4][2].length > 0 && parseInt(product.idAux) - this.props.dados.LocaisVisual[4][0].length - this.props.dados.LocaisVisual[4][1].length - this.props.dados.ElementosPorDias[0] - this.props.dados.ElementosPorDias[1] - this.props.dados.ElementosPorDias[2] - this.props.dados.ElementosPorDias[3] < this.props.dados.LocaisVisual[4][2].length ?
                  <div className="d-flex justify-content-center mt-3">
                    <h6 id={product.idAux} className="h5Aux"><FontAwesomeIcon style={{ color: 'white' }} icon={faMoon} /> Noite </h6>
                  </div> :
                  null
            : null
        }
        <div className="product-item-content">

          {
            product.placeName !== "Bate e volta" && product.placeName.includes("(DT)") !== true ?
              <button id={product.idAux} className="h5Aux" onClick={this.ClickBotao} style={{ borderRadius: '6px', height: '10rem', width: '100%', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'rgba(63, 62, 145,1)', backgroundImage: 'url("' + product.placeImage + '")' }}>
                {/*<iframe onClick={this.ClickBotao} src="https://drive.google.com/file/d/1_JY1A8m33S-9E8E1Nif7JZM_y2sdInJ8/preview" width="100%" height="100%" allow="autoplay"></iframe>*/}
              </button>
              :
              <button id={product.idAux} className="h5Aux" onClick={this.ClickBotao} style={{ borderRadius: '6px', height: '10rem', width: '100%', backgroundPosition: 'center', backgroundColor: 'rgba(231, 232, 234)', backgroundImage: 'url("' + product.placeImage + '")' }}>
                {/*<iframe onClick={this.ClickBotao} src="https://drive.google.com/file/d/1_JY1A8m33S-9E8E1Nif7JZM_y2sdInJ8/preview" width="100%" height="100%" allow="autoplay"></iframe>*/}
              </button>
          }


        </div>
        {
          product.placeName !== "Bate e volta" && product.placeName.includes("(DT)") !== true ?
            <div id={product.idAux} className="d-flex justify-content-center align-items-end">
              <h6 id={product.idAux} className="h6TextosAuxBranco">{product.placeName} </h6>
            </div>
            : null
        }
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="carousel-demo" id="CarouselLocais0" style={{ display: 'block' }}>
          <div className="card" style={{ backgroundColor: '#e9434e', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'top', backgroundImage: "url('/imagens/Fundo3.png')" }}>
            <Carousel value={this.state.products.slice(0, this.props.dados.ElementosPorDias[0])} numVisible={5} numScroll={5} responsiveOptions={this.responsiveOptions} className="custom-carousel" circular
              itemTemplate={this.productTemplate} />
          </div>
        </div>
        <div className="carousel-demo" id="CarouselLocais1" style={{ display: 'none' }}>
          <div className="card" style={{ backgroundColor: '#e9434e', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'top', backgroundImage: "url('/imagens/Fundo3.png')" }}>
            <Carousel value={this.state.products.slice(this.props.dados.ElementosPorDias[0], this.props.dados.ElementosPorDias[0] + this.props.dados.ElementosPorDias[1])} numVisible={5} numScroll={5} responsiveOptions={this.responsiveOptions} className="custom-carousel" circular
              itemTemplate={this.productTemplate2} />
          </div>
        </div>
        <div className="carousel-demo" id="CarouselLocais2" style={{ display: 'none' }}>
          <div className="card" style={{ backgroundColor: '#e9434e', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'top', backgroundImage: "url('/imagens/Fundo3.png')" }}>
            <Carousel value={this.state.products.slice(this.props.dados.ElementosPorDias[0] + this.props.dados.ElementosPorDias[1], this.props.dados.ElementosPorDias[0] + this.props.dados.ElementosPorDias[1] + this.props.dados.ElementosPorDias[2])} numVisible={5} numScroll={5} responsiveOptions={this.responsiveOptions} className="custom-carousel" circular
              itemTemplate={this.productTemplate3} />
          </div>
        </div>
        <div className="carousel-demo" id="CarouselLocais3" style={{ display: 'none' }}>
          <div className="card" style={{ backgroundColor: '#e9434e', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'top', backgroundImage: "url('/imagens/Fundo3.png')" }}>
            <Carousel value={this.state.products.slice(this.props.dados.ElementosPorDias[0] + this.props.dados.ElementosPorDias[1] + this.props.dados.ElementosPorDias[2], this.props.dados.ElementosPorDias[0] + this.props.dados.ElementosPorDias[1] + this.props.dados.ElementosPorDias[2] + this.props.dados.ElementosPorDias[3])} numVisible={5} numScroll={5} responsiveOptions={this.responsiveOptions} className="custom-carousel" circular
              itemTemplate={this.productTemplate4} />
          </div>
        </div>
        <div className="carousel-demo" id="CarouselLocais4" style={{ display: 'none' }}>
          <div className="card" style={{ backgroundColor: '#e9434e', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'top', backgroundImage: "url('/imagens/Fundo3.png')" }}>
            <Carousel value={this.state.products.slice(this.props.dados.ElementosPorDias[0] + this.props.dados.ElementosPorDias[1] + this.props.dados.ElementosPorDias[2] + this.props.dados.ElementosPorDias[3], this.props.dados.ElementosPorDias[0] + this.props.dados.ElementosPorDias[1] + this.props.dados.ElementosPorDias[2] + this.props.dados.ElementosPorDias[3] + this.props.dados.ElementosPorDias[4])} numVisible={5} numScroll={5} responsiveOptions={this.responsiveOptions} className="custom-carousel" circular
              itemTemplate={this.productTemplate5} />
          </div>
        </div>
        <Container>
          <Row className="mt-4">
            <Col md={8} >
              <Row>
                <Col md={6}>
                  <h4 className="h4">{this.props.dados.placeName}</h4>
                  <div id="placeDescriptText" className="h6Textos" style={{ textAlign: 'justify', textJustify: 'inter-word' }}></div>
                </Col>
                <Col md>
                  <iframe title="mapa" src={this.props.dados.ItineraryInformationsMap.ItineraryMap} width="100%" height="100%" style={{ minHeight: '35vh' }}></iframe>
                </Col>
              </Row>
              {
                <Row>
                  <Col className="m-3" style={{ height: '50vh', backgroundRepeat: 'no-repeat', borderRadius: '8px', backgroundSize: '100%', backgroundPosition: 'center', backgroundColor: 'white', backgroundImage: 'url("' + this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeImage + '")' }}>
                  </Col>
                </Row>
              }
              <Row className="my-3">
                <Col>
                  <Accordion>
                    {
                      this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeSuggestedTimes.length > 0 ?
                        <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} eventKey="1">
                          <Accordion.Header>Horário sugerido para ir</Accordion.Header>
                          <Accordion.Body className="p-4">
                            {this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeSuggestedTimes}
                          </Accordion.Body>
                        </Accordion.Item>
                        :
                        null
                    }
                    {
                      this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeWhereToEat.length > 0 ?
                        <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} eventKey="2">
                          <Accordion.Header>Onde Comer</Accordion.Header>
                          <Accordion.Body className="p-4" id="placeWhereToEat">
                          </Accordion.Body>
                        </Accordion.Item>
                        :
                        null
                    }
                    {
                      this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeHowToGet.length > 0 ?
                        <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} eventKey="3">
                          <Accordion.Header>Como chegar</Accordion.Header>
                          <Accordion.Body className="p-4" id="placeHowToGet">
                          </Accordion.Body>
                        </Accordion.Item>
                        :
                        null
                    }
                    {
                      this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeShop.length > 0 ?
                        <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} eventKey="4">
                          <Accordion.Header>Onde comprar</Accordion.Header>
                          <Accordion.Body className="p-4" id="placeShop">
                          </Accordion.Body>
                        </Accordion.Item>
                        :
                        null
                    }
                    {
                      this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeVisitacionTime.length > 0 ?
                        <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} eventKey="5">
                          <Accordion.Header>Tempo de visitação</Accordion.Header>
                          <Accordion.Body className="p-4">
                            {this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeVisitacionTime}
                          </Accordion.Body>
                        </Accordion.Item>
                        :
                        null
                    }
                    {
                      this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeTips.length > 0 ?
                        <Accordion.Item className='h6Textos' style={{ textAlign: 'justify', textJustify: 'inter-word' }} eventKey="6">
                          <Accordion.Header>Dicas e Curiosidades</Accordion.Header>
                          <Accordion.Body className="p-4" id="placeTips">
                          </Accordion.Body>
                        </Accordion.Item>
                        :
                        null
                    }
                  </Accordion>
                </Col>
              </Row>
            </Col>
            <Col md>
              <Row><h6 className="h6TextosPreto">Informações</h6></Row>
              <Separador color='rgb(63, 62, 145)'></Separador>
              {
                this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeAdress.length > 0 &&
                <Row>
                  <Col xs={5} xl={1} className="align-self-center">
                    <FontAwesomeIcon icon={faMapPin} size='lg' color='rgb(63, 62, 145)' />
                  </Col>
                  <Col xs={5} xl className="mt-3">
                    <h6 style={{ textAlign: 'justify', textJustify: 'inter-word' }} className="h6TextosAux"> {this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeAdress}</h6>
                  </Col>
                </Row>
              }
              {
                this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeImportantLinks.length > 0 &&
                <Row className="mt-2">
                  <Col xs={5} xl={1} className="align-self-center">
                    <FontAwesomeIcon icon={faLink} size='lg' color='rgb(63, 62, 145)' />
                  </Col>
                  <Col xs={5} xl>
                    <a target="_blank" rel="noopener noreferrer" href={this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeImportantLinks}>{this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeImportantLinks}</a>
                  </Col>
                </Row>
              }
              {
                this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeOpenTime.length > 0 &&
                <Row className="mt-2">
                  <Col xs={5} xl={1} className="align-self-center">
                    <FontAwesomeIcon icon={faClock} size='lg' color='rgb(63, 62, 145)' />
                  </Col>
                  <Col xs={5} xl className="">
                    <h6 style={{ textAlign: 'justify', textJustify: 'inter-word' }} className="h6TextosAux">{this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placeOpenTime}</h6>
                  </Col>
                </Row>
              }
              {
                this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placePrice.length > 0 &&
                <Row className="mt-2">
                  <Col xs={5} xl={1} className="align-self-center">
                    <FontAwesomeIcon icon={faMoneyBill} size='lg' color='rgb(63, 62, 145)' />
                  </Col>
                  <Col className="">
                    <h6 style={{ textAlign: 'justify', textJustify: 'inter-word' }} className="h6TextosAux">{this.props.dados.LocaisFullData[this.state.valorAtualCarrosel].placePrice}</h6>
                  </Col>
                </Row>
              }
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      ItineraryInformationsMap: [], ItineraryInformationsDays: [], valoresDiasVisual: [], LocaisVisual: [], LocaisFullData: [], valorAtualCarrosel: 0, valorAtualNav: 0, valorAtualCarroselAux: [0, 0, 0, 0, 0],
      checkeds: [], isLoading: true
    };

    this.onCityChange = this.onCityChange.bind(this);
    this.ClickNav = this.ClickNav.bind(this);
  }

  parseURLParams(url) {
    var queryStart = url.indexOf("?") + 1,
      queryEnd = url.indexOf("#") + 1 || url.length + 1,
      query = url.slice(queryStart, queryEnd - 1),
      pairs = query.replace(/\+/g, " ").split("&"),
      parms = {}, i, n, v, nv;

    if (query === url || query === "") return;

    for (i = 0; i < pairs.length; i++) {
      nv = pairs[i].split("=", 2);
      n = decodeURIComponent(nv[0]);
      v = decodeURIComponent(nv[1]);

      if (!parms.hasOwnProperty(n)) parms[n] = [];
      parms[n].push(nv.length === 2 ? v : null);
    }
    return parms;
  }

  componentDidMount() {
    this.syc();
  }

  async syc() {
    let dados = this.parseURLParams(window.location.href);

    await Api.get('/itineraryWithoutToken/GetItinerary/' + dados.itineraryId).then((response) => {

      return response;
    }).then(data => {
      this.setState({
        ItineraryInformationsMap: data.data.projects
      });

    }).catch(error => {
      console.log(error);
    });

    await Api.get('/itineraryWithoutToken/GetItineraryDays/' + dados.itineraryId + "&" + dados.preferencias + "&" + dados.velocidade + "&" + dados.primeiraVez + "&" + dados.numeroDeDias).then((response) => {
      return response;
    }).then(data => {
      this.setState({
        ItineraryInformationsDays: data.data.projects
      });
      if (this.state.ItineraryInformationsDays[0] != undefined) {
        let somador = 0;
        for (let i = 0; i < parseInt(this.state.ItineraryInformationsDays[0].ItineraryDays); i++) {

          this.state.valoresDiasVisual.push({ name: 'Dia ' + (i + 1), eventKey: i.toString() });
          this.state.LocaisVisual.push([]);
          for (let k = 0; k < 3; k++) {
            this.state.LocaisVisual[i].push([]);
            for (let j = 0; j < parseInt(this.state.ItineraryInformationsDays[0].ItineraryInfoData[i].children[k].children.length); j++) {
              this.state.LocaisVisual[i][k].push({ name: this.state.ItineraryInformationsDays[0].ItineraryInfoData[i].children[k].children[j].label, data: this.state.ItineraryInformationsDays[0].ItineraryInfoData[i].children[k].children[j].data, id: somador });
              somador += 1;
            }
          }
        }
      }
    }).catch(error => {
      console.log(error);
    });
    let auxList = [];
    let auxList2 = [];
    let auxList3 = [];
    let somador = 0;
    if (this.state.ItineraryInformationsDays[0] != undefined) {
      for (let i = 0; i < parseInt(this.state.ItineraryInformationsDays[0].ItineraryDays); i++) {
        somador = 0;
        for (let k = 0; k < 3; k++) {
          somador += parseInt(this.state.ItineraryInformationsDays[0].ItineraryInfoData[i].children[k].children.length);
          for (let j = 0; j < parseInt(this.state.ItineraryInformationsDays[0].ItineraryInfoData[i].children[k].children.length); j++) {
            await Api.get('/itineraryWithoutToken/byItinerary/' + this.state.LocaisVisual[i][k][j].data).then((response) => {
              return response;
            }).then(data => {
              console.log(data);
              auxList.push(data.data.places);
              auxList2.push(data.data.places.placeImage);

              this.state.LocaisVisual[i][k][j].image = auxList2[auxList2.length - 1];
              this.setState({
                isLoading: false
              });
            }).catch(error => {
              console.log(error);
            });
          }
        }
        auxList3.push(somador);
      }
    }
    this.setState({
      LocaisFullData: auxList,
      ElementosPorDias: auxList3
    });

  }


  ClickNav(evt) {

    this.setState({
      valorAtualNav: evt,
    });

    document.getElementById("CarouselLocais" + evt).style.display = "block";
    for (let i = 0; i < this.state.ElementosPorDias.length; i++) {
      if (evt != i) {
        document.getElementById("CarouselLocais" + i).style.display = "none";
      }
    }
  }


  onCityChange = (e) => {
    let selectedCities = [...this.state.checkeds];
    if (e.checked)
      selectedCities.push(e.value);
    else
      selectedCities.splice(selectedCities.indexOf(e.value), 1);

    this.setState({
      checkeds: selectedCities
    });
  }

  render() {
    if (!this.state.isLoading) {
      if (this.state.LocaisFullData[0] !== undefined) {
        return (
          <div className="telaRoteiros">
            <Container fluid>
              <Header></Header>
              <RoteiroParte1 nome={this.state.ItineraryInformationsMap.ItineraryName} Lati_Logi={this.state.ItineraryInformationsMap.ItineraryLatitude + ", " + this.state.ItineraryInformationsMap.ItineraryLongitude} Img={this.state.ItineraryInformationsMap.ItineraryImage} author={this.state.ItineraryInformationsMap.ItineraryImageAuthor}></RoteiroParte1>
            </Container>
            <Container fluid>
              <Container>
                <Tabs defaultActiveKey="Visão geral" id="uncontrolled-tab-example " className="mb-3 mt-3 h6TextosAux">
                  <Tab eventKey="Visão geral" title="Visão geral">
                    <RoteiroParte2 ItineraryInformationsMap={this.state.ItineraryInformationsMap}></RoteiroParte2>
                  </Tab>
                  <Tab eventKey="Diário de bordo" title="Diário de bordo">
                    <Row
                      className="justify-content-center mb-4"
                    >
                      <Container fluid>
                        <Nav style={{ width: "100%" }} variant="pills" defaultActiveKey="0" justify="true" fill="true" onSelect={this.ClickNav}>
                          {this.state.valoresDiasVisual.map((radio) => (
                            <Nav.Item key={radio.eventKey} >
                              <Nav.Link className="BotoesDias" id={"BotoesDias" + radio.eventKey} eventKey={radio.eventKey} > {radio.name}</Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                      </Container>
                    </Row>
                    <CarouselDemo dados={this.state}></CarouselDemo>
                  </Tab>
                  <Tab eventKey="CheckList" title="CheckList">
                    <Row className="mb-3">
                      <Col xs={3} sm={1} className="d-flex justify-content-center">
                        <FontAwesomeIcon style={{ color: "#3f3e91" }} icon={faIdBadge} size='4x' />
                      </Col>
                      <Col xs={5} sm className="mt-3">
                        <h2 className="h2CheckList">DOCUMENTOS</h2>
                      </Col>
                    </Row>
                    <Row className="m-3">
                      <div className="">
                        <Checkbox className="mb-2 mx-2" inputId="cb1" value="Passaporte, se necessário(atente para a validade)" onChange={this.onCityChange} checked={this.state.checkeds.includes('Passaporte, se necessário(atente para a validade)')}></Checkbox>
                        <label htmlFor="cb1" className="p-checkbox-label">Passaporte, se necessário(atente para a validade)</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div className="">
                        <Checkbox className="mb-2 mx-2" inputId="cb2" value="Outros documentos de identificação, se necessário" onChange={this.onCityChange} checked={this.state.checkeds.includes('Outros documentos de identificação, se necessário')}></Checkbox>
                        <label htmlFor="cb2" className="p-checkbox-label">Outros documentos de identificação, se necessário</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb3" value="CNH e Permissão Internacional para Dirigir - PID, se for dirigir" onChange={this.onCityChange} checked={this.state.checkeds.includes('CNH e Permissão Internacional para Dirigir - PID, se for dirigir')}></Checkbox>
                        <label htmlFor="cb3" className="p-checkbox-label ">CNH e Permissão Internacional para Dirigir - PID, se for dirigir</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb4" value="Autorização de viagem para menores " onChange={this.onCityChange} checked={this.state.checkeds.includes('Autorização de viagem para menores ')}></Checkbox>
                        <label htmlFor="cb4" className="p-checkbox-label ">Autorização de viagem para menores </label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb5" value="Passagens aéreas" onChange={this.onCityChange} checked={this.state.checkeds.includes('Passagens aéreas')}></Checkbox>
                        <label htmlFor="cb5" className="p-checkbox-label ">Passagens aéreas</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb6" value="Visto" onChange={this.onCityChange} checked={this.state.checkeds.includes('Visto')}></Checkbox>
                        <label htmlFor="cb6" className="p-checkbox-label ">Visto</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb7" value="Certificado de vacinas" onChange={this.onCityChange} checked={this.state.checkeds.includes('Certificado de vacinas')}></Checkbox>
                        <label htmlFor="cb7" className="p-checkbox-label ">Certificado de vacinas</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb8" value="Apólice de seguro saúde" onChange={this.onCityChange} checked={this.state.checkeds.includes('Apólice de seguro saúde')}></Checkbox>
                        <label htmlFor="cb8" className="p-checkbox-label ">Apólice de seguro saúde</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb9" value="Cópia do comprovante  de hospedagem e passagem de retorno para o Brasil" onChange={this.onCityChange} checked={this.state.checkeds.includes('Cópia do comprovante  de hospedagem e passagem de retorno para o Brasil')}></Checkbox>
                        <label htmlFor="cb9" className="p-checkbox-label ">Cópia do comprovante  de hospedagem e passagem de retorno para o Brasil</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb10" value="Comprovante de passagens de trem, ônibus ou aluguel de carro" onChange={this.onCityChange} checked={this.state.checkeds.includes('Comprovante de passagens de trem, ônibus ou aluguel de carro')}></Checkbox>
                        <label htmlFor="cb10" className="p-checkbox-label ">Comprovante de passagens de trem, ônibus ou aluguel de carro</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb11" value="Vouchers de passeios ou entradas" onChange={this.onCityChange} checked={this.state.checkeds.includes('Vouchers de passeios ou entradas')}></Checkbox>
                        <label htmlFor="cb11" className="p-checkbox-label ">Vouchers de passeios ou entradas</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb12" value="Desbloquear cartão internacional para compras no exterior" onChange={this.onCityChange} checked={this.state.checkeds.includes('Desbloquear cartão internacional para compras no exterior')}></Checkbox>
                        <label htmlFor="cb12" className="p-checkbox-label ">Desbloquear cartão internacional para compras no exterior</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb13" value="Comprovantes financeiros - dólar ou moeda local e limites de cartões" onChange={this.onCityChange} checked={this.state.checkeds.includes('Comprovantes financeiros - dólar ou moeda local e limites de cartões')}></Checkbox>
                        <label htmlFor="cb13" className="p-checkbox-label ">Comprovantes financeiros - dólar ou moeda local e limites de cartões</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb14" value="Doleira " onChange={this.onCityChange} checked={this.state.checkeds.includes('Doleira ')}></Checkbox>
                        <label htmlFor="cb14" className="p-checkbox-label ">Doleira </label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb15" value="Fotos de todos esses documentos em e-mail ou nuvem" onChange={this.onCityChange} checked={this.state.checkeds.includes('Fotos de todos esses documentos em e-mail ou nuvem')}></Checkbox>
                        <label htmlFor="cb15" className="p-checkbox-label ">Fotos de todos esses documentos em e-mail ou nuvem</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb16" value="Fazer uma pasta com cópias de todos esses documentos" onChange={this.onCityChange} checked={this.state.checkeds.includes('Fazer uma pasta com cópias de todos esses documentos')}></Checkbox>
                        <label htmlFor="cb16" className="p-checkbox-label ">Fazer uma pasta com cópias de todos esses documentos</label>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={3} sm={1} className="d-flex justify-content-center">
                        <FontAwesomeIcon style={{ color: "#3f3e91" }} icon={faMobileAlt} size='4x' />
                      </Col>
                      <Col xs={5} sm className="mt-3">
                        <h2 className="h2CheckList">ELETRÔNICOS E ACESSÓRIOS</h2>
                      </Col>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb17" value="Celulares e carregadores" onChange={this.onCityChange} checked={this.state.checkeds.includes('Celulares e carregadores')}></Checkbox>
                        <label htmlFor="cb17" className="p-checkbox-label ">Celulares e carregadores</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb18" value="Bateria extra " onChange={this.onCityChange} checked={this.state.checkeds.includes('Bateria extra ')}></Checkbox>
                        <label htmlFor="cb18" className="p-checkbox-label ">Bateria extra </label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb19" value="Câmera fotográfica e cartão de memória" onChange={this.onCityChange} checked={this.state.checkeds.includes('Câmera fotográfica e cartão de memória')}></Checkbox>
                        <label htmlFor="cb19" className="p-checkbox-label ">Câmera fotográfica e cartão de memória</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb20" value="Cadeados e identificadores para mala" onChange={this.onCityChange} checked={this.state.checkeds.includes('Cadeados e identificadores para mala')}></Checkbox>
                        <label htmlFor="cb20" className="p-checkbox-label ">Cadeados e identificadores para mala</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb21" value="Adaptador de tomadas universal" onChange={this.onCityChange} checked={this.state.checkeds.includes('Adaptador de tomadas universal')}></Checkbox>
                        <label htmlFor="cb21" className="p-checkbox-label ">Adaptador de tomadas universal</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb22" value="Chip internacional ou pacote telefônico internacional" onChange={this.onCityChange} checked={this.state.checkeds.includes('Chip internacional ou pacote telefônico internacional')}></Checkbox>
                        <label htmlFor="cb22" className="p-checkbox-label ">Chip internacional ou pacote telefônico internacional</label>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={3} sm={1} className="d-flex justify-content-center">
                        <FontAwesomeIcon style={{ color: "#3f3e91" }} icon={faSuitcaseRolling} size='4x' />
                      </Col>
                      <Col xs={5} sm className="mt-3">
                        <h2 className="h2CheckList">MALA DE MÃO</h2>
                      </Col>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb23" value="Doleira" onChange={this.onCityChange} checked={this.state.checkeds.includes('Doleira')}></Checkbox>
                        <label htmlFor="cb23" className="p-checkbox-label ">Doleira</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb24" value="Fones de ouvido" onChange={this.onCityChange} checked={this.state.checkeds.includes('Fones de ouvido')}></Checkbox>
                        <label htmlFor="cb24" className="p-checkbox-label ">Fones de ouvido</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb25" value="Almofada para pescoço" onChange={this.onCityChange} checked={this.state.checkeds.includes('Almofada para pescoço')}></Checkbox>
                        <label htmlFor="cb25" className="p-checkbox-label ">Almofada para pescoço</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb26" value="Muda de roupa na mala de mão" onChange={this.onCityChange} checked={this.state.checkeds.includes('Muda de roupa na mala de mão')}></Checkbox>
                        <label htmlFor="cb26" className="p-checkbox-label ">Muda de roupa na mala de mão</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb27" value="Objetos de valor como relógios, jóias ou eletrônicos. " onChange={this.onCityChange} checked={this.state.checkeds.includes('Objetos de valor como relógios, jóias ou eletrônicos. ')}></Checkbox>
                        <label htmlFor="cb27" className="p-checkbox-label ">Objetos de valor como relógios, jóias ou eletrônicos. </label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb28" value="Remédios de uso diário ou controlados" onChange={this.onCityChange} checked={this.state.checkeds.includes('Remédios de uso diário ou controlados')}></Checkbox>
                        <label htmlFor="cb28" className="p-checkbox-label ">Remédios de uso diário ou controlados</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb29" value="Monte sua farmácia com medicamentos básicos" onChange={this.onCityChange} checked={this.state.checkeds.includes('Monte sua farmácia com medicamentos básicos')}></Checkbox>
                        <label htmlFor="cb29" className="p-checkbox-label ">Monte sua farmácia com medicamentos básicos</label>
                      </div>
                    </Row>
                    <Row className="m-3">
                      <div >
                        <Checkbox className="mb-2 mx-2" inputId="cb30" value="Itens de uso pessoal e básicos de higiene(limite de 100ml e necessaire ser transparente)" onChange={this.onCityChange} checked={this.state.checkeds.includes('Itens de uso pessoal e básicos de higiene(limite de 100ml e necessaire ser transparente)')}></Checkbox>
                        <label htmlFor="cb30" className="p-checkbox-label ">Itens de uso pessoal e básicos de higiene(limite de 100ml e necessaire ser transparente)</label>
                      </div>
                    </Row>
                  </Tab>
                </Tabs>
              </Container>
            </Container>
            <Footer></Footer>
          </div >
        );
      } else {
        {/*<>
            <h2 className="h2CheckList text-center m-5">ROTEIRO NÃO ENCONTRADO</h2>
            <Row className="d-flex justify-content-center">
              <Col xs={3} sm={1} className="d-flex justify-content-center">
                <Link to='/' className="my-2" >  <Button className="" style={{ border: '2px solid white', backgroundColor: 'transparent', borderRadius: '50px', width: '90%', height: '100%', color: 'white' }}> VOLTAR</Button> </Link>
              </Col>
            </Row>
          </>*/}
        return (
          <div style={{ width: "100%", height: "100vh" }}>
            <Loading size='10%' />
          </div>
        );
      }
    }
    else {
      return (
        <div style={{ width: "100%", height: "100vh" }}>
          <Loading size='10%' />
        </div>
      )
    }
  }
}
