import React, { Component, useEffect, useRef } from 'react';
import { Row, Col, Container } from 'react-bootstrap'

import Header from '../../components/Header'
import Footer from '../../components/Footer'

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { convertToHTML, convertFromHTML } from 'draft-convert';

import { Editor, EditorState, convertFromRaw, CompositeDecorator } from "draft-js";


import Api from '../../services/api';

import Loading from '../../components/Loading'

import "./textCss.css"

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote': return 'RichEditor-blockquote';
        default: return null;
    }
}

const styles = {
    urlInputContainer: {
        marginBottom: 10,
    },
    button: {
        marginTop: 10,
        textAlign: 'center',
    },
    link: {
        color: '#3b5998',
        textDecoration: 'underline',
    },
};


function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
}


const Link = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a target="_blank" rel="noopener noreferrer" href={url} style={styles.link}>
            {props.children}
        </a>
    );
};

function RichEditorReadOnly(props) {

    const element = useRef(null);

    useEffect(() => {
        element.current.innerHTML = (props.data.convertedContent).replace("<p>&lt;a", "<a target='_blank' ").replace("&lt;/a&gt;</p>", "</a>").replace("&quot;", "\"").replace("&quot;", "\"").replace("&gt;", ">").replace("<strong>", "<b class='negrito'>").replace("</strong>", "</b>");
    }, [])

    const decorator = new CompositeDecorator([
        {
            strategy: findLinkEntities,
            component: Link,
        },
    ]);

    /*const [state, setState] = useState({
        editorState: EditorState.createWithContent(
            props.data.convertedContent, decorator)
    });*/



    return (
        <div>
            <div className="Postagem">
                <div className="my-4">
                    <div ref={element}>

                    </div>
                </div>
            </div>
        </div>

    );
}

const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
};


class Blog extends Component {

    constructor(props) {
        super(props);

        this.state = { PostInformations: [], convertedContent: null, isLoading: true };
        this.syc = this.syc.bind(this);
    }


    parseURLParams(url) {
        var queryStart = url.indexOf("?") + 1,
            queryEnd = url.indexOf("#") + 1 || url.length + 1,
            query = url.slice(queryStart, queryEnd - 1),
            pairs = query.replace(/\+/g, " ").split("&"),
            parms = {}, i, n, v, nv;

        if (query === url || query === "") return;

        for (i = 0; i < pairs.length; i++) {
            nv = pairs[i].split("=", 2);
            n = decodeURIComponent(nv[0]);
            v = decodeURIComponent(nv[1]);

            if (!parms.hasOwnProperty(n)) parms[n] = [];
            parms[n].push(nv.length === 2 ? v : null);
        }
        return parms;
    }

    componentDidMount() {
        this.syc();

    }

    async syc() {
        let dados = this.parseURLParams(window.location.href);

        await Api.get('/itineraryWithoutToken/blog/' + dados.blogId).then((response) => {

            return response;
        }).then(data => {
            this.setState({
                PostInformations: data.data.posts,
                convertedContent: convertToHTML(convertFromRaw(JSON.parse((data.data.posts.convertedContent)))),
                isLoading: false
            });
        }).catch(error => {
            console.log(error);
        });


    }

    render() {
        return (
            <div>
                {
                    this.state.isLoading == false ?
                        <div className="blog">
                            <Header></Header>
                            <Container>
                                <Row className="mt-4">
                                    <h3>{this.state.PostInformations.title}</h3>
                                </Row>
                                <Row className="mt-1">
                                    <h6 style={{ color: 'gray' }}>{(new Date(this.state.PostInformations.createdAt)).toLocaleString()}, {this.state.PostInformations.author}</h6>
                                </Row>
                                <Row>
                                    <Col md={9}>
                                        <Row >
                                            <Col className="m-2" style={{ height: '50vh', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'rgb(63, 62, 145)', borderRadius: '10px', backgroundImage: 'url("' + this.state.PostInformations.image + '")' }}>
                                            </Col>
                                        </Row >
                                        {
                                            this.state.convertedContent != null ?
                                                <RichEditorReadOnly data={this.state}></RichEditorReadOnly>
                                                : null

                                        }

                                    </Col>
                                    <Col md>

                                    </Col>
                                </Row>
                            </Container>
                            <Footer> </Footer>
                        </div>
                        :
                        <div style={{ width: "100%", height: "100vh" }}>
                            <Loading size='10%' />
                        </div>
                }
            </div>
        );
    }
}

export default Blog;