import React, { Component } from 'react';
import Api from '../../services/api';

import {
    Row,
    Col,
    Container,
    Card,
    ListGroup,
    ListGroupItem,
    Form, Button, Dropdown, Modal
} from "react-bootstrap";

import Header from '../../components/Header'
import Loading from '../../components/Loading'

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
    Editor, EditorState, RichUtils, getDefaultKeyBinding, convertToRaw, convertFromRaw,
    CompositeDecorator
} from "draft-js";
import "draft-js/dist/Draft.css";
import "./textCss.css"

import { faSearch, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styles = {
    root: {
        fontFamily: '\'Georgia\', serif',
        padding: 20,
        width: 400,
    },
    buttons: {
        marginBottom: 10,
    },
    urlInputContainer: {
        marginBottom: 10,
    },
    urlInput: {
        fontFamily: '\'Georgia\', serif',
        marginRight: 10,
        padding: 3,
    },
    editor: {
        border: '1px solid #ccc',
        cursor: 'text',
        minHeight: 80,
        padding: 10,
    },
    button: {
        marginTop: 10,
        textAlign: 'center',
    },
    link: {
        color: '#3b5998',
        textDecoration: 'underline',
    },
};

const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
};

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote': return 'RichEditor-blockquote';
        default: return null;
    }
}

function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
}


const Link = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url} style={styles.link}>
            {props.children}
        </a>
    );
};

class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'RichEditor-styleButton';
        if (this.props.active) {
            className += ' RichEditor-activeButton';
        }

        return (
            <span className={className} onMouseDown={this.onToggle}>
                {this.props.label}
            </span>
        );
    }
}

const BLOCK_TYPES = [
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'H4', style: 'header-four' },
    { label: 'H5', style: 'header-five' },
    { label: 'H6', style: 'header-six' },
    { label: 'Blockquote', style: 'blockquote' },
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
    { label: 'Code Block', style: 'code-block' },
];

const BlockStyleControls = (props) => {
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

var INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    { label: 'Monospace', style: 'CODE' },
];

const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();

    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};
let testeValorInput = "";
class RichEditorExample extends React.Component {
    constructor(props) {

        super(props);

        const decorator = new CompositeDecorator([
            {
                strategy: findLinkEntities,
                component: Link,
            },
        ]);

        if (this.props.textOut != '') {
            let conversao = '';
            try {
                conversao = convertFromRaw(JSON.parse(this.props.textOut));

            }
            catch (err) {
                conversao = convertFromRaw({ "blocks": [{ "key": "cdqv5", "text": this.props.textOut, "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }], "entityMap": {} });

            }
            this.state = {
                title: null, resume: null, image: null,
                imageAuthor: null, author: null, convertedContent: null, editorState: EditorState.createWithContent(
                    conversao, decorator), showURLInput: false,
                urlValue: '',
            };

        } else {
            this.state = {
                title: null, resume: null, image: null,
                imageAuthor: null, author: null, convertedContent: null, editorState: EditorState.createEmpty(decorator), showURLInput: false,
                urlValue: '',
            };

        }

        this.focus = () => this.refs.editor.focus();
        this.onChange = (editorState) => {
            this.setState({
                editorState,
                convertedContent: JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()))
            })
            testeValorInput = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));
        };


        this.handleKeyCommand = this._handleKeyCommand.bind(this);
        this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
        this.toggleBlockType = this._toggleBlockType.bind(this);
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.Post = this.Post.bind(this);

    }

    logState = () => {
        const content = this.state.editorState.getCurrentContent();
    };

    onURLChange = (e) => this.setState({ urlValue: e.target.value });

    promptForLink = (e) => {
        e.preventDefault();
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = editorState.getSelection().getStartKey();
            const startOffset = editorState.getSelection().getStartOffset();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
            let url = '';
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                url = linkInstance.getData().url;
            }
            this.setState({
                showURLInput: true,
                urlValue: url,
            }, () => {
                setTimeout(() => this.refs.url.focus(), 0);
            });
        }
    }

    confirmLink = (e) => {
        e.preventDefault();
        const { editorState, urlValue } = this.state;
        const contentState = editorState.getCurrentContent();

        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            { url: urlValue }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        // Apply entity
        let nextEditorState = EditorState.set(editorState,
            { currentContent: contentStateWithEntity }
        );

        // Apply selection
        nextEditorState = RichUtils.toggleLink(nextEditorState,
            nextEditorState.getSelection(), entityKey
        );

        this.setState({
            editorState: nextEditorState,
            showURLInput: false,
            urlValue: '',
        }, () => {
            setTimeout(() => this.refs.editor.focus(), 0);
        });
    }

    onLinkInputKeyDown = (e) => { if (e.which === 13) { this.confirmLink(e); } }

    removeLink = (e) => {
        e.preventDefault();
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            this.setState({
                editorState: RichUtils.toggleLink(editorState, selection, null),
            });
        }
    }



    _handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    }

    _mapKeyToEditorCommand(e) {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                this.state.editorState,
                4, /* maxDepth */
            );
            if (newEditorState !== this.state.editorState) {
                this.onChange(newEditorState);
            }
            return;
        }
        return getDefaultKeyBinding(e);
    }

    _toggleBlockType(blockType) {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }

    _toggleInlineStyle(inlineStyle) {
        this.onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    }

    async Post(e) {
        try {

            function replacer(key, value) {
                if (key == "editorState") return undefined;
                else return value;
            }


            let JsonSend = JSON.stringify(this.state, replacer);

            const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + sessionStorage.getItem('token')
            };

            await Api.post('/blog/create', JsonSend, { headers }).then((response) => {
                return response;
            }).then(() => {
                e.preventDefault();
            });
        }
        catch (error) {
            console.log(error);
            window.alert("ERRO");
        }
        this.forceUpdate();

    }


    handleSubmit = (e) => {

        this.setState({ convertedContent: JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())) });

        this.Post(e);
        window.alert("SALVO");
    }

    render() {
        const { editorState, showURLInput } = this.state;

        let className = 'RichEditor-editor';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return (

            < div id="form" >
                <div className="Postagem">
                    <div className="RichEditor-root m-5">
                        <BlockStyleControls
                            editorState={editorState}
                            onToggle={this.toggleBlockType}
                        />
                        <InlineStyleControls
                            editorState={editorState}
                            onToggle={this.toggleInlineStyle}
                        />
                        <div style={styles.buttons}>
                            <button
                                onMouseDown={this.promptForLink}
                                style={{ marginRight: 10 }}>
                                Add Link
                            </button>
                            <button onMouseDown={this.removeLink}>
                                Remove Link
                            </button>
                        </div>
                        {

                            showURLInput == true ?
                                <div style={styles.urlInputContainer}>
                                    <input
                                        onChange={this.onURLChange}
                                        ref="url"
                                        style={styles.urlInput}
                                        type="text"
                                        value={this.state.urlValue}
                                        onKeyDown={this.onLinkInputKeyDown}
                                    />
                                    <button onMouseDown={this.confirmLink}> Confirm </button>
                                </div>
                                : null
                        }
                        <div className={className} onClick={this.focus}>
                            <Editor
                                blockStyleFn={getBlockStyle}
                                customStyleMap={styleMap}
                                editorState={editorState}
                                handleKeyCommand={this.handleKeyCommand}
                                keyBindingFn={this.mapKeyToEditorCommand}
                                onChange={this.onChange}
                                placeholder="Escreva o conteúdo..."
                                ref="editor"
                                spellCheck={true}
                            /*textAlignment={}*/
                            />
                        </div>
                    </div>
                </div>
            </div >

        );
    }
}

let color2 = '#3e3d90';
class GetProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roteiros: [],
            informacoesRoteiroAtual: [{ id: "", ItineraryName: "", ItineraryResume: "", ItineraryWhereGo: "", ItineraryTimeStay: "", ItineraryHowGo: "", ItinerarySafety: "", ItineraryHealth: "", ItineraryImage: "", ItineraryLatitude: 0, ItineraryLongitude: 0, ItineraryDay: 1, ItineraryMap: "", ItineraryPassport: "", ItineraryExchange: "", ItineraryInternet: "", ItineraryAccommodation: "", ItineraryExpensiveOrCheap: "", ItineraryMovieMusicAndOthers: "", ItineraryLocomotion: "", ItineraryOthers: "" }],
            selectedRoteiros: "",
            selectedRoteirosDisplay: "",
            validationError: "",
            places: []
        };

        this.handleChange = this.handleChange.bind(this);
        this.click = this.click.bind(this);
    }

    componentDidMount() {

        sessionStorage.removeItem('id_Itinerary');

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
        };

        Api.get('/itinerary', { headers }).then((response) => {
            return response;
        }).then(data => {
            let roteirosFromApi = data.data.projects.map(data => {

                return { value: data._id, display: data.ItineraryName }
            });
            this.setState({
                roteiros: roteirosFromApi
            });

        }).catch(error => {
            console.log(error);
        });
    }

    handleChange(event) {
        document.getElementById("submitbutton").style.display = "none";
        document.getElementById("updatebutton").style.display = "block";
        this.setState({ selectedRoteiros: event.target.getAttribute("value"), selectedRoteirosDisplay: event.target.getAttribute("display") });
        sessionStorage.setItem('id_Itinerary', event.target.getAttribute("value"));
        sessionStorage.setItem('itineraryName', event.target.getAttribute("display"));
        this.click();
    }

    async click() {
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
        };

        Api.get('/places/byItinerary/' + sessionStorage.getItem('id_Itinerary'), { headers }).then((response) => {
            return response;
        }).then(data => {
            let placesFromApi = data.data.places.map(data => {
                return { value: data._id, display: data.placeName }
            });

            this.setState({
                places: placesFromApi
            });

        }).catch(error => {
            console.log(error);
        });

    }



    render() {
        return (
            <div>
                <Col md>
                    <h6 className="TextosAdm">Você tá cadastrando os locais para:<h4>{this.state.selectedRoteirosDisplay} </h4> </h6>
                    <Dropdown>
                        <Dropdown.Toggle className='dropdownAdm' id="dropdown-basic" size="lg">
                            Escolha o roteiro que você quer cadastrar os locais
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ overflowY: 'scroll', height: '20vh' }}>
                            {this.state.roteiros.map((roteiro) => {
                                return (
                                    <Dropdown.Item style={{ border: '0px solid #3e3d90', borderBottomWidth: '1px', color: color2 }} key={roteiro.value} onClick={this.handleChange} value={roteiro.value} display={roteiro.display}>{roteiro.display}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col md className="mt-2">
                    <GetPlaces info={this.state.places}></GetPlaces>
                </Col>
            </div>

        )
    }
}

class GetPlaces extends Component {
    constructor(props) {
        super(props);
        this.state = {
            places: [],
            placeInformations: [{
                id: "", placeName: "", placeDescript: "", placeHowToGet: "", placeImage: "", placeImageAuthor: "", placeSuggestedTimes: "",
                placeVisitacionTime: "", placePrice: "", placeOpenTime: "", placePhone: "", placeWhereToEat: "", placeShop: "",
                placeTips: "", placeAdress: "", placeImportantLinks: "", itineraryId: "", itineraryName: ""
            }],
            selectedRoteiros: "",
            selectedRoteirosDisplay: "",
            validationError: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.GetPlaces = this.GetPlaces.bind(this);
        this.Refresh = this.Refresh.bind(this);
    }

    Refresh() {

        this.setState({
            places: this.props.info
        });

        this.forceUpdate();

    }

    async GetPlaces(selected) {

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
        };

        sessionStorage.setItem('id_Place', selected);

        await Api.get('/places/' + selected, { headers }).then((response) => {
            return response;
        }).then(data => {
            let dataMap = data.data.places;

            this.setState({
                placeInformations: {
                    id: dataMap._id, placeName: dataMap.placeName, placeDescript: dataMap.placeDescript, placeHowToGet: dataMap.placeHowToGet, placeImage: dataMap.placeImage,
                    placeImageAuthor: dataMap.placeImageAuthor, placeSuggestedTimes: dataMap.placeSuggestedTimes, placeVisitacionTime: dataMap.placeVisitacionTime, placePrice: dataMap.placePrice,
                    placeOpenTime: dataMap.placeOpenTime, placePhone: dataMap.placePhone, placeWhereToEat: dataMap.placeWhereToEat, placeShop: dataMap.placeShop, placeTips: dataMap.placeTips,
                    placeAdress: dataMap.placeAdress, placeImportantLinks: dataMap.placeImportantLinks, itineraryId: dataMap.itineraryId, itineraryName: dataMap.itineraryName
                }
            });

            document.getElementById("placeName").value = this.state.placeInformations.placeName;
            document.getElementById("placeDescript").value = this.state.placeInformations.placeDescript;
            document.getElementById("placeHowToGet").value = this.state.placeInformations.placeHowToGet;
            document.getElementById("placeImage").value = this.state.placeInformations.placeImage;
            document.getElementById("placeImageAuthor").value = this.state.placeInformations.placeImageAuthor;
            document.getElementById("placeSuggestedTimes").value = this.state.placeInformations.placeSuggestedTimes;
            document.getElementById("placeVisitacionTime").value = this.state.placeInformations.placeVisitacionTime;
            document.getElementById("placePrice").value = this.state.placeInformations.placePrice;
            document.getElementById("placeOpenTime").value = this.state.placeInformations.placeOpenTime;
            document.getElementById("placePhone").value = this.state.placeInformations.placePhone;
            document.getElementById("placeWhereToEat").value = this.state.placeInformations.placeWhereToEat;
            document.getElementById("placeShop").value = this.state.placeInformations.placeShop;
            document.getElementById("placeTips").value = this.state.placeInformations.placeTips;
            document.getElementById("placeAdress").value = this.state.placeInformations.placeAdress;
            document.getElementById("placeImportantLinks").value = this.state.placeInformations.placeImportantLinks;


        }).catch(error => {
            console.log(error);
        });

    }

    handleChange(event) {
        document.getElementById("submitbutton").style.display = "none";
        document.getElementById("updatebutton").style.display = "block";
        this.setState({ selectedRoteiros: event.target.getAttribute("value"), selectedRoteirosDisplay: event.target.getAttribute("display") });
        this.GetPlaces(event.target.getAttribute("value"));
    }


    render() {
        return (
            <div>
                <Dropdown onClick={this.Refresh}>
                    <Dropdown.Toggle className='dropdownAdm' id="dropdown-basic" size="lg" >
                        Locais cadastrados
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ overflowY: 'scroll', height: '20vh', width: '40%' }}>
                        {this.state.places.map((a) => {
                            return (
                                <Row style={{ border: '0px solid #3e3d90', borderBottomWidth: '1px', color: color2 }}>
                                    <Col md={9}>
                                        <Dropdown.Item key={a.value} onClick={this.handleChange} value={a.value}>{a.display}
                                        </Dropdown.Item>
                                    </Col>
                                    <Col md={1}>
                                        <div id="buttonX">
                                            <Button id="buttonXRoteiros" variant="flat" size="sm" type="submit" onClick={async () => {
                                                if (window.confirm("Deseja exluir a postagem?")) {

                                                    const headers = {
                                                        "Content-Type": "application/json; charset=utf-8",
                                                        "Authorization": "Bearer " + sessionStorage.getItem('token')
                                                    };

                                                    await Api.delete('/places/remove/' + a.value, { headers }).then((response) => {
                                                        return response;
                                                    }).finally(data => {
                                                        //this.Refresh();
                                                    }).catch(error => {
                                                        console.log(error);
                                                    });
                                                }
                                            }}> <FontAwesomeIcon style={{ color: '#e7424d' }} id="teste" className="" icon={faPowerOff} />
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>

        )
    }
}

let UpdateProject = false;
export default class CriarEvento extends Component {

    constructor(props) {
        super(props);
        this.state = {
            placeName: "", placeDescript: "", placeHowToGet: "", placeImage: "", placeImageAuthor: "", placeSuggestedTimes: "",
            placeVisitacionTime: "", placePrice: "", placeOpenTime: "", placePhone: "", placeWhereToEat: "", placeShop: "",
            placeTips: "", placeAdress: "", placeImportantLinks: "", itineraryId: null, itineraryName: null, ModalShow: false, InputIdAtual: "", textoAtual: "", isLoading: false
        };

        this.ClickSubmit = this.ClickSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.postPlace = this.postPlace.bind(this);
        this.newPlaceFunction = this.newPlaceFunction.bind(this);
        this.changeModalHide = this.changeModalHide.bind(this);
        this.changeModalShow = this.changeModalShow.bind(this);
    }

    changeModalHide = () => {
        this.setState({
            ModalShow: false,
        });

        document.getElementById(this.state.InputIdAtual).value = testeValorInput;
    }
    changeModalShow = (evt) => {
        this.textoAtual = evt.target.value;
        testeValorInput = evt.target.value;
        //document.getElementById(this.state.InputIdAtual).value = evt.target.value;
        this.setState({
            ModalShow: true,
            InputIdAtual: evt.target.id
        });
    }

    ClickSubmit(e) {
        if (e.target.id === "updatebutton") {
            UpdateProject = true;
        }
        else {
            UpdateProject = false;
        }

        this.setState({
            placeName: document.getElementById("placeName").value,
            placeDescript: document.getElementById("placeDescript").value,
            placeHowToGet: document.getElementById("placeHowToGet").value,
            placeImage: document.getElementById("placeImage").value,
            placeImageAuthor: document.getElementById("placeImageAuthor").value,
            placeSuggestedTimes: document.getElementById("placeSuggestedTimes").value,
            placeVisitacionTime: document.getElementById("placeVisitacionTime").value,
            placePrice: document.getElementById("placePrice").value,
            placeOpenTime: document.getElementById("placeOpenTime").value,
            placePhone: document.getElementById("placePhone").value,
            placeWhereToEat: document.getElementById("placeWhereToEat").value,
            placeShop: document.getElementById("placeShop").value,
            placeTips: document.getElementById("placeTips").value,
            placeAdress: document.getElementById("placeAdress").value,
            placeImportantLinks: document.getElementById("placeImportantLinks").value,
            itineraryId: sessionStorage.getItem('id_Itinerary'),
            itineraryName: sessionStorage.getItem('itineraryName')
        });
    }

    async handleSubmit(event) {
        try {
            if (UpdateProject === true) {
                this.UpdatePlace(event);
            } else {
                if (sessionStorage.getItem('id_Itinerary') != null) {
                    this.postPlace(event);
                } else {
                    window.alert("Você não selecionou o roteiro que quer linkar");
                }
            }
            sessionStorage.removeItem('id_Itinerary');
            sessionStorage.removeItem('itineraryName');
        }
        catch (e) {
            window.alert("ERRO! " + e);
        }
    }

    handleChange(event) {
        switch (event.target.id) {
            case "placeName":
                this.setState({ placeName: event.target.value });
                break;
            case "placeDescript":
                this.setState({ placeDescript: event.target.value });
                break;
            case "placeHowToGet":
                this.setState({ placeHowToGet: event.target.value });
                break;
            case "placeImage":
                this.setState({ placeImage: event.target.value });
                break;
            case "placeImageAuthor":
                this.setState({ placeImageAuthor: event.target.value });
                break;
            case "placeSuggestedTimes":
                this.setState({ placeSuggestedTimes: event.target.value });
                break;
            case "placeVisitacionTime":
                this.setState({ placeVisitacionTime: event.target.value });
                break;
            case "placeWhereToEat":
                this.setState({ placeWhereToEat: event.target.value });
                break;
            case "placeShop":
                this.setState({ placeShop: event.target.value });
                break;
            case "placeTips":
                this.setState({ placeTips: event.target.value });
                break;
            case "placeAdress":
                this.setState({ placeAdress: event.target.value });
                break;
            case "placeImportantLinks":
                this.setState({ placeImportantLinks: event.target.value });
                break;
            default:
                console.log("ERRO SWITCH");
                ;
        }
    }

    async postPlace(e) {
        try {
            this.setState({
                isLoading: true
            });

            function replacer(key, value) {
                if (key == "ModalShow" || key == "InputIdAtual" || key == "textoAtual" || key == "isLoading") return undefined;
                else return value;
            }

            let JsonSend = JSON.stringify(this.state, replacer);

            const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + sessionStorage.getItem('token')
            };

            await Api.post('/places', JsonSend, { headers }).then((response) => {
                this.setState({
                    isLoading: false
                });
                return response;
            }).then(() => {
                window.alert("Enviado com Sucesso!");
            });
        }
        catch (error) {
            if (error.response !== undefined) {
                window.alert(error.response.data.error);
            }
        }

    }

    async UpdatePlace(e) {
        try {
            this.setState({
                isLoading: true
            });

            function replacer(key, value) {
                if (key == "ModalShow" || key == "InputIdAtual" || key == "textoAtual" || key == "isLoading") return undefined;
                else return value;
            }

            let JsonSend = JSON.stringify(this.state, replacer);

            const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + sessionStorage.getItem('token')
            };
            await Api.put('/places/' + sessionStorage.getItem('id_Place'), JsonSend, { headers }).then((response) => {
                this.setState({
                    isLoading: false
                });
                return response;
            }).then(() => {
                window.alert("Atualizado com Sucesso!");
            });
        }
        catch (error) {
            if (error.response !== undefined) {
                window.alert(error.response.data.error);
            }
        }

    }

    newPlaceFunction() {
        document.getElementById("submitbutton").style.display = "block";
        document.getElementById("updatebutton").style.display = "none";

        document.getElementById("placeName").value = "";
        document.getElementById("placeDescript").value = "";
        document.getElementById("placeHowToGet").value = "";
        document.getElementById("placeImage").value = "";
        document.getElementById("placeImageAuthor").value = "";
        document.getElementById("placeSuggestedTimes").value = "";
        document.getElementById("placeVisitacionTime").value = "";
        document.getElementById("placePrice").value = "";
        document.getElementById("placeOpenTime").value = "";
        document.getElementById("placePhone").value = "";
        document.getElementById("placeWhereToEat").value = "";
        document.getElementById("placeShop").value = "";
        document.getElementById("placeTips").value = "";
        document.getElementById("placeAdress").value = "";
        document.getElementById("placeImportantLinks").value = "";


        this.setState({
            placeName: document.getElementById("placeName").value,
            placeDescript: document.getElementById("placeDescript").value,
            placeHowToGet: document.getElementById("placeHowToGet").value,
            placeImage: document.getElementById("placeImage").value,
            placeImageAuthor: document.getElementById("placeImageAuthor").value,
            placeSuggestedTimes: document.getElementById("placeSuggestedTimes").value,
            placeVisitacionTime: document.getElementById("placeVisitacionTime").value,
            placePrice: document.getElementById("placePrice").value,
            placeOpenTime: document.getElementById("placeOpenTime").value,
            placePhone: document.getElementById("placePhone").value,
            placeWhereToEat: document.getElementById("placeWhereToEat").value,
            placeShop: parseFloat(document.getElementById("placeShop").value),
            placeTips: parseFloat(document.getElementById("placeTips").value),
            placeAdress: document.getElementById("placeAdress").value,
            placeImportantLinks: document.getElementById("placeImportantLinks").value,
            itineraryId: null
        });
    }


    render() {
        return (
            <div>
                {
                    this.state.isLoading == false ?
                        <div className="cadastro TextosAdm">
                            <Header></Header>
                            <Container>
                                <Form onSubmit={this.handleSubmit}>
                                    <div id="COMPONENTE">
                                        <Row className="my-3 d-flex justify-content-center">
                                            <GetProjects></GetProjects>
                                        </Row>
                                        <Row className="justify-content-center mb-3">
                                            <Col>
                                                <Card>
                                                    <ListGroup className="list-group-flush">
                                                        <Row className="m-3">
                                                            <Col md={8}>
                                                                <Button id="newPlace" className='dropdownAdm' onClick={this.newPlaceFunction} size="lg" >Novo Local</Button>
                                                            </Col>
                                                        </Row>
                                                        <ListGroupItem>
                                                            <Card.Body>
                                                                <Card.Title className="TextosAdmSize">Nome do local:
                                                                         <Form.Group className="mt-2" controlId="placeName">
                                                                        <Form.Control as="input" />
                                                                    </Form.Group>
                                                                </Card.Title>
                                                                <Row>
                                                                    <Col>
                                                                        <Card.Title className="TextosAdmSize">Link da imagem:
                                                                         <Form.Group className="mt-2" controlId="placeImage">
                                                                                <Form.Control as="input" rows={1} />
                                                                            </Form.Group>
                                                                        </Card.Title>
                                                                    </Col>
                                                                    <Col>
                                                                        <Card.Title className="TextosAdmSize">Nome do autor da imagem:
                                                                         <Form.Group className="mt-2" controlId="placeImageAuthor">
                                                                                <Form.Control as="input" rows={1} />
                                                                            </Form.Group>
                                                                        </Card.Title>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <Card.Title className="TextosAdmSize">Descrição:
                                                                         <Form.Group className="mt-2" controlId="placeDescript" onClick={this.changeModalShow}>
                                                                                <Form.Control as="input" />
                                                                            </Form.Group></Card.Title>
                                                                    </Col>
                                                                    <Col>
                                                                        <Card.Title className="TextosAdmSize">Como chegar:
                                                                         <Form.Group className="mt-2" controlId="placeHowToGet" onClick={this.changeModalShow}>
                                                                                <Form.Control as="input" />
                                                                            </Form.Group>
                                                                        </Card.Title>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </ListGroupItem>
                                                        <ListGroupItem>
                                                            <Card.Body>
                                                                <Row>
                                                                    <Col>
                                                                        <Card.Title className="TextosAdmSize">Horarios surgeridos:
                                                                        <Form.Group className="mt-2" controlId="placeSuggestedTimes">
                                                                                <Form.Control as="textarea" rows={5} /></Form.Group>
                                                                        </Card.Title>
                                                                    </Col>
                                                                    <Col>
                                                                        <Card.Title className="TextosAdmSize">Tempo de visitação:
                                                                        <Form.Group className="mt-2" controlId="placeVisitacionTime">
                                                                                <Form.Control as="textarea" rows={5} /></Form.Group>
                                                                        </Card.Title>
                                                                    </Col>
                                                                    <Col>
                                                                        <Card.Title className="TextosAdmSize">Valor:
                                                                        <Form.Group className="mt-2" controlId="placePrice">
                                                                                <Form.Control as="textarea" rows={5} /></Form.Group>
                                                                        </Card.Title>
                                                                    </Col>
                                                                    <Col>
                                                                        <Card.Title className="TextosAdmSize">Horário de funcionamento:
                                                                        <Form.Group className="mt-2" controlId="placeOpenTime">
                                                                                <Form.Control as="textarea" rows={5} /></Form.Group>
                                                                        </Card.Title>
                                                                    </Col>
                                                                    <Col>
                                                                        <Card.Title className="TextosAdmSize">Telefone:
                                                                        <Form.Group className="mt-2" controlId="placePhone">
                                                                                <Form.Control as="textarea" rows={5} /></Form.Group>
                                                                        </Card.Title>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>

                                                        </ListGroupItem>
                                                        <ListGroupItem>
                                                            <Card.Body>
                                                                <Card.Title className="TextosAdmSize">Onde comer:
                                                                        <Form.Group className="mt-2" controlId="placeWhereToEat" onClick={this.changeModalShow}>
                                                                        <Form.Control as="input" /></Form.Group>
                                                                </Card.Title>
                                                                <Card.Title className="TextosAdmSize">Compras:
                                                                        <Form.Group className="mt-2" controlId="placeShop" onClick={this.changeModalShow}>
                                                                        <Form.Control as="input" /></Form.Group>
                                                                </Card.Title>

                                                                <Card.Title className="TextosAdmSize">Dicas e Curiosidades:
                                                                        <Form.Group className="mt-2" controlId="placeTips" onClick={this.changeModalShow}>
                                                                        <Form.Control as="input" /></Form.Group>
                                                                </Card.Title>
                                                            </Card.Body>
                                                        </ListGroupItem>
                                                        <ListGroupItem>
                                                            <Card.Body>
                                                                <Row>
                                                                    <Col>
                                                                        <Card.Title className="TextosAdmSize">Endereço:
                                                                        <Form.Group className="mt-2" controlId="placeAdress">
                                                                                <Form.Control as="textarea" rows={1} /></Form.Group>
                                                                        </Card.Title>
                                                                    </Col>
                                                                </Row>


                                                                <Card.Title className="TextosAdmSize">Links importantes:
                                                                        <Form.Group className="mt-2" controlId="placeImportantLinks">
                                                                        <Form.Control as="textarea" rows={3} /></Form.Group>
                                                                </Card.Title>
                                                            </Card.Body>
                                                        </ListGroupItem>
                                                    </ListGroup>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row className="mx-5">
                                            <Button className='dropdownAdm2' id="submitbutton" onClick={this.ClickSubmit} type="submit" size="lg" >Salvar</Button>
                                        </Row>
                                        <Row className="mx-5">
                                            <Button style={{ display: 'none' }} className='dropdownAdm2' id="updatebutton" onClick={this.ClickSubmit} type="submit" size="lg" >Update</Button>
                                        </Row>
                                        <Row className="my-3">

                                        </Row>
                                    </div>
                                </Form>
                                <Modal
                                    show={this.state.ModalShow}
                                    onHide={this.changeModalHide}
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                >
                                    <Modal.Header closeButton>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <RichEditorExample textOut={this.textoAtual} />
                                    </Modal.Body>
                                </Modal>
                            </Container>
                        </div >
                        :
                        <div style={{ width: "100%", height: "50vh" }}>
                            <Loading size='5%' />
                        </div>
                }
            </div>

        );
    }
}





