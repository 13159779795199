import React, { Component } from 'react';
import Api from '../../services/api';

import {
    Dropdown, Col, Button, Row
} from "react-bootstrap";


import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { faSearch, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

let color2 = '#3e3d90';

export default class GetProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roteiros: [],
            informacoesRoteiroAtual: [{ id: "", ItineraryName: "", ItineraryResume: "", ItineraryWhereGo: "", ItineraryTimeStay: "", ItineraryHowGo: "", ItinerarySafety: "", ItineraryHealth: "", ItineraryImage: "", ItineraryImageAuthor: "", ItineraryLatitude: 0, ItineraryLongitude: 0, ItineraryDay: 1, ItineraryMap: "", ItineraryPassport: "", ItineraryExchange: "", ItineraryInternet: "", ItineraryAccommodation: "", ItineraryExpensiveOrCheap: "", ItineraryMovieMusicAndOthers: "", ItineraryLocomotion: "", ItineraryOthers: "" }],
            selectedRoteiros: "",
            validationError: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.GetItinerary = this.GetItinerary.bind(this);
        this.Atualizar = this.Atualizar.bind(this);
    }

    componentDidMount() {
        this.Atualizar();
    }

    Atualizar() {
        document.getElementById("submitbutton").style.display = "block";
        document.getElementById("updatebutton").style.display = "none";

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
        };

        Api.get('/itinerary', { headers }).then((response) => {
            return response;
        }).then(data => {
            let roteirosFromApi = data.data.projects.map(data => {

                return { value: data._id, display: data.ItineraryName }
            });
            this.setState({
                roteiros: roteirosFromApi
            });


        }).catch(error => {
            console.log(error);
        });
    }
    async GetItinerary(selected) {

        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
        };

        sessionStorage.setItem('id_project', selected);

        await Api.get('/itinerary/' + selected, { headers }).then((response) => {
            return response;
        }).then(data => {
            let dataMap = data.data.projects;

            this.setState({
                informacoesRoteiroAtual: { id: dataMap._id, ItineraryName: dataMap.ItineraryName, ItineraryResume: dataMap.ItineraryResume, ItineraryWhereGo: dataMap.ItineraryWhereGo, ItineraryTimeStay: dataMap.ItineraryTimeStay, ItineraryHowGo: dataMap.ItineraryHowGo, ItinerarySafety: dataMap.ItinerarySafety, ItineraryHealth: dataMap.ItineraryHealth, ItineraryImageAuthor: dataMap.ItineraryImageAuthor, ItineraryImage: dataMap.ItineraryImage, ItineraryLatitude: dataMap.ItineraryLatitude, ItineraryLongitude: dataMap.ItineraryLongitude, ItineraryDay: dataMap.ItineraryDay, ItineraryMap: dataMap.ItineraryMap, ItineraryPassport: dataMap.ItineraryPassport, ItineraryExchange: dataMap.ItineraryExchange, ItineraryInternet: dataMap.ItineraryInternet, ItineraryAccommodation: dataMap.ItineraryAccommodation, ItineraryExpensiveOrCheap: dataMap.ItineraryExpensiveOrCheap, ItineraryMovieMusicAndOthers: dataMap.ItineraryMovieMusicAndOthers, ItineraryLocomotion: dataMap.ItineraryLocomotion, ItineraryOthers: dataMap.ItineraryOthers }
            });

            document.getElementById("ItineraryName").value = this.state.informacoesRoteiroAtual.ItineraryName;
            document.getElementById("ItineraryResume").value = this.state.informacoesRoteiroAtual.ItineraryResume;
            document.getElementById("ItineraryWhereGo").value = this.state.informacoesRoteiroAtual.ItineraryWhereGo;
            document.getElementById("ItineraryTimeStay").value = this.state.informacoesRoteiroAtual.ItineraryTimeStay;
            document.getElementById("ItineraryHowGo").value = this.state.informacoesRoteiroAtual.ItineraryHowGo;
            document.getElementById("ItinerarySafety").value = this.state.informacoesRoteiroAtual.ItinerarySafety;
            document.getElementById("ItineraryHealth").value = this.state.informacoesRoteiroAtual.ItineraryHealth;
            document.getElementById("ItineraryLatitude").value = parseFloat(this.state.informacoesRoteiroAtual.ItineraryLatitude);
            document.getElementById("ItineraryLongitude").value = parseFloat(this.state.informacoesRoteiroAtual.ItineraryLongitude);
            document.getElementById("ItineraryImage").value = this.state.informacoesRoteiroAtual.ItineraryImage;
            document.getElementById("ItineraryImageAuthor").value = this.state.informacoesRoteiroAtual.ItineraryImageAuthor;

            //document.getElementById("ItineraryDays").value = this.state.informacoesRoteiroAtual.ItineraryDay;
            document.getElementById("ItineraryMap").value = this.state.informacoesRoteiroAtual.ItineraryMap;
            document.getElementById("ItineraryPassport").value = this.state.informacoesRoteiroAtual.ItineraryPassport;
            document.getElementById("ItineraryExchange").value = this.state.informacoesRoteiroAtual.ItineraryExchange;
            document.getElementById("ItineraryInternet").value = this.state.informacoesRoteiroAtual.ItineraryInternet;
            document.getElementById("ItineraryAccommodation").value = this.state.informacoesRoteiroAtual.ItineraryAccommodation;
            document.getElementById("ItineraryExpensiveOrCheap").value = this.state.informacoesRoteiroAtual.ItineraryExpensiveOrCheap;
            document.getElementById("ItineraryMovieMusicAndOthers").value = this.state.informacoesRoteiroAtual.ItineraryMovieMusicAndOthers;
            document.getElementById("ItineraryLocomotion").value = this.state.informacoesRoteiroAtual.ItineraryLocomotion;
            document.getElementById("ItineraryOthers").value = this.state.informacoesRoteiroAtual.ItineraryOthers;

        }).catch(error => {
            console.log(error);
        });

    }

    handleChange(event) {
        document.getElementById("submitbutton").style.display = "none";
        document.getElementById("updatebutton").style.display = "block";
        this.setState({ selectedRoteiros: event.target.getAttribute("value") });
        this.GetItinerary(event.target.getAttribute("value"));

    }


    render() {
        return (
            <div>
                <Dropdown>
                    <Dropdown.Toggle className='dropdownAdm' variant="success" id="dropdown-basic" size="lg">
                        Roteiros cadastrados
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ overflowY: 'scroll', height: '20vh' }} onFocus={this.Atualizar}>
                        {this.state.roteiros.map((roteiro) => {
                            return (
                                <Row style={{ border: '0px solid #3e3d90', borderBottomWidth: '1px', color: color2 }}>
                                    <Col md={9} >
                                        <Dropdown.Item key={roteiro.value} onClick={this.handleChange} value={roteiro.value}>{roteiro.display}</Dropdown.Item>
                                    </Col>
                                    <Col md={1}>
                                        <div id="buttonX">
                                            <Button id="buttonXRoteiros" variant="flat" size="sm" type="submit" onClick={async () => {
                                                if (window.confirm("Deseja exluir a postagem?")) {

                                                    const headers = {
                                                        "Content-Type": "application/json; charset=utf-8",
                                                        "Authorization": "Bearer " + sessionStorage.getItem('token')
                                                    };

                                                    await Api.delete('/itinerary/remove/' + roteiro.value, { headers }).then((response) => {
                                                        return response;
                                                    }).finally(data => {
                                                        this.Atualizar();
                                                    }).catch(error => {
                                                        console.log(error);
                                                    });
                                                }
                                            }}> <FontAwesomeIcon style={{ color: '#e7424d' }} id="teste" className="" icon={faPowerOff} />
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            );
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </div>

        )
    }
}