import React, { Component } from 'react';
import { Row, Col, Container, Form, Button, Alert } from 'react-bootstrap'

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Redirect } from 'react-router-dom';

import Api from '../../services/api';

class CadastroUsuario extends Component {

    constructor(props) {
        super(props);
        this.state = { emailvalue: '', passwordvalue: '', passwordvalue2: '', namevalue: '', idUser: '', token: '', showUserAlert: false, showSenhaAlert: false, redir: false };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.postUser = this.postUser.bind(this);
    }

    handleChange(event) {
        switch (event.target.id) {
            case "name":
                this.setState({ namevalue: event.target.value });
                break;

            case "email":
                this.setState({ emailvalue: event.target.value });
                break;

            case "password1":
                this.setState({ passwordvalue: event.target.value });
                break;

            case "password2":
                this.setState({ passwordvalue2: event.target.value });
                break;

            default:
                console.log("ERRO NO SWITCH");
                break;

        }
    }
    fechou(event) {
        console.log(event);
    }

    async postUser() {
        this.setState({ showUserAlert: false, showSenhaAlert: false });
        try {

            const JsonSend = {
                "name": this.state.namevalue,
                "email": this.state.emailvalue,
                "password": this.state.passwordvalue
            };

            const headers = {
                "Content-Type": "application/json; charset=utf-8"
            };

            if (this.state.passwordvalue === this.state.passwordvalue2 && this.state.passwordvalue !== "" && this.state.passwordvalue2 !== "") {
                const response = await Api.post('/auth/register', JsonSend, { headers })

                if (this.state.idUser !== undefined && this.state.token !== undefined) {

                    localStorage.removeItem('token');
                    localStorage.removeItem('idUser');
                    localStorage.removeItem('name');
                    localStorage.removeItem('email');
                    this.setState({ idUser: response.data.user._id, token: response.data.token, passwordvalue: undefined, passwordvalue2: undefined });
                    localStorage.setItem('token', this.state.token);
                    localStorage.setItem('idUser', this.state.idUser);
                    localStorage.setItem('name', this.state.namevalue);
                    localStorage.setItem('email', this.state.emailvalue);
                    this.setState({ redir: true });
                }


            } else {
                this.setState({ showSenhaAlert: true });
            }



        }
        catch (error) {
            if (error.response !== undefined) {
                if (error.response.data.error === "Usuario Já existe") {
                    this.setState({ showUserAlert: true });
                }
            }
        }

    }

    handleSubmit(event) {
        this.postUser();
        event.preventDefault();
    }

    render() {
        if (this.state.redir) {
            return (<Redirect
                to={{
                    pathname: "/",
                    id: { id: this.state.idUser }
                }}
            />);
        }
        else {
            return (
                <div className="cadastro">
                    <Container fluid>
                        <Row className="">
                            <Col md className="">
                                <Alert key={0} variant="danger" show={this.state.showUserAlert}>
                                    Email informado já estar cadastrado no sistema.
                            </Alert>
                                <Alert key={1} variant="danger" show={this.state.showSenhaAlert}>
                                    Senhas diferentes.
                            </Alert>
                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Group controlId="name" onChange={this.handleChange}  value={this.state.namevalue}>
                                        <Form.Label>Nome:</Form.Label>
                                        <Form.Control size="lg" type="name" placeholder="Coloque seu nome completo" />
                                    </Form.Group>

                                    <Form.Group controlId="email" onChange={this.handleChange} className="my-3" value={this.state.emailvalue}>
                                        <Form.Label>Email:</Form.Label>
                                        <Form.Control size="lg" type="email" placeholder="Coloque o Email" />
                                    </Form.Group>

                                    <Form.Group controlId="password1" onChange={this.handleChange} value={this.state.passwordvalue} >
                                        <Form.Label>Senha:</Form.Label>
                                        <Form.Control size="lg" type="password" placeholder="Coloque sua senha" />
                                    </Form.Group>

                                    <Form.Group controlId="password2" onChange={this.handleChange} className="my-3" value={this.state.passwordvalue2}>
                                        <Form.Label>Confirme a senha:</Form.Label>
                                        <Form.Control size="lg" type="password" placeholder="Confirme a senha" />
                                    </Form.Group>
                                    <Row className="m-5">
                                        <Col className="d-flex justify-content-center">
                                            <Col className="d-flex justify-content-center">
                                                <Button size="sm" type="submit" className="text-center button2" style={{ backgroundColor: 'transparent', borderRadius: '50px', width: '100%', height: '2.5rem', color: 'white' }}>CADASTRAR</Button>
                                            </Col>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            );
        }
    }

}

export default CadastroUsuario;