import React from 'react';
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons'

import { Col, Image, FormControl, InputGroup, Row, Button } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

export default function Footer() {

    return (
        <footer className=' p-5'>

            <Row>
                <Col sm={4} className='px-5'>
                    <Row  className='my-2'>
                        <Link to={'/'}> <Image style={{ width: '30%' }} src='/imagens/Logo2.png' /> </Link>
                    </Row>
                    <Row>
                        <h5 className="footerText">Somos uma plataforma especializada em viagens que preza pela autonomia e singularidade do viajante, sempre levando em conta seu perfil e desejos através dos filtros disponibilizados.</h5>
                    </Row>
                </Col>
                <Col sm={4} className='px-5 mt-2'>
                    <Row>
                        <h5 className="h5AuxRed">Contato</h5>
                    </Row>
                    <Row>
                        <h5 className="footerText2">Av. A, 4165, Sala 614 <br></br>
                        Paiva, Cabo de Santo Agostinho, Pernambuco.<br></br>
                            <a rel="noopener noreferrer" target="_blank" href="mailto:graciela@rotaviagem.com.br" className="footerText">graciela@rotaviagem.com.br</a>
                        </h5>
                    </Row>
                </Col>
                <Col sm={4} className='px-5 mt-2'>
                    <Row>
                        <h5 className="h5AuxRed">Fique por dentro!</h5>
                    </Row>
                    <Row>
                        <h5 className="footerText">Receba novidades por e-mail</h5>
                    </Row>
                    <Row>
                        <InputGroup className="mb-2">
                            <FormControl style={{ backgroundColor: 'transparent', borderRadius: '0px', borderTop: 'transparent', borderRight: 'transparent', borderLeft: 'transparent' }}
                                placeholder="Digite seu email"
                                aria-label="Digite seu email"
                                aria-describedby=""
                            />
                            <Button variant="none" className="mr-2"><FontAwesomeIcon icon={faArrowAltCircleRight} /> </Button>
                        </InputGroup>
                    </Row>
                </Col>
            </Row>
            <Row className='mt-5 justify-content-center' >
                <Col sm={11} className='' style={{ backgroundColor: 'white', height: '0.2vh' }}>

                </Col>

            </Row>
            <Row className='mt-5'>
                <Col sm={8} className='px-4'>
                    <p style={{ color: 'white' }}>&copy; Copyright 2021 Todos os direitos reservados a Rota viagem &middot; <Link to="#">Termos e Privacidade</Link></p>
                </Col>
                <Col sm={4} className="px-4 d-flex justify-content-end">
                    <a href='https://www.instagram.com/rotaviagem/' target="_blank" rel="noopener noreferrer"> <FontAwesomeIcon className="" style={{ color: 'white', width: '4vh', height: '4vh' }} icon={faFacebookSquare} /> </a>
                    <a href='https://www.instagram.com/rotaviagem/' target="_blank" rel="noopener noreferrer"> <FontAwesomeIcon className="" style={{ color: 'white', width: '4vh', height: '4vh' }} icon={faInstagramSquare} /> </a>
                </Col>
            </Row>
        </footer>
    )
}
