import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Form, Button, Card, ListGroup, ListGroupItem } from 'react-bootstrap'
import Loading from '../../components/Loading'

import Header from '../../components/Header'

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Api from '../../services/api';


const HomeAdm = () => {
    const [links, setLinks] = useState({
        itinerary1: '',
        itinerary2: '',
        itinerary3: '',
        itinerary4: '',
        itinerary5: '',
        itinerary6: '',
        blog1: '',
        blog2: '',
        blog3: '',
        blog4: '',
    });

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        get();
    }, [])



    const parseURLParams = (url) => {
        var queryStart = url.indexOf("?") + 1,
            queryEnd = url.indexOf("#") + 1 || url.length + 1,
            query = url.slice(queryStart, queryEnd - 1),
            pairs = query.replace(/\+/g, " ").split("&"),
            parms = {}, i, n, v, nv;

        if (query === url || query === "") return;

        for (i = 0; i < pairs.length; i++) {
            nv = pairs[i].split("=", 2);
            n = decodeURIComponent(nv[0]);
            v = decodeURIComponent(nv[1]);

            if (!parms.hasOwnProperty(n)) parms[n] = [];
            parms[n].push(nv.length === 2 ? v : null);
        }
        return parms;
    }

    const get = async (e) => {
        try {
            setIsLoading(true);

            const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + sessionStorage.getItem('token')
            };

            await Api.get('/itineraryWithoutToken/getconfigurations', { headers }).then((response) => {
                setIsLoading(false);
                return response;
            }).then((data) => {
                let array = [];
                let arrayLinks = [];

                arrayLinks[0] = data.data.configs[0].itinerary1;
                arrayLinks[1] = data.data.configs[0].itinerary2;
                arrayLinks[2] = data.data.configs[0].itinerary3;
                arrayLinks[3] = data.data.configs[0].itinerary4;
                arrayLinks[4] = data.data.configs[0].itinerary5;
                arrayLinks[5] = data.data.configs[0].itinerary6;
                arrayLinks[6] = data.data.configs[0].blog1;
                arrayLinks[7] = data.data.configs[0].blog2;
                arrayLinks[8] = data.data.configs[0].blog3;
                arrayLinks[9] = data.data.configs[0].blog4;

                setLinks({
                    itinerary1: arrayLinks[0],
                    itinerary2: arrayLinks[1],
                    itinerary3: arrayLinks[2],
                    itinerary4: arrayLinks[3],
                    itinerary5: arrayLinks[4],
                    itinerary6: arrayLinks[5],
                    blog1: arrayLinks[6],
                    blog2: arrayLinks[7],
                    blog3: arrayLinks[8],
                    blog4: arrayLinks[9],
                })

                array[0] = parseURLParams(data.data.configs[0].itinerary1).itineraryId[0];
                array[1] = parseURLParams(data.data.configs[0].itinerary2).itineraryId[0];
                array[2] = parseURLParams(data.data.configs[0].itinerary3).itineraryId[0];
                array[3] = parseURLParams(data.data.configs[0].itinerary4).itineraryId[0];
                array[4] = parseURLParams(data.data.configs[0].itinerary5).itineraryId[0];
                array[5] = parseURLParams(data.data.configs[0].itinerary6).itineraryId[0];
                array[6] = parseURLParams(data.data.configs[0].blog1).itineraryId[0];
                array[7] = parseURLParams(data.data.configs[0].blog2).itineraryId[0];
                array[8] = parseURLParams(data.data.configs[0].blog3).itineraryId[0];
                array[9] = parseURLParams(data.data.configs[0].blog4).itineraryId[0];

            });
        }
        catch (error) {
            setIsLoading(false);
            if (error.response !== undefined) {
                window.alert(error.response.data.error);
            }
        }
    }

    const post = async (e) => {
        try {
            setIsLoading(true);

            let JsonSend = JSON.stringify(links);

            const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + sessionStorage.getItem('token')
            };

            await Api.post('/homepage/create', JsonSend, { headers }).then((response) => {
                setIsLoading(false);
                return response;
            }).then(() => {
                window.alert("Enviado com Sucesso!");
            });
        }
        catch (error) {
            setIsLoading(false);
            if (error.response !== undefined) {
                window.alert(error.response.data.error);
            }
        }
    }

    return (
        !isLoading ?
            <div className="criarhome">
                <Header></Header>
                <Container>
                    <Form>
                        <Row className="justify-content-center my-3">

                        </Row>
                        <Card className="">
                            <ListGroup className="list-group-flush">
                                <h1 className="text-center TextosAdm my-3" style={{ fontSize: 'calc(0.5em + 1.3vw)' }}>Principais Roteiros</h1>
                                <ListGroupItem>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <Card.Title className="TextosAdmSize">Link roteiro 1
                                        <Form.Group controlId="Roteiro1" >
                                                        <Form.Control onChange={(e) => {
                                                            setLinks({
                                                                itinerary1: e.currentTarget.value,
                                                                itinerary2: links.itinerary2,
                                                                itinerary3: links.itinerary3,
                                                                itinerary4: links.itinerary4,
                                                                itinerary5: links.itinerary5,
                                                                itinerary6: links.itinerary6,
                                                                blog1: links.blog1,
                                                                blog2: links.blog2,
                                                                blog3: links.blog3,
                                                                blog4: links.blog4,
                                                            })
                                                        }} value={links.itinerary1} type="text" placeholder="Coloque o link do roteiro 1" />
                                                    </Form.Group>
                                                </Card.Title>
                                            </Col>
                                            <Col>
                                                <Card.Title className="TextosAdmSize">Link roteiro 2
                                        <Form.Group controlId="Roteiro2" >
                                                        <Form.Control onChange={(e) => {
                                                            setLinks({
                                                                itinerary1: links.itinerary1,
                                                                itinerary2: e.currentTarget.value,
                                                                itinerary3: links.itinerary3,
                                                                itinerary4: links.itinerary4,
                                                                itinerary5: links.itinerary5,
                                                                itinerary6: links.itinerary6,
                                                                blog1: links.blog1,
                                                                blog2: links.blog2,
                                                                blog3: links.blog3,
                                                                blog4: links.blog4,
                                                            })
                                                        }} type="text" value={links.itinerary2} placeholder="Coloque o link do roteiro 2" />
                                                    </Form.Group>
                                                </Card.Title>
                                            </Col>
                                            <Col>
                                                <Card.Title className="TextosAdmSize">Link roteiro 3
                                        <Form.Group controlId="Roteiro3" >
                                                        <Form.Control onChange={(e) => {
                                                            setLinks({
                                                                itinerary1: links.itinerary1,
                                                                itinerary2: links.itinerary2,
                                                                itinerary3: e.currentTarget.value,
                                                                itinerary4: links.itinerary4,
                                                                itinerary5: links.itinerary5,
                                                                itinerary6: links.itinerary6,
                                                                blog1: links.blog1,
                                                                blog2: links.blog2,
                                                                blog3: links.blog3,
                                                                blog4: links.blog4,
                                                            })
                                                        }} type="text" value={links.itinerary3} placeholder="Coloque o link do roteiro 3" />
                                                    </Form.Group>
                                                </Card.Title>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Card.Title className="TextosAdmSize">Link roteiro 4
                                        <Form.Group controlId="Roteiro4" >
                                                        <Form.Control onChange={(e) => {
                                                            setLinks({
                                                                itinerary1: links.itinerary1,
                                                                itinerary2: links.itinerary2,
                                                                itinerary3: links.itinerary3,
                                                                itinerary4: e.currentTarget.value,
                                                                itinerary5: links.itinerary5,
                                                                itinerary6: links.itinerary6,
                                                                blog1: links.blog1,
                                                                blog2: links.blog2,
                                                                blog3: links.blog3,
                                                                blog4: links.blog4,
                                                            })
                                                        }} type="text" value={links.itinerary4} placeholder="Coloque o link do roteiro 4" />
                                                    </Form.Group>
                                                </Card.Title>
                                            </Col>
                                            <Col>
                                                <Card.Title className="TextosAdmSize">Link roteiro 5
                                        <Form.Group controlId="Roteiro5" >
                                                        <Form.Control onChange={(e) => {
                                                            setLinks({
                                                                itinerary1: links.itinerary1,
                                                                itinerary2: links.itinerary2,
                                                                itinerary3: links.itinerary3,
                                                                itinerary4: links.itinerary4,
                                                                itinerary5: e.currentTarget.value,
                                                                itinerary6: links.itinerary6,
                                                                blog1: links.blog1,
                                                                blog2: links.blog2,
                                                                blog3: links.blog3,
                                                                blog4: links.blog4,
                                                            })
                                                        }} type="text" value={links.itinerary5} placeholder="Coloque o link do roteiro 5" />
                                                    </Form.Group>
                                                </Card.Title>
                                            </Col>
                                            <Col>
                                                <Card.Title className="TextosAdmSize">Link roteiro 6
                                        <Form.Group controlId="Roteiro6" >
                                                        <Form.Control onChange={(e) => {
                                                            setLinks({
                                                                itinerary1: links.itinerary1,
                                                                itinerary2: links.itinerary2,
                                                                itinerary3: links.itinerary3,
                                                                itinerary4: links.itinerary4,
                                                                itinerary5: links.itinerary5,
                                                                itinerary6: e.currentTarget.value,
                                                                blog1: links.blog1,
                                                                blog2: links.blog2,
                                                                blog3: links.blog3,
                                                                blog4: links.blog4,
                                                            })
                                                        }} type="text" value={links.itinerary6} placeholder="Coloque o link do roteiro 6" />
                                                    </Form.Group>
                                                </Card.Title>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                        <Card className="my-5">
                            <ListGroup className="list-group-flush">
                                <h1 className="text-center TextosAdm my-3" style={{ fontSize: 'calc(0.5em + 1.3vw)' }}>Principais Postagens</h1>
                                <ListGroupItem>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <Card.Title className="TextosAdmSize">Link do blog 1
                                                <Form.Group controlId="blog1" >
                                                        <Form.Control onChange={(e) => {
                                                            setLinks({
                                                                itinerary1: links.itinerary1,
                                                                itinerary2: links.itinerary2,
                                                                itinerary3: links.itinerary3,
                                                                itinerary4: links.itinerary4,
                                                                itinerary5: links.itinerary5,
                                                                itinerary6: links.itinerary6,
                                                                blog1: e.currentTarget.value,
                                                                blog2: links.blog2,
                                                                blog3: links.blog3,
                                                                blog4: links.blog4,
                                                            })
                                                        }} type="text" value={links.blog1} placeholder="Coloque o link do blog 1" />
                                                    </Form.Group>
                                                </Card.Title>
                                            </Col>
                                            <Col>
                                                <Card.Title className="TextosAdmSize">Link do blog 2
                                            <Form.Group controlId="blog2" >
                                                        <Form.Control onChange={(e) => {
                                                            setLinks({
                                                                itinerary1: links.itinerary1,
                                                                itinerary2: links.itinerary2,
                                                                itinerary3: links.itinerary3,
                                                                itinerary4: links.itinerary4,
                                                                itinerary5: links.itinerary5,
                                                                itinerary6: links.itinerary6,
                                                                blog1: links.blog1,
                                                                blog2: e.currentTarget.value,
                                                                blog3: links.blog3,
                                                                blog4: links.blog4,
                                                            })
                                                        }} type="text" value={links.blog2} placeholder="Coloque o link do blog 2" />
                                                    </Form.Group>
                                                </Card.Title>
                                            </Col>
                                            <Col>
                                                <Card.Title className="TextosAdmSize">Link do blog 3
                                        <Form.Group controlId="blog3" >
                                                        <Form.Control onChange={(e) => {
                                                            setLinks({
                                                                itinerary1: links.itinerary1,
                                                                itinerary2: links.itinerary2,
                                                                itinerary3: links.itinerary3,
                                                                itinerary4: links.itinerary4,
                                                                itinerary5: links.itinerary5,
                                                                itinerary6: links.itinerary6,
                                                                blog1: links.blog1,
                                                                blog2: links.blog2,
                                                                blog3: e.currentTarget.value,
                                                                blog4: links.blog4,
                                                            })
                                                        }} type="text" value={links.blog3} placeholder="Coloque o link do blog 3" />
                                                    </Form.Group>
                                                </Card.Title>
                                            </Col>
                                            <Col>
                                                <Card.Title className="TextosAdmSize">Link do blog 4
                                        <Form.Group controlId="blog4" >
                                                        <Form.Control onChange={(e) => {
                                                            setLinks({
                                                                itinerary1: links.itinerary1,
                                                                itinerary2: links.itinerary2,
                                                                itinerary3: links.itinerary3,
                                                                itinerary4: links.itinerary4,
                                                                itinerary5: links.itinerary5,
                                                                itinerary6: links.itinerary6,
                                                                blog1: links.blog1,
                                                                blog2: links.blog2,
                                                                blog3: links.blog3,
                                                                blog4: e.currentTarget.value,
                                                            })
                                                        }} type="text" value={links.blog4} placeholder="Coloque o link do blog 3" />
                                                    </Form.Group>
                                                </Card.Title>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </ListGroupItem>
                            </ListGroup>
                        </Card>
                    </Form>
                    <Row className="m-4">
                        <Button className='dropdownAdm2' id="submitbutton" size="lg" onClick={post} >Atualizar</Button>
                    </Row>
                </Container>
            </div>
            :
            <div style={{ width: "100%", height: "50vh" }}>
                <Loading size='5%' />
            </div>
    );
}

export default HomeAdm;