import React, { Component } from 'react';
import { Image, Navbar, Button, Stack, Container } from 'react-bootstrap'
import { Link, Redirect } from 'react-router-dom'
import '../Header/style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons'

import 'bootstrap/dist/css/bootstrap.min.css';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = { logout: false, html: "/", emailvalue: sessionStorage.getItem('email'), namevalue: sessionStorage.getItem('name'), idUser: sessionStorage.getItem('idUser'), token: sessionStorage.getItem('token'), adm: sessionStorage.getItem('adm') };

        this.logout = this.logout.bind(this);
    }

    logout() {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('idUser');
        sessionStorage.removeItem('name');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('adm');
        this.forceUpdate();
        //RESOLVER PROBLEMA QUANDO DAR LOGOUT NO MODO USUARIO
        this.setState({ emailvalue: null, namevalue: null, idUser: null, token: null, adm: false, html: '/', logout: true });

    }
    render() {

        /*if (this.state.token == null) {
            return (<Redirect
                to={{
                    pathname: "/login",
                }}
            />);
        }*/
        if (this.state.logout) {
            return (<Redirect
                to={{
                    pathname: "/",
                }}
            />);
        }

        if (this.state.adm === false.toString() || this.state.adm === null) {
            this.state.html = "/";
        } else {
            this.state.html = "/adm";
        }

        if (this.state.emailvalue == null || this.state.namevalue == null || this.state.idUser == null || this.state.token == null) {
            return (
                <Container>
                    <Navbar expand="lg" >
                        <Container>
                            <Link to={this.state.html}> <Image style={{ width: '80%' }} src='/imagens/logo.png' /> </Link>
                            <Stack direction="horizontal" gap={3}>
                                <div>
                                    <Link to='/login' className="link1 " >  <FontAwesomeIcon className="mx-2" icon={faArrowAltCircleRight} />Entrar</Link>
                                </div>
                                {/*
                                <div>
                                    <Link to='/login' className="link2 " ><Button size="sm" className="text-center button2" style={{ backgroundColor: 'transparent', borderRadius: '50px', width: '180%', height: '2.5rem', color: 'white' }}>Adicionar rota</Button></Link>
                                </div>
                                */}
                            </Stack>
                        </Container>
                    </Navbar>
                </Container>
            );
        }
        else {
            return (
                <Navbar expand="lg" >
                    <Container>
                        <Link to={this.state.html}> <Image style={{ width: '80%' }} src='/imagens/logo.png' /> </Link>
                        <Stack direction="horizontal" gap={3}>
                            <div className=" ms-auto">Olá! {this.state.namevalue}</div>
                            <div className=""><Button variant="" size="lg" onClick={this.logout}>
                                Logout
                                </Button></div>
                        </Stack>
                    </Container>
                </Navbar>

            );
        }
    }

}

export default Header;


