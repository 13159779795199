import React, { Component } from 'react';
import Api from '../../services/api';
import GetProjects from '../../components/GetProjectsAdm'

import {
    Row,
    Col,
    Container,
    Form, Button, Modal, Card, ListGroup, ListGroupItem
} from "react-bootstrap";

import Header from '../../components/Header'
import Separador from '../../components/Separador'

import Loading from '../../components/Loading'

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import {
    Editor, EditorState, RichUtils, getDefaultKeyBinding, convertToRaw, convertFromRaw,
    CompositeDecorator
} from "draft-js";
import "draft-js/dist/Draft.css";
import "./textCss.css"

const styles = {
    root: {
        fontFamily: '\'Georgia\', serif',
        padding: 20,
        width: 400,
    },
    buttons: {
        marginBottom: 10,
    },
    urlInputContainer: {
        marginBottom: 10,
    },
    urlInput: {
        fontFamily: '\'Georgia\', serif',
        marginRight: 10,
        padding: 3,
    },
    editor: {
        border: '1px solid #ccc',
        cursor: 'text',
        minHeight: 80,
        padding: 10,
    },
    button: {
        marginTop: 10,
        textAlign: 'center',
    },
    link: {
        color: '#3b5998',
        textDecoration: 'underline',
    },
};

const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
    },
};

function getBlockStyle(block) {
    switch (block.getType()) {
        case 'blockquote': return 'RichEditor-blockquote';
        default: return null;
    }
}

function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
}


const Link = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url} style={styles.link}>
            {props.children}
        </a>
    );
};

class StyleButton extends React.Component {
    constructor() {
        super();
        this.onToggle = (e) => {
            e.preventDefault();
            this.props.onToggle(this.props.style);
        };
    }

    render() {
        let className = 'RichEditor-styleButton';
        if (this.props.active) {
            className += ' RichEditor-activeButton';
        }

        return (
            <span className={className} onMouseDown={this.onToggle}>
                {this.props.label}
            </span>
        );
    }
}

const BLOCK_TYPES = [
    { label: 'H1', style: 'header-one' },
    { label: 'H2', style: 'header-two' },
    { label: 'H3', style: 'header-three' },
    { label: 'H4', style: 'header-four' },
    { label: 'H5', style: 'header-five' },
    { label: 'H6', style: 'header-six' },
    { label: 'Blockquote', style: 'blockquote' },
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
    { label: 'Code Block', style: 'code-block' },
];

const BlockStyleControls = (props) => {
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    return (
        <div className="RichEditor-controls">
            {BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={type.style === blockType}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};

var INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    { label: 'Monospace', style: 'CODE' },
];

const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();

    return (
        <div className="RichEditor-controls">
            {INLINE_STYLES.map((type) =>
                <StyleButton
                    key={type.label}
                    active={currentStyle.has(type.style)}
                    label={type.label}
                    onToggle={props.onToggle}
                    style={type.style}
                />
            )}
        </div>
    );
};
let testeValorInput = "";
class RichEditorExample extends React.Component {
    constructor(props) {

        super(props);

        const decorator = new CompositeDecorator([
            {
                strategy: findLinkEntities,
                component: Link,
            },
        ]);

        if (this.props.textOut != '') {
            let conversao = '';
            try {
                conversao = convertFromRaw(JSON.parse(this.props.textOut));

            }
            catch (err) {
                conversao = convertFromRaw({ "blocks": [{ "key": "cdqv5", "text": this.props.textOut, "type": "unstyled", "depth": 0, "inlineStyleRanges": [], "entityRanges": [], "data": {} }], "entityMap": {} });

            }
            this.state = {
                title: null, resume: null, image: null,
                imageAuthor: null, author: null, convertedContent: null, editorState: EditorState.createWithContent(
                    conversao, decorator), showURLInput: false,
                urlValue: '',
            };

        } else {
            this.state = {
                title: null, resume: null, image: null,
                imageAuthor: null, author: null, convertedContent: null, editorState: EditorState.createEmpty(decorator), showURLInput: false,
                urlValue: '',
            };

        }

        this.focus = () => this.refs.editor.focus();
        this.onChange = (editorState) => {
            this.setState({
                editorState,
                convertedContent: JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()))
            })
            testeValorInput = JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent()));
        };


        this.handleKeyCommand = this._handleKeyCommand.bind(this);
        this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
        this.toggleBlockType = this._toggleBlockType.bind(this);
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.Post = this.Post.bind(this);

    }

    logState = () => {
        const content = this.state.editorState.getCurrentContent();
    };

    onURLChange = (e) => this.setState({ urlValue: e.target.value });

    promptForLink = (e) => {
        e.preventDefault();
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = editorState.getSelection().getStartKey();
            const startOffset = editorState.getSelection().getStartOffset();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
            let url = '';
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                url = linkInstance.getData().url;
            }
            this.setState({
                showURLInput: true,
                urlValue: url,
            }, () => {
                setTimeout(() => this.refs.url.focus(), 0);
            });
        }
    }

    confirmLink = (e) => {
        e.preventDefault();
        const { editorState, urlValue } = this.state;
        const contentState = editorState.getCurrentContent();

        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            { url: urlValue }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        // Apply entity
        let nextEditorState = EditorState.set(editorState,
            { currentContent: contentStateWithEntity }
        );

        // Apply selection
        nextEditorState = RichUtils.toggleLink(nextEditorState,
            nextEditorState.getSelection(), entityKey
        );

        this.setState({
            editorState: nextEditorState,
            showURLInput: false,
            urlValue: '',
        }, () => {
            setTimeout(() => this.refs.editor.focus(), 0);
        });
    }

    onLinkInputKeyDown = (e) => { if (e.which === 13) { this.confirmLink(e); } }

    removeLink = (e) => {
        e.preventDefault();
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            this.setState({
                editorState: RichUtils.toggleLink(editorState, selection, null),
            });
        }
    }



    _handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    }

    _mapKeyToEditorCommand(e) {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                this.state.editorState,
                4, /* maxDepth */
            );
            if (newEditorState !== this.state.editorState) {
                this.onChange(newEditorState);
            }
            return;
        }
        return getDefaultKeyBinding(e);
    }

    _toggleBlockType(blockType) {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }

    _toggleInlineStyle(inlineStyle) {
        this.onChange(
            RichUtils.toggleInlineStyle(
                this.state.editorState,
                inlineStyle
            )
        );
    }

    async Post(e) {
        try {

            function replacer(key, value) {
                if (key == "editorState") return undefined;
                else return value;
            }


            let JsonSend = JSON.stringify(this.state, replacer);


            const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + sessionStorage.getItem('token')
            };

            await Api.post('/blog/create', JsonSend, { headers }).then((response) => {
                return response;
            }).then(() => {
                e.preventDefault();
            });
        }
        catch (error) {
            console.log(error);
            window.alert("ERRO");
        }
        this.forceUpdate();

    }


    handleSubmit = (e) => {

        this.setState({ convertedContent: JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())) });

        this.Post(e);
        window.alert("SALVO");
    }

    render() {
        const { editorState, showURLInput } = this.state;

        let className = 'RichEditor-editor';
        var contentState = editorState.getCurrentContent();
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
                className += ' RichEditor-hidePlaceholder';
            }
        }

        return (

            < div id="form" >
                <div className="Postagem">
                    <div className="RichEditor-root m-5">
                        <BlockStyleControls
                            editorState={editorState}
                            onToggle={this.toggleBlockType}
                        />
                        <InlineStyleControls
                            editorState={editorState}
                            onToggle={this.toggleInlineStyle}
                        />
                        <div style={styles.buttons}>
                            <button
                                onMouseDown={this.promptForLink}
                                style={{ marginRight: 10 }}>
                                Add Link
                            </button>
                            <button onMouseDown={this.removeLink}>
                                Remove Link
                            </button>
                        </div>
                        {

                            showURLInput == true ?
                                <div style={styles.urlInputContainer}>
                                    <input
                                        onChange={this.onURLChange}
                                        ref="url"
                                        style={styles.urlInput}
                                        type="text"
                                        value={this.state.urlValue}
                                        onKeyDown={this.onLinkInputKeyDown}
                                    />
                                    <button onMouseDown={this.confirmLink}> Confirm </button>
                                </div>
                                : null
                        }
                        <div className={className} onClick={this.focus}>
                            <Editor
                                blockStyleFn={getBlockStyle}
                                customStyleMap={styleMap}
                                editorState={editorState}
                                handleKeyCommand={this.handleKeyCommand}
                                keyBindingFn={this.mapKeyToEditorCommand}
                                onChange={this.onChange}
                                placeholder="Escreva o conteúdo..."
                                ref="editor"
                                spellCheck={true}
                            /*textAlignment={}*/
                            />
                        </div>
                    </div>
                </div>
            </div >

        );
    }
}


let UpdateProject = false;
export default class CriarRoteiros extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ItineraryName: '', ItineraryResume: '', ItineraryWhereGo: '', ItineraryTimeStay: '', ItineraryHowGo: '', ItinerarySafety: '', ItineraryHealth: '', ItineraryImage: '', ItineraryImageAuthor: '', ItineraryLatitude: 0, ItineraryLongitude: 0, ItineraryDay: -1, ItineraryMap: '', ItineraryPassport: '', ItineraryExchange: '', ItineraryInternet: '', ItineraryAccommodation: '', ItineraryExpensiveOrCheap: '', ItineraryMovieMusicAndOthers: '', ItineraryLocomotion: '', ItineraryOthers: '',
            ModalShow: false, InputIdAtual: "", textoAtual: "", isLoading: false
        };
        this.ClickSubmit = this.ClickSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.postItinerary = this.postItinerary.bind(this);
        this.newItineraryFunction = this.newItineraryFunction.bind(this);
        this.changeModalHide = this.changeModalHide.bind(this);
        this.changeModalShow = this.changeModalShow.bind(this);

    }

    changeModalHide = () => {
        this.setState({
            ModalShow: false,
        });

        document.getElementById(this.state.InputIdAtual).value = testeValorInput;
    }
    changeModalShow = (evt) => {
        this.textoAtual = evt.target.value;
        testeValorInput = evt.target.value;
        //document.getElementById(this.state.InputIdAtual).value = evt.target.value;
        this.setState({
            ModalShow: true,
            InputIdAtual: evt.target.id
        });
    }

    handleChange(event) {
        switch (event.target.id) {
            case "ItineraryName":
                this.setState({ ItineraryName: event.target.value });
                break;
            case "ItineraryResume":
                this.setState({ ItineraryResume: event.target.value });
                break;
            case "ItineraryWhereGo":
                this.setState({ ItineraryWhereGo: event.target.value });
                break;
            case "ItineraryTimeStay":
                this.setState({ ItineraryTimeStay: event.target.value });
                break;
            case "ItineraryHowGo":
                this.setState({ ItineraryHowGo: event.target.value });
                break;
            case "ItinerarySafety":
                this.setState({ ItinerarySafety: event.target.value });
                break;
            case "ItineraryHealth":
                this.setState({ ItineraryHealth: event.target.value });
                break;
            case "ItineraryImage":
                this.setState({ ItineraryImage: event.target.value });
                break;
            case "ItineraryImageAuthor":
                this.setState({ ItineraryImageAuthor: event.target.value });
                break;
            case "ItineraryLatitude":
                this.setState({ ItineraryLatitude: event.target.value });
                break;
            case "ItineraryLongitude":
                this.setState({ ItineraryLongitude: event.target.value });
                break;
            case "ItineraryMap":
                this.setState({ ItineraryMap: event.target.value });
                break;
            case "ItineraryPassport":
                this.setState({ ItineraryPassport: event.target.value });
                break;
            case "ItineraryExchange":
                this.setState({ ItineraryExchange: event.target.value });
                break;
            case "ItineraryInternet":
                this.setState({ ItineraryInternet: event.target.value });
                break;
            case "ItineraryAccommodation":
                this.setState({ ItineraryAccommodation: event.target.value });
                break;
            case "ItineraryExpensiveOrCheap":
                this.setState({ ItineraryExpensiveOrCheap: event.target.value });
                break;
            case "ItineraryMovieMusicAndOthers":
                this.setState({ ItineraryMovieMusicAndOthers: event.target.value });
                break;
            case "ItineraryLocomotion":
                this.setState({ ItineraryLocomotion: event.target.value });
                break;
            case "ItineraryOthers":
                this.setState({ ItineraryOthers: event.target.value });
                break;
            default:
                console.log("ERRO SWITCH");
                ;

        }
    }

    async postItinerary(e) {
        try {

            this.setState({
                isLoading: true
            });

            //e.preventDefault();
            function replacer(key, value) {
                if (key == "ModalShow" || key == "InputIdAtual" || key == "textoAtual" || key == "isLoading") return undefined;
                else return value;
            }

            let JsonSend = JSON.stringify(this.state, replacer);

            const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + sessionStorage.getItem('token')
            };

            await Api.post('/itinerary', JsonSend, { headers }).then((response) => {

                this.setState({
                    isLoading: false
                });

                return response;

            }).then(() => {
                window.alert("Enviado com Sucesso!");
            });
        }
        catch (error) {
            if (error.response !== undefined) {
                window.alert(error.response.data.error);
            }
        }

    }

    async UpdateItinerary(e) {
        try {

            this.setState({
                isLoading: true
            });

            function replacer(key, value) {
                if (key == "ModalShow" || key == "InputIdAtual" || key == "textoAtual" || key == "isLoading") return undefined;
                else return value;
            }

            let JsonSend = JSON.stringify(this.state, replacer);

            const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + sessionStorage.getItem('token')
            };
            await Api.put('/itinerary/' + sessionStorage.getItem('id_project'), JsonSend, { headers }).then((response) => {
                this.setState({
                    isLoading: false
                });

                return response;
            }).then(() => {
                window.alert("Atualizado com Sucesso!");
            });
        }
        catch (error) {
            if (error.response !== undefined) {
                window.alert(error.response.data.error);
            }
        }

    }

    async handleSubmit(event) {
        try {
            if (UpdateProject === true) {
                this.UpdateItinerary(event);
            } else {
                this.postItinerary(event);
            }
        }
        catch (e) {
            window.alert("ERRO! " + e);
        }
    }

    ClickSubmit(e) {
        if (e.target.id === "updatebutton") {
            UpdateProject = true;
        }
        else {
            UpdateProject = false;
        }
        this.setState({
            ItineraryName: document.getElementById("ItineraryName").value,
            ItineraryResume: document.getElementById("ItineraryResume").value,
            ItineraryWhereGo: document.getElementById("ItineraryWhereGo").value,
            ItineraryTimeStay: document.getElementById("ItineraryTimeStay").value,
            ItineraryHowGo: document.getElementById("ItineraryHowGo").value,
            ItinerarySafety: document.getElementById("ItinerarySafety").value,
            ItineraryHealth: document.getElementById("ItineraryHealth").value,
            ItineraryLatitude: parseFloat(document.getElementById("ItineraryLatitude").value),
            ItineraryLongitude: parseFloat(document.getElementById("ItineraryLongitude").value),
            ItineraryImage: document.getElementById("ItineraryImage").value,
            ItineraryImageAuthor: document.getElementById("ItineraryImageAuthor").value,
            ItineraryMap: document.getElementById("ItineraryMap").value,
            ItineraryPassport: document.getElementById("ItineraryPassport").value,
            ItineraryExchange: document.getElementById("ItineraryExchange").value,
            ItineraryInternet: document.getElementById("ItineraryInternet").value,
            ItineraryAccommodation: document.getElementById("ItineraryAccommodation").value,
            ItineraryExpensiveOrCheap: document.getElementById("ItineraryExpensiveOrCheap").value,
            ItineraryMovieMusicAndOthers: document.getElementById("ItineraryMovieMusicAndOthers").value,
            ItineraryLocomotion: document.getElementById("ItineraryLocomotion").value,
            ItineraryOthers: document.getElementById("ItineraryOthers").value
        });
    }

    newItineraryFunction() {
        document.getElementById("submitbutton").style.display = "block";
        document.getElementById("updatebutton").style.display = "none";

        document.getElementById("ItineraryName").value = "";
        document.getElementById("ItineraryImage").value = "";
        document.getElementById("ItineraryImageAuthor").value = "";
        document.getElementById("ItineraryResume").value = "";
        document.getElementById("ItineraryWhereGo").value = "";
        document.getElementById("ItineraryTimeStay").value = "";
        document.getElementById("ItineraryHowGo").value = "";
        document.getElementById("ItinerarySafety").value = "";
        document.getElementById("ItineraryHealth").value = "";
        document.getElementById("ItineraryLatitude").value = 0;
        document.getElementById("ItineraryLongitude").value = 0;
        //document.getElementById("ItineraryDays").value = this.state.informacoesRoteiroAtual.ItineraryDay;
        document.getElementById("ItineraryMap").value = "";
        document.getElementById("ItineraryPassport").value = "";
        document.getElementById("ItineraryExchange").value = "";
        document.getElementById("ItineraryInternet").value = "";
        document.getElementById("ItineraryAccommodation").value = "";
        document.getElementById("ItineraryExpensiveOrCheap").value = "";
        document.getElementById("ItineraryMovieMusicAndOthers").value = "";
        document.getElementById("ItineraryLocomotion").value = "";
        document.getElementById("ItineraryOthers").value = "";


        this.setState({
            ItineraryName: document.getElementById("ItineraryName").value,
            ItineraryResume: document.getElementById("ItineraryResume").value,
            ItineraryWhereGo: document.getElementById("ItineraryWhereGo").value,
            ItineraryTimeStay: document.getElementById("ItineraryTimeStay").value,
            ItineraryHowGo: document.getElementById("ItineraryHowGo").value,
            ItinerarySafety: document.getElementById("ItinerarySafety").value,
            ItineraryHealth: document.getElementById("ItineraryHealth").value,
            ItineraryLatitude: parseFloat(document.getElementById("ItineraryLatitude").value),
            ItineraryLongitude: parseFloat(document.getElementById("ItineraryLongitude").value),
            ItineraryImage: document.getElementById("ItineraryImage").value,
            ItineraryImageAuthor: document.getElementById("ItineraryImageAuthor").value,
            ItineraryMap: document.getElementById("ItineraryMap").value,
            ItineraryPassport: document.getElementById("ItineraryPassport").value,
            ItineraryExchange: document.getElementById("ItineraryExchange").value,
            ItineraryInternet: document.getElementById("ItineraryInternet").value,
            ItineraryAccommodation: document.getElementById("ItineraryAccommodation").value,
            ItineraryExpensiveOrCheap: document.getElementById("ItineraryExpensiveOrCheap").value,
            ItineraryMovieMusicAndOthers: document.getElementById("ItineraryMovieMusicAndOthers").value,
            ItineraryLocomotion: document.getElementById("ItineraryLocomotion").value,
            ItineraryOthers: document.getElementById("ItineraryOthers").value
        });
    }

    render() {
        return (
            <div>
                {
                    this.state.isLoading == false ?
                        <div className="cadastro">
                            <Header></Header>
                            <Container>
                                <Row className="mt-5">
                                    <Col md className="">
                                        <Row className="my-3 ">
                                            <Col md={9}>
                                                <Button className='dropdownAdm' id="newItinerary" onClick={this.newItineraryFunction} variant="primary" size="lg" >Novo roteiro</Button>
                                            </Col>
                                            <Col md className="d-flex justify-content-end">
                                                <GetProjects></GetProjects>
                                            </Col>
                                        </Row>
                                        <Form id="form1" onSubmit={this.handleSubmit} className="TextosAdm">
                                            <Card>
                                                <ListGroup className="list-group-flush">
                                                    <ListGroupItem>
                                                        <Card.Body>
                                                            <Card.Title className="TextosAdmSize">Nome da cidade/pais do roteiro:
                                                            <Form.Group className="mt-2" controlId="ItineraryName" onChange={this.handleChange} >
                                                                    <Form.Control className="TextosAdmSize" type="text" placeholder="Nome da cidade/pais do roteiro" />
                                                                </Form.Group>
                                                            </Card.Title>
                                                            <Row>
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Link da imagem:
                                                                         <Form.Group className="mt-2" controlId="ItineraryImage" onChange={this.handleChange}>
                                                                            <Form.Control type="text" placeholder="Link da imagem para o roteiro" />
                                                                        </Form.Group>
                                                                    </Card.Title>
                                                                </Col>
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Nome do autor da imagem:
                                                                         <Form.Group className="mt-2" controlId="ItineraryImageAuthor" onChange={this.handleChange}>
                                                                            <Form.Control type="text" placeholder="Nome do autor da imagem" />
                                                                        </Form.Group>
                                                                    </Card.Title>
                                                                </Col>
                                                            </Row>
                                                            <Row className="">
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Latitude
                                                                    <Form.Group className="mt-2" controlId="ItineraryLatitude" onChange={this.handleChange} >
                                                                            <Form.Control type="text" placeholder="Latitude" />
                                                                        </Form.Group>
                                                                    </Card.Title>
                                                                </Col>
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Longitude
                                                                        <Form.Group className="mt-2" controlId="ItineraryLongitude" onChange={this.handleChange} >
                                                                            <Form.Control type="text" placeholder="Longitude" />
                                                                        </Form.Group>
                                                                    </Card.Title>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </ListGroupItem>
                                                    <ListGroupItem>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Resumo
                                                                    <Form.Group className="mt-2" controlId="ItineraryResume" onClick={this.changeModalShow}>
                                                                            <Form.Control as="textarea" rows={8} />
                                                                        </Form.Group>
                                                                    </Card.Title>
                                                                </Col>
                                                                <Col>
                                                                    <Row className="justify-content-center">
                                                                        <Card.Title className="TextosAdmSize">Link para o Mapa
                                                                        <Form.Group className="mt-2" controlId="ItineraryMap" onChange={this.handleChange} >
                                                                                <Form.Control type="text" placeholder="Coloque o link para o mapa do Google Drive" />
                                                                            </Form.Group>
                                                                        </Card.Title>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </ListGroupItem>
                                                </ListGroup>
                                            </Card>
                                            <Row className="mt-5 justify-content-centerTextosAdm">
                                                <Col className="text-center">
                                                    <h2 className="TextosAdm" style={{ fontSize: 'calc(1.5em + 1vw)', color: '#3e3d90' }}>ANTES DE VIAJAR!</h2>
                                                </Col>
                                            </Row>
                                            <Card className="">
                                                <ListGroup className="list-group-flush">
                                                    <ListGroupItem>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Quando ir
                                                                    <Form.Group className="mt-2" controlId="ItineraryWhereGo" onClick={this.changeModalShow}>
                                                                            <Form.Control as="textarea" rows={5} />
                                                                        </Form.Group>
                                                                    </Card.Title>
                                                                </Col>
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Quantos dias ficar
                                                                    <Form.Group className="mt-2" controlId="ItineraryTimeStay" onClick={this.changeModalShow}>
                                                                            <Form.Control as="textarea" rows={5} />
                                                                        </Form.Group>
                                                                    </Card.Title>
                                                                </Col>
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Como chegar
                                                                    <Form.Group className="mt-2" controlId="ItineraryHowGo" onClick={this.changeModalShow}>
                                                                            <Form.Control as="textarea" rows={5} />
                                                                        </Form.Group>
                                                                    </Card.Title>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Saúde
                                                                    <Form.Group className="mt-2" controlId="ItineraryHealth" onClick={this.changeModalShow}>
                                                                            <Form.Control as="textarea" rows={5} />
                                                                        </Form.Group>
                                                                    </Card.Title>
                                                                </Col>
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Moeda
                                                                    <Form.Group className="mt-2" controlId="ItineraryExchange" onClick={this.changeModalShow}>
                                                                            <Form.Control as="textarea" rows={5} />
                                                                        </Form.Group>
                                                                    </Card.Title>
                                                                </Col>
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Passaporte e visto
                                                                    <Form.Group className="mt-2" controlId="ItineraryPassport" onClick={this.changeModalShow}>
                                                                            <Form.Control as="textarea" rows={5} />
                                                                        </Form.Group>
                                                                    </Card.Title>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </ListGroupItem>
                                                </ListGroup>
                                            </Card>
                                            <Row className="mt-5 justify-content-centerTextosAdm">
                                                <Col className="text-center">
                                                    <h2 className="TextosAdm" style={{ fontSize: 'calc(1.5em + 1vw)', color: '#3e3d90' }}>QUANDO ESTIVER LÁ!</h2>
                                                </Col>
                                            </Row>
                                            <Card className="">
                                                <ListGroup className="list-group-flush">
                                                    <ListGroupItem>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Locomoção
                                                                    <Form.Group controlId="ItineraryLocomotion" onClick={this.changeModalShow}>
                                                                            <Form.Control as="textarea" rows={5} />
                                                                        </Form.Group>
                                                                    </Card.Title>
                                                                </Col>
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Segurança
                                                                    <Form.Group controlId="ItinerarySafety" onClick={this.changeModalShow}>
                                                                            <Form.Control as="textarea" rows={5} />
                                                                        </Form.Group>
                                                                    </Card.Title>
                                                                </Col>
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Internet
                                                                    <Form.Group controlId="ItineraryInternet" onClick={this.changeModalShow}>
                                                                            <Form.Control as="textarea" rows={5} />
                                                                        </Form.Group>
                                                                    </Card.Title>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Hospedagem
                                                                    <Form.Group controlId="ItineraryAccommodation" onClick={this.changeModalShow}>
                                                                            <Form.Control as="textarea" rows={5} />
                                                                        </Form.Group>
                                                                    </Card.Title>
                                                                </Col>
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Costumes e peculiaridades
                                                                    <Form.Group controlId="ItineraryOthers" onClick={this.changeModalShow}>
                                                                            <Form.Control as="textarea" rows={5} />
                                                                        </Form.Group>

                                                                    </Card.Title>
                                                                </Col>
                                                                <Col>
                                                                    <Card.Title className="TextosAdmSize">Preço
                                                                    <Form.Group controlId="ItineraryExpensiveOrCheap" onChange={this.handleChange}>
                                                                            <Form.Control as="textarea" rows={1} />
                                                                        </Form.Group>
                                                                    </Card.Title>
                                                                    <Row>
                                                                        <Col>
                                                                            <Card.Title className="TextosAdmSize">Livros,filmes e séries
                                                                            <Form.Group controlId="ItineraryMovieMusicAndOthers" onClick={this.changeModalShow}>
                                                                                    <Form.Control as="textarea" rows={4} />
                                                                                </Form.Group>
                                                                            </Card.Title>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </ListGroupItem>
                                                </ListGroup>
                                            </Card>
                                            <Row className="my-3">

                                            </Row>
                                            <Row className="mx-5">
                                                <Button className='dropdownAdm2' id="submitbutton" onClick={this.ClickSubmit} type="submit" size="lg" >Salvar</Button>
                                            </Row>
                                            <Row className="mx-5">
                                                <Button className='dropdownAdm2' style={{ display: 'none' }} id="updatebutton" onClick={this.ClickSubmit} type="submit" size="lg" >Update</Button>
                                            </Row>
                                            <Row className="my-3">

                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                <Modal
                                    show={this.state.ModalShow}
                                    onHide={this.changeModalHide}
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                >
                                    <Modal.Header closeButton>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <RichEditorExample textOut={this.textoAtual} />
                                    </Modal.Body>
                                </Modal>
                            </Container>
                        </div>
                        :
                        <div style={{ width: "100%", height: "50vh" }}>
                            <Loading size='5%' />
                        </div>
                }
            </div>
        );
    }
}
