import React, { Component, useEffect } from "react";
import { Row, Col, Container, Button, Card, Modal } from 'react-bootstrap'
import { Carousel } from 'primereact/carousel';

import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Separador from '../../components/Separador'

import Loading from '../../components/Loading'

import MapContainer2 from '../../components/Mapa/testeMapa2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWalking, faPlaceOfWorship, faLeaf, faUtensils, faSearch, faEye, faSave } from '@fortawesome/free-solid-svg-icons'

import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

import Api from '../../services/api';


export class CarouselHome extends Component {

  constructor(props) {
    super(props);

    this.state = {
      products: [], valorAtualCarrosel: 0, isLoading: false
    };

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '600px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '480px',
        numVisible: 1,
        numScroll: 1
      }
    ];


    this.productTemplate = this.productTemplate.bind(this);
    this.ClickBotao = this.ClickBotao.bind(this);
  }

  componentDidMount() {

    let array = [];
    let arrayLinks = [];

    const parseURLParams = (url) => {
      var queryStart = url.indexOf("?") + 1,
        queryEnd = url.indexOf("#") + 1 || url.length + 1,
        query = url.slice(queryStart, queryEnd - 1),
        pairs = query.replace(/\+/g, " ").split("&"),
        parms = {}, i, n, v, nv;

      if (query === url || query === "") return;

      for (i = 0; i < pairs.length; i++) {
        nv = pairs[i].split("=", 2);
        n = decodeURIComponent(nv[0]);
        v = decodeURIComponent(nv[1]);

        if (!parms.hasOwnProperty(n)) parms[n] = [];
        parms[n].push(nv.length === 2 ? v : null);
      }
      return parms;
    }

    const post = async (e) => {
      this.setState({
        isLoading: true
      });
      try {
        //setIsLoading(true);

        const headers = {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        };

        await Api.get('/itineraryWithoutToken/getconfigurations', { headers }).then((response) => {
          //setIsLoading(false);
          return response;
        }).then((data) => {
          arrayLinks[0] = data.data.configs[0].itinerary1;
          arrayLinks[1] = data.data.configs[0].itinerary2;
          arrayLinks[2] = data.data.configs[0].itinerary3;
          arrayLinks[3] = data.data.configs[0].itinerary4;
          arrayLinks[4] = data.data.configs[0].itinerary5;
          arrayLinks[5] = data.data.configs[0].itinerary6;
          arrayLinks[6] = data.data.configs[0].blog1;
          arrayLinks[7] = data.data.configs[0].blog2;
          arrayLinks[8] = data.data.configs[0].blog3;
          arrayLinks[9] = data.data.configs[0].blog4;
          array[0] = parseURLParams(data.data.configs[0].itinerary1);
          array[1] = parseURLParams(data.data.configs[0].itinerary2)
          array[2] = parseURLParams(data.data.configs[0].itinerary3)
          array[3] = parseURLParams(data.data.configs[0].itinerary4)
          array[4] = parseURLParams(data.data.configs[0].itinerary5)
          array[5] = parseURLParams(data.data.configs[0].itinerary6)
          array[6] = parseURLParams(data.data.configs[0].blog1)
          array[7] = parseURLParams(data.data.configs[0].blog2)
          array[8] = parseURLParams(data.data.configs[0].blog3)
          array[9] = parseURLParams(data.data.configs[0].blog4)

        });
      }
      catch (error) {
        //setIsLoading(false);
        if (error.response !== undefined) {
          window.alert(error.response.data.error);
        }
      }
    }



    post().finally(async () => {
      console.log(array);
      let products = [];
      for (let i = 0; i < 6; i++) {
        const element = array[i];

        try {
          const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
          };

          await Api.get('/itineraryWithoutToken/GetItinerary/' + element.itineraryId[0], { headers }).then((response) => {
            //setIsLoading(false);
            return response;
          }).then((data) => {
            if (i === 5) {
              this.setState({
                isLoading: false
              });
            }
            let textdays = "dia"
            if (Math.abs(element.numeroDeDias) > 1) {
              textdays = "dias"
            }
            products.push(
              { name: data.data.projects.ItineraryName + ', ' + element.numeroDeDias + ' ' + textdays, link: '/' + arrayLinks[i].split('/')[3], image: data.data.projects.ItineraryImage }
            );

          });
        }
        catch (error) {
          //setIsLoading(false);
          if (error.response !== undefined) {
            window.alert(error.response.data.error);
          }
        }
      }

      this.setState({
        products: products
      });
    });


  }

  //http://localhost:3001/roteiro?itineraryId=6116e19d391d75001763ad6c&numeroDeDias=1&primeiraVez=0&velocidade=0&preferencias=0

  ClickBotao(evt) {
    this.setState({
      valorAtualCarrosel: evt.target.id
    });

  }

  productTemplate(product) {
    return (

      <div className="product-item2" style={{ backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'top', backgroundColor: 'transparent', backgroundImage: "url(" + product.image + ")" }}>
        <div className="product-item-content2 d-flex align-items-end" style={{ color: "white", backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0,0), rgba(0, 0, 0,0.8)', backgroundSize: 'cover' }}>
          <div className="" >
            <Row sm={1} className="" >
              <Col className="m-1 d-flex align-items-end">
                <h5 className="mx-4 h5Aux">{product.name}</h5>
              </Col>
            </Row>
            <Row>
              <Link to={product.link} className="m-4" >  <Button className="LinkStylesAux shadow-lg " style={{ border: '2px solid white', backgroundColor: 'transparent', borderRadius: '50px', width: '90%', height: '100%', color: 'white' }}> Explorar</Button> </Link>
            </Row>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      !this.state.isLoading ?
        <div>
          <div className="carousel-demo2" id="CarouselLocais0">
            <div className="card" style={{ backgroundColor: 'transparent', border: 'none' }}>
              <Carousel value={this.state.products} numVisible={3} numScroll={3} responsiveOptions={this.responsiveOptions} className="custom-carousel" circular
                autoplayInterval={10000} itemTemplate={this.productTemplate} />
            </div>
          </div>
        </div>
        :
        <div style={{ width: "100%", height: "50vh" }}>
          <Loading size='5%' />
        </div>
    );
  }
}

export class CarouselComentarios extends Component {

  constructor(props) {
    super(props);

    this.state = {
      products: [], valorAtualCarrosel: 0
    };

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '600px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '480px',
        numVisible: 1,
        numScroll: 1
      }
    ];


    this.productTemplate = this.productTemplate.bind(this);
    this.ClickBotao = this.ClickBotao.bind(this);
  }

  componentDidMount() {

    this.setState({
      products: [{ name: 'Maria Augusta.', comentario: 'Com o Rota Viagem, consegui reduzir o tempo de pesquisa para o meu roteiro.' }, { name: 'Benício.', comentario: 'Gostei bastante das informações individualizada para o meu perfil de viajante.' }, { name: 'Gabriel.', comentario: 'Consegui aproveitar várias dicas do site na minha viagem.' },
      { name: 'Maria Augusta.', comentario: 'Com o Rota Viagem, consegui reduzir o tempo de pesquisa para o meu roteiro.' }, { name: 'Benício.', comentario: 'Gostei bastante das informações individualizada para o meu perfil de viajante.' }, { name: 'Gabriel.', comentario: 'Consegui aproveitar várias dicas do site na minha viagem.' }]
    });
  }

  ClickBotao(evt) {
    console.log(evt.target);
    this.setState({
      valorAtualCarrosel: evt.target.id
    });

  }

  productTemplate(product) {
    return (

      <div className="product-item3">
        <div className="product-item-content3 ">
          <Row className="d-flex justify-content-center ">
            <Col className="d-flex justify-content-center" >
              <div>
                <Row className="text-center" >
                  <Card bg="" text="black" className="text-center">
                    <blockquote className=" card-body">
                      <p>
                        {product.comentario}
                      </p>
                      <footer style={{ backgroundColor: 'transparent' }} className="blockquote-footer">
                        <small className="text-muted">
                          {product.name}
                        </small>
                      </footer>
                    </blockquote>
                  </Card>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    return (
      !this.state.isLoading ?
        <div>
          <div className="carousel-demo3" id="CarouselLocais0">
            <div className="card" style={{ backgroundColor: 'transparent', border: 'none' }}>
              <Carousel value={this.state.products} numVisible={1} numScroll={1} responsiveOptions={this.responsiveOptions} className="custom-carousel" circular
                autoplayInterval={10000} itemTemplate={this.productTemplate} />
            </div>
          </div>
        </div>
        :
        <div style={{ width: "100%", height: "50vh" }}>
          <Loading size='5%' />
        </div>
    );
  }
}

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size={'xl'}
    >
      <Modal.Header closeButton keyboard>
        Escolha seu destino
      </Modal.Header>
      <Modal.Body>
        <MapContainer2 className="my-2"></MapContainer2>
      </Modal.Body>
      <Modal.Footer>

      </Modal.Footer>
    </Modal>
  );
}

export default function Home() {

  const [modalShow, setModalShow] = React.useState(false);
  const [array, setArray] = React.useState([]);
  const [arrayLinks, setArrayLinks] = React.useState([]);
  const [data, setData] = React.useState([]);

  useEffect(() => {
    componentDidMount();
  }, [])

  const componentDidMount = () => {

    const parseURLParams = (url) => {
      var queryStart = url.indexOf("?") + 1,
        queryEnd = url.indexOf("#") + 1 || url.length + 1,
        query = url.slice(queryStart, queryEnd - 1),
        pairs = query.replace(/\+/g, " ").split("&"),
        parms = {}, i, n, v, nv;

      if (query === url || query === "") return;

      for (i = 0; i < pairs.length; i++) {
        nv = pairs[i].split("=", 2);
        n = decodeURIComponent(nv[0]);
        v = decodeURIComponent(nv[1]);

        if (!parms.hasOwnProperty(n)) parms[n] = [];
        parms[n].push(nv.length === 2 ? v : null);
      }
      return parms;
    }

    const post = async (e) => {
      try {
        //setIsLoading(true);

        const headers = {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        };

        await Api.get('/itineraryWithoutToken/getconfigurations', { headers }).then((response) => {
          //setIsLoading(false);
          return response;
        }).then((data) => {
          let arrayLinks = [];
          let array = [];
          arrayLinks[0] = data.data.configs[0].blog1;
          arrayLinks[1] = data.data.configs[0].blog2;
          arrayLinks[2] = data.data.configs[0].blog3;
          arrayLinks[3] = data.data.configs[0].blog4;
          array[0] = parseURLParams(data.data.configs[0].blog1);
          array[1] = parseURLParams(data.data.configs[0].blog2);
          array[2] = parseURLParams(data.data.configs[0].blog3);
          array[3] = parseURLParams(data.data.configs[0].blog4);

          setArray(array);
          setArrayLinks(arrayLinks);
        });
      }
      catch (error) {
        //setIsLoading(false);
        if (error.response !== undefined) {
          window.alert(error.response.data.error);
        }
      }
    }



    post().finally(() => {
      let products = [];

      setArray(async (state) => {
        console.log(state);

        try {
          const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + sessionStorage.getItem('token')
          };
          for (let i = 0; i < 4; i++) {
            const element = state[i];

            await Api.get('itineraryWithoutToken/blog/' + element.blogId[0], { headers }).then((response) => {
              return response;
            }).then((data) => {
              console.log(data.data);
              if (i === 5) {
                //setIsLoading(false);
              }
              let textdays = "dia"
              if (Math.abs(element.numeroDeDias) > 1) {
                textdays = "dias"
              }
              products.push(
                { title: data.data.posts.title, image: data.data.posts.image, resume: data.data.posts.resume, id: element.blogId[0] }
              );



            });
          }
          setData(products);
        }
        catch (error) {
          //setIsLoading(false);
          if (error.response !== undefined) {
            window.alert(error.response.data.error);
          }
        }
        return state
      })

      console.log(data);
    })

  }

  return (
    <div className="telaInicial">
      <Container fluid>
        <Header></Header>
        <Row>
          <Col style={{ width: '100%', height: '100%', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'top', backgroundColor: 'white', backgroundImage: "url('/imagens/home.png')" }}>
            <Row style={{ marginTop: '12vh' }} className="text-center">
              <h5 className="H1Home">Descubra a melhor rota para você!</h5>
            </Row>
            <Row style={{ marginTop: '' }} className="text-center">
              <h1 className="h5Titulo">Tenha em mãos o roteiro perfeito para sua viagem</h1>
            </Row>
            <Row style={{ marginTop: '5vh' }} className="d-flex justify-content-center">
              <Button onClick={() => setModalShow(true)} size="lg" className="shadow-lg text-center botaoIniciar" style={{ border: 'transparent', backgroundColor: '#e7424d', borderRadius: '50px', width: '25%', color: 'white' }}>Vamos começar?</Button>
              <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </Row>
            <Row style={{ marginTop: '3vh', marginBottom: '3vh' }} className="d-flex justify-content-center">
              <CarouselHome />
            </Row>
          </Col>
        </Row>

        <Row >
          <Col style={{ height: '100%', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'top', backgroundImage: "url('/imagens/Fundo2.png')" }}>
            <Row className="m-5">
              <Col >
                <h1 className="h5AuxBlue">Conheça nossas vantagens</h1>
                <Separador color={'#e7424d'}></Separador>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center mb-4">
              <Col sm={3} className="">
                <Row className="d-flex justify-content-center" >
                  <FontAwesomeIcon className="h5AuxRed" style={{ width: '100%', height: '6vh' }} icon={faSearch} />
                </Row>
                <Row className="d-flex justify-content-center" style={{ marginTop: '1vh' }}>
                  <h5 className="h5AuxRed  text-center">Explore a cidade do seu jeito</h5>
                </Row>
                <Row className="" style={{ marginTop: '1vh' }}>
                  <h5 style={{ textAlign: 'center' }} className="h5AuxGray">Receba informações direcionadas às suas preferências de viajante e programe-se da melhor forma possível. </h5>
                </Row>
              </Col>
              <Col sm={3} className="">
                <Row className="d-flex justify-content-center" >
                  <FontAwesomeIcon className="h5AuxRed" style={{ width: '100%', height: '6vh' }} icon={faEye} />
                </Row>
                <Row className="d-flex justify-content-center" style={{ marginTop: '1vh' }}>
                  <h5 className="h5AuxRed  text-center">Fique por dentro </h5>
                </Row>
                <Row className="" style={{ marginTop: '1vh' }}>
                  <h5 style={{ textAlign: 'center' }} className="h5AuxGray"> Aproveite ao máximo sua estadia informando-se sobre o essencial para cada destino, como transporte, documentação, clima e câmbio.</h5>
                </Row>
              </Col>
              <Col sm={3} className="">
                <Row className="d-flex justify-content-center" >
                  <FontAwesomeIcon className="h5AuxRed" style={{ width: '100%', height: '6vh' }} icon={faSave} />
                </Row>
                <Row className="d-flex justify-content-center" style={{ marginTop: '1vh' }}>
                  <h5 className="h5AuxRed  text-center">Consulte seus roteiros a qualquer momento </h5>
                </Row>
                <Row className="" style={{ marginTop: '1vh' }}>
                  <h5 style={{ textAlign: 'center' }} className="h5AuxGray">Depois de encontrar a rota ideal para você, salve as informações para usá-las durante a viagem, mesmo sem internet.</h5>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <Row className="m-5">
              <Col>
                <h1 className="h5AuxBlue">Últimas noticias</h1>
                <Separador color={'#e7424d'}></Separador>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center m-5">
              <Col sm={2} md className="">
                <Card className="my-2">
                  <Card.Img variant="top" src={data[0] != null && data[0].image} />
                  <Card.Body>
                    <Card.Title className='textBlog'>{data[0] != null && data[0].title} </Card.Title>
                    <Card.Text className=" h5AuxGray">
                      {data[0] != null && data[0].resume}
                    </Card.Text>
                    {/*<Card.Text className=" h5AuxGray">
                      Resumo da noticia do blog;
                    </Card.Text>*/}
                    <Card.Link className=" LinkStylesRed" href={data[0] != null && '/blog?blogId=' + data[0].id}>Continuar lendo...</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={2} md className="">
                <Card className="my-2">
                  <Card.Img variant="top" src={data[1] != null && data[1].image} />
                  <Card.Body>
                    <Card.Title className='textBlog'>{data[1] != null && data[1].title} </Card.Title>
                    <Card.Text className=" h5AuxGray">
                      {data[1] != null && data[1].resume}
                    </Card.Text>
                    {/*<Card.Text className=" h5AuxGray">
                      Resumo da noticia do blog;
                    </Card.Text>*/}
                    <Card.Link className=" LinkStylesRed" href={data[1] != null && '/blog?blogId=' + data[1].id}>Continuar lendo...</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={2} md className="">
                <Card className="my-2">
                  <Card.Img variant="top" src={data[2] != null && data[2].image} />
                  <Card.Body>
                    <Card.Title className='textBlog'>{data[2] != null && data[2].title} </Card.Title>
                    <Card.Text className=" h5AuxGray">
                      {data[2] != null && data[2].resume}
                    </Card.Text>
                    {/*<Card.Text className=" h5AuxGray">
                      Resumo da noticia do blog;
                    </Card.Text>*/}
                    <Card.Link className=" LinkStylesRed" href={data[2] != null && '/blog?blogId=' + data[2].id}>Continuar lendo...</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={2} md>
                <Card className="my-2">
                  <Card.Img variant="top" src={data[3] != null && data[3].image} />
                  <Card.Body>
                    <Card.Title className='textBlog'>{data[3] != null && data[3].title}</Card.Title>
                    <Card.Text className=" h5AuxGray">
                      {data[3] != null && data[3].resume}
                    </Card.Text>
                    {/*<Card.Text className=" h5AuxGray">
                      Resumo da noticia do blog;
                    </Card.Text>*/}
                    <Card.Link className=" LinkStylesRed" href={data[3] != null && '/blog?blogId=' + data[3].id}>Continuar lendo...</Card.Link>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="">
          <Col style={{ height: '100%', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'top', backgroundImage: "url('/imagens/Fundo1.png')" }}>
            <Row className="m-5">
              <Col>
                <h1 className="h5AuxBlue">Pesquise por perfil </h1>
                <Separador color={'#e7424d'}></Separador>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center mb-4">
              <Col sm={3} className="">
                <Row className="d-flex justify-content-center" >
                  <FontAwesomeIcon className="h5AuxRed" style={{ width: '100%', height: '6vh' }} icon={faWalking} />
                </Row>
                <Row className="d-flex justify-content-center" style={{ marginTop: '1vh' }}>
                  <h5 className="h5AuxRed  text-center">Turistão</h5>
                </Row>
                <Row className="" style={{ marginTop: '1vh' }}>
                  <h5 style={{ textAlign: 'center' }} className="h5AuxGray">Não quer perder os pontos turísticos mais conhecidos e procurados? Esse perfil é seu!</h5>
                </Row>
              </Col>
              <Col sm={3} className="">
                <Row className="d-flex justify-content-center" >
                  <FontAwesomeIcon className="h5AuxRed" style={{ width: '100%', height: '6vh' }} icon={faPlaceOfWorship} />
                </Row>
                <Row className="d-flex justify-content-center" style={{ marginTop: '1vh' }}>
                  <h5 className="h5AuxRed  text-center">Passaporte cultural</h5>
                </Row>
                <Row className="" style={{ marginTop: '1vh' }}>
                  <h5 style={{ textAlign: 'center' }} className="h5AuxGray">Para os viajantes que procuram uma rota repleta de arte e cultura.</h5>
                </Row>
              </Col>
              <Col sm={3} className="">
                <Row className="d-flex justify-content-center" >
                  <FontAwesomeIcon className="h5AuxRed" style={{ width: '100%', height: '6vh' }} icon={faLeaf} />
                </Row>
                <Row className="d-flex justify-content-center" style={{ marginTop: '1vh' }}>
                  <h5 className="h5AuxRed  text-center">Ao ar livre</h5>
                </Row>
                <Row className="" style={{ marginTop: '1vh' }}>
                  <h5 style={{ textAlign: 'center' }} className="h5AuxGray"> Roteiros que destacam opções de passeios ao ar livre, sempre conectado com a natureza. </h5>
                </Row>
              </Col>
              <Col sm={3} className="">
                <Row className="d-flex justify-content-center" >
                  <FontAwesomeIcon className="h5AuxRed" style={{ width: '100%', height: '6vh' }} icon={faUtensils} />
                </Row>
                <Row className="d-flex justify-content-center" style={{ marginTop: '1vh' }}>
                  <h5 className="h5AuxRed  text-center">Caramba, comi bem</h5>
                </Row>
                <Row className="" style={{ marginTop: '1vh' }}>
                  <h5 style={{ textAlign: 'center' }} className="h5AuxGray">Para os amantes da boa gastronomia, mas que também não abrem mão conhecer o destino.</h5>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="">
          <Col style={{ height: '100%', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'top', backgroundColor: 'white', backgroundImage: "url('/imagens/comentario.png')" }}>
            <Row className="m-5" >
              <Col>
                <h1 className="h5Aux">Comentários</h1>
                <Separador color={'#e7424d'}></Separador>
              </Col>
            </Row>
            <CarouselComentarios />
          </Col>
        </Row>
        <Row>
          <Col style={{ width: '100%', height: '100%', backgroundColor: '#e7424d', backgroundRepeat: 'repeat-y', backgroundSize: 'cover', backgroundPosition: 'top' }}>
            <Row className="m-5 ">
              <Col md sm={6}>
                <h5 className="h5TituloMaior">Nós fazemos a sua viagem brilhar!</h5>
                <Separador color={'white'}></Separador>
                <h5 className="h5TituloMenor mt-2">O Rota Viagem tem como objetivo proporcionar ao viajante a melhor experiência no destino escolhido, através das dicas e orientações de viagem direcionadas para o seu gosto pessoal. </h5>
              </Col>
              <Col className="d-flex justify-content-center  align-self-center mt-3">
                <Button className="shadow-lg" style={{ border: 'transparent', backgroundColor: 'white', borderRadius: '50px', width: '30vw', height: '5rem', color: '#e7424d' }}> <Link to='/login' className="LinkStyles2" >Quero aproveitar ao máximo minha viagem!</Link></Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer> </Footer>
    </div>

  );
}

