import React, { Component } from 'react';
import Api from '../../services/api';

import { Link } from 'react-router-dom'

import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/saga-blue/theme.css';

import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import {
  Row,
  Col,
  Button, Card, ListGroup, ListGroupItem, Container, Dropdown,
} from "react-bootstrap";

import SimpleImageEditor from '../../components/editText';

import {
  Editor, EditorState, RichUtils, getDefaultKeyBinding, convertToRaw, convertFromRaw,
  CompositeDecorator
} from "draft-js";
import "draft-js/dist/Draft.css";
import "./textCss.css"

import { faSearch, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Header from '../../components/Header'

const styles = {
  root: {
    fontFamily: '\'Georgia\', serif',
    padding: 20,
    width: 400,
  },
  buttons: {
    marginBottom: 10,
  },
  urlInputContainer: {
    marginBottom: 10,
  },
  urlInput: {
    fontFamily: '\'Georgia\', serif',
    marginRight: 10,
    padding: 3,
  },
  editor: {
    border: '1px solid #ccc',
    cursor: 'text',
    minHeight: 80,
    padding: 10,
  },
  button: {
    marginTop: 10,
    textAlign: 'center',
  },
  link: {
    color: '#3b5998',
    textDecoration: 'underline',
  },
};


function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}


const LinkComponente = (props) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={styles.link}>
      {props.children}
    </a>
  );
};


class RichEditorExample extends React.Component {
  constructor(props) {

    super(props);

    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: LinkComponente,
      },
    ]);

    this.state = {
      title: null, resume: null, image: null,
      imageAuthor: null, author: null, convertedContent: null, editorState: EditorState.createEmpty(decorator), showURLInput: false,
      urlValue: '',
    };

    this.focus = () => this.refs.editor.focus();
    this.onChange = (editorState) => { this.setState({ editorState, convertedContent: JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())) }) };


    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.mapKeyToEditorCommand = this._mapKeyToEditorCommand.bind(this);
    this.toggleBlockType = this._toggleBlockType.bind(this);
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.Post = this.Post.bind(this);

  }

  logState = () => {
    const content = this.state.editorState.getCurrentContent();
  };

  onURLChange = (e) => this.setState({ urlValue: e.target.value });

  promptForLink = (e) => {
    e.preventDefault();
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }
      this.setState({
        showURLInput: true,
        urlValue: url,
      }, () => {
        setTimeout(() => this.refs.url.focus(), 0);
      });
    }
  }

  confirmLink = (e) => {
    e.preventDefault();
    const { editorState, urlValue } = this.state;
    const contentState = editorState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      { url: urlValue }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // Apply entity
    let nextEditorState = EditorState.set(editorState,
      { currentContent: contentStateWithEntity }
    );

    // Apply selection
    nextEditorState = RichUtils.toggleLink(nextEditorState,
      nextEditorState.getSelection(), entityKey
    );

    this.setState({
      editorState: nextEditorState,
      showURLInput: false,
      urlValue: '',
    }, () => {
      setTimeout(() => this.refs.editor.focus(), 0);
    });
  }

  onLinkInputKeyDown = (e) => { if (e.which === 13) { this.confirmLink(e); } }

  removeLink = (e) => {
    e.preventDefault();
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.setState({
        editorState: RichUtils.toggleLink(editorState, selection, null),
      });
    }
  }



  _handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _mapKeyToEditorCommand(e) {
    if (e.keyCode === 9 /* TAB */) {
      const newEditorState = RichUtils.onTab(
        e,
        this.state.editorState,
        4, /* maxDepth */
      );
      if (newEditorState !== this.state.editorState) {
        this.onChange(newEditorState);
      }
      return;
    }
    return getDefaultKeyBinding(e);
  }

  _toggleBlockType(blockType) {
    this.onChange(
      RichUtils.toggleBlockType(
        this.state.editorState,
        blockType
      )
    );
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(
        this.state.editorState,
        inlineStyle
      )
    );
  }

  async Post(e) {
    try {

      function replacer(key, value) {
        if (key == "editorState") return undefined;
        else return value;
      }


      let JsonSend = JSON.stringify(this.state, replacer);

      const headers = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + sessionStorage.getItem('token')
      };

      await Api.post('/blog/create', JsonSend, { headers }).then((response) => {
        return response;
      }).then(() => {
        e.preventDefault();
      });
    }
    catch (error) {
      console.log(error);
      window.alert("ERRO");
    }
    this.forceUpdate();

  }


  handleSubmit = (e) => {
    this.setState({ convertedContent: JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())) });

    this.Post(e);
    window.alert("SALVO");
  }

  handleChange(event) {
    switch (event.target.id) {
      case "Título":
        this.setState({ title: event.target.value });
        break;
      case "Resumo":
        this.setState({ resume: event.target.value });
        break;
      case "Link da imagem":
        this.setState({ image: event.target.value });
        break;
      case "Autor da imagem":
        this.setState({ imageAuthor: event.target.value });
        break;
      case "Autor":
        this.setState({ author: event.target.value });
        break;
      default:
        console.log("ERRO SWITCH");
        ;

    }
  }


  render() {
    const { editorState, showURLInput } = this.state;

    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }

    return (

      <div id="form">
        <div className="Postagem">
          <Container className="p-jc-center my-1">
          <Col md className="d-flex justify-content-end mb-3">
            <GetPosts></GetPosts>
          </Col>
            <Card>
              <ListGroup className="list-group-flush">
                <ListGroupItem>
                  <Card.Body>
                    <div className="p-inputgroup p-mt-1">
                      <span className="p-inputgroup-addon">Título</span>
                      <InputText onChange={this.handleChange} id="Título" type="text" />
                    </div>
                    <Row>
                      <Col>
                        <div className="p-inputgroup  p-my-3">
                          <span className="p-inputgroup-addon">Link da imagem</span>
                          <InputText onChange={this.handleChange} id="Link da imagem" type="text" />
                        </div>

                      </Col>
                      <Col>
                        <div className="p-inputgroup  p-my-3">
                          <span className="p-inputgroup-addon">Autor da imagem</span>
                          <InputText onChange={this.handleChange} id="Autor da imagem" type="text" />
                        </div>
                      </Col>
                    </Row>
                    <Row className="">
                      <Col>
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">Autor da postagem</span>
                          <InputText onChange={this.handleChange} id="Autor" type="text" />
                        </div>
                      </Col>
                      <Col>
                        <div className="p-inputgroup">
                          <span className="p-inputgroup-addon">Resumo</span>
                          <InputTextarea onChange={this.handleChange} id="Resumo" type="text" />
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </ListGroupItem>
                <ListGroupItem>
                  <Card.Body>
                    <h1 className="text-center TextosAdm" style={{ fontSize: 'calc(0.5em + 1.5vw)' }}>Conteúdo</h1>
                    <div className="RichEditor-root ">
                      <BlockStyleControls
                        editorState={editorState}
                        onToggle={this.toggleBlockType}
                      />
                      <InlineStyleControls
                        editorState={editorState}
                        onToggle={this.toggleInlineStyle}
                      />
                      <div style={styles.buttons}>
                        <button
                          onMouseDown={this.promptForLink}
                          style={{ marginRight: 10 }}>
                          Add Link
                          </button>
                        <button onMouseDown={this.removeLink}>
                          Remove Link
                         </button>
                      </div>
                      {

                        showURLInput == true ?
                          <div style={styles.urlInputContainer}>
                            <input
                              onChange={this.onURLChange}
                              ref="url"
                              style={styles.urlInput}
                              type="text"
                              value={this.state.urlValue}
                              onKeyDown={this.onLinkInputKeyDown}
                            />
                            <button onMouseDown={this.confirmLink}> Confirm </button>
                          </div>
                          : null
                      }
                      <div className={className} onClick={this.focus}>
                        <Editor
                          blockStyleFn={getBlockStyle}
                          customStyleMap={styleMap}
                          editorState={editorState}
                          handleKeyCommand={this.handleKeyCommand}
                          keyBindingFn={this.mapKeyToEditorCommand}
                          onChange={this.onChange}
                          placeholder="Escreva o conteúdo..."
                          ref="editor"
                          spellCheck={true}
                        /*textAlignment={}*/
                        />
                      </div>
                    </div>
                  </Card.Body>
                </ListGroupItem>
              </ListGroup>
            </Card>
            <Row className="m-4">
              <Button className='dropdownAdm2' id="submitbutton" onClick={this.handleSubmit} type="submit" size="lg" >Enviar</Button>
            </Row>
          </Container>
        </div>
      </div >

    );
  }
}

const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};

function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  { label: 'Code Block', style: 'code-block' },
];

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) =>
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

var INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  { label: 'Monospace', style: 'CODE' },
];

const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle();

  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map((type) =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

let color2 = '#3e3d90';

class GetPosts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      informations: []
    };

    this.Atualizar = this.Atualizar.bind(this);
  }

  Atualizar() {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + sessionStorage.getItem('token')
    };

    Api.get('/blog/', { headers }).then((response) => {
      return response;
    }).then(data => {

      this.setState({
        informations: data.data.posts
      });
    })
      .catch(error => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.Atualizar();
  }


  render() {
    return (
      <div>
        <Dropdown>
          <Dropdown.Toggle className="dropdownAdm" id="dropdown-basic" size="lg" onFocus={this.Atualizar}>
            Postagens no Blog
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ overflowY: 'scroll', overflowX: 'hidden', height: '20vh', width: '30vw' }}>
            {this.state.informations.map((a) => {
              return (
                <>
                  <Row style={{ border: '0px solid #3e3d90', borderBottomWidth: '1px', color: color2 }}>
                    <Col md={9} >
                      <Dropdown.Item style={{ color: color2 }} disabled className="textAdmSelect" key={a.value} value={a.value}>{a.author}, {a.title}
                      </Dropdown.Item>
                    </Col>
                    <Col md={1}>
                      <Link id="buttonCommit" to={"/blog?blogId=" + a._id}>
                        <Button id="buttonSubmitRoteiros" variant="flat" size="sm" type="submit"> <FontAwesomeIcon id="teste" className="" icon={faSearch} />
                        </Button>
                      </Link>
                    </Col>
                    <Col md={1}>
                      <div id="buttonX">
                        <Button id="buttonXRoteiros" variant="flat" size="sm" type="submit" onClick={async () => {
                          if (window.confirm("Deseja exluir a postagem?")) {

                            const headers = {
                              "Content-Type": "application/json; charset=utf-8",
                              "Authorization": "Bearer " + sessionStorage.getItem('token')
                            };

                            await Api.delete('/blog/remove/' + a._id, { headers }).then((response) => {
                              return response;
                            }).finally(data => {
                              this.Atualizar();
                            }).catch(error => {
                              console.log(error);
                            });
                          }
                        }}> <FontAwesomeIcon style={{ color: '#e7424d' }} id="teste" className="" icon={faPowerOff} />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div >

    )
  }
}


export default function CriarPostagem() {

  return (
    <div>
      <Header></Header>
      <RichEditorExample />
      {/*<SimpleImageEditor/>*/}
    </div>

  );
}
