import React from 'react';
import { Row } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Component } from 'react';

export default class Separador extends Component {

    render() {
        return (
            <Row className="m-1">
                <div style={{ backgroundColor: this.props.color, height: '0.2vh', width: '2.5vw' }} >
                </div>
            </Row>
        )

    }
}
